import { z } from 'zod'
import { SUPPORTED_STUDY_LANGUAGES } from '@shared/frontend-and-landing-and-backend/constants/lang-codes'

export const DEFAULT_AUDIO_SPEED = 1.0
export const DEFAULT_POSITION_IN_FREQUENCY_LIST = 5000
export const DEFAULT_WORD_LENGTH = 15

export const MIN_EXERCISE_WORD_LENGTH = 5
export const MAX_EXERCISE_WORD_LENGTH = 70

export const MIN_AUDIO_SPEED = 0.75
export const MAX_AUDIO_SPEED = 1.25

export const UserSettingsSchema = z.object({
  preferences: z.object({
    exercises: z.object({
      audioSpeed: z.object({
        clonePronunciation: z.number().min(MIN_AUDIO_SPEED).max(MAX_AUDIO_SPEED).default(DEFAULT_AUDIO_SPEED),
        userPronunciation: z.number().min(MIN_AUDIO_SPEED).max(MAX_AUDIO_SPEED).default(DEFAULT_AUDIO_SPEED),
      }),
      frequencyList: z.object({
        exerciseLength: z.object({
          byLanguage: z
            .array(
              z.object({
                language: z.enum(SUPPORTED_STUDY_LANGUAGES),
                length: z
                  .number()
                  .min(MIN_EXERCISE_WORD_LENGTH)
                  .max(MAX_EXERCISE_WORD_LENGTH)
                  .default(DEFAULT_WORD_LENGTH),
              })
            )
            .default(
              SUPPORTED_STUDY_LANGUAGES.map((lang) => ({
                language: lang,
                position: DEFAULT_POSITION_IN_FREQUENCY_LIST,
              }))
            ),
        }),
        position: z.object({
          byLanguage: z
            .array(
              z.object({
                language: z.enum(SUPPORTED_STUDY_LANGUAGES),
                position: z.number().default(DEFAULT_POSITION_IN_FREQUENCY_LIST),
              })
            )
            .default(
              SUPPORTED_STUDY_LANGUAGES.map((lang) => ({
                language: lang,
                position: DEFAULT_POSITION_IN_FREQUENCY_LIST,
              }))
            ),
        }),
      }),
    }),
  }),
})

export type UserSettings = z.infer<typeof UserSettingsSchema>

export type GetUserSettingsResponse = UserSettings

export type UpdateUserSettingsRequest = UserSettings

export type UpdateUserSettingsResponse = UserSettings
