import { Navigate, Outlet } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectIsUserInfoFromAllSourcesLoaded, selectMissingOnboardingStep } from '../../state/slices/account-slice.ts'
import { FullViewSquaresLoader } from '../loader/full-view-squares-loader.tsx'

export const AllowIfOnboarded = () => {
  const missingOnboardingStep: string | null = useSelector(selectMissingOnboardingStep)
  const isUserInfoFromAllSourcesLoaded = useSelector(selectIsUserInfoFromAllSourcesLoaded)

  if (!isUserInfoFromAllSourcesLoaded) {
    return <FullViewSquaresLoader />
  } else if (missingOnboardingStep) {
    return <Navigate to={missingOnboardingStep} replace />
  }
  return <Outlet />
}
