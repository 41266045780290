import { Link } from 'react-router-dom'
import { BookA, Dice5, FileText, Layers, LucideIcon } from 'lucide-react'
import { ROUTE_PATHS } from '../../routing/route-paths.ts'
import { ExerciseCard } from './exercise-card.tsx'
import { langCodeToLanguageName } from '../../constants/lang-code-utils.ts'
import { selectStudyLanguageOrEnglish } from '../../state/slices/account-slice.ts'
import { useSelector } from 'react-redux'
import { t } from '../../i18n/translate'

type Exercise = {
  name: string
  description: string
  icon: LucideIcon
  route: string
  isMainExercise?: boolean
}

export const ExercisesList = () => {
  const studyLanguage = useSelector(selectStudyLanguageOrEnglish)
  const exercises: Exercise[] = [
    {
      name: t('dashboard.exercises.beginner.title').replace('{language}', langCodeToLanguageName(studyLanguage)),
      description: t('dashboard.exercises.beginner.description'),
      icon: BookA,
      isMainExercise: true,
      route: ROUTE_PATHS.BEGINNER_LEVEL_EXERCISE,
    },
    {
      name: t('dashboard.exercises.levelBased.title'),
      description: t('dashboard.exercises.levelBased.description'),
      icon: Layers,
      route: ROUTE_PATHS.FREQUENCY_LIST_EXERCISE,
    },
    {
      name: t('dashboard.exercises.random.title'),
      description: t('dashboard.exercises.random.description'),
      icon: Dice5,
      route: ROUTE_PATHS.RANDOM_EXERCISE,
    },
    {
      name: t('dashboard.exercises.custom.title'),
      description: t('dashboard.exercises.custom.description'),
      icon: FileText,
      route: ROUTE_PATHS.CUSTOM_EXERCISE,
    },
  ]

  return (
    <div className='grid w-full grid-cols-2 gap-1'>
      {exercises.map((exercise, index) => (
        <Link key={index} to={exercise.route} className='w-full'>
          <ExerciseCard
            name={exercise.name}
            description={exercise.description}
            icon={exercise.icon}
            isMainExercise={exercise.isMainExercise}
          />
        </Link>
      ))}
    </div>
  )
}
