import {
  NUMBER_OF_DAYS_IN_FREE_TRIAL,
  REFUND_PERIOD_IN_DAYS,
} from '@shared/frontend-and-landing-and-backend/constants/pricing-constants.ts'
import { Dictionary } from '../translate.ts'
import { englishDictionary } from './english-dictionary.ts'

const CONTACT_US_TEXT = 'contacto'

export const spanishDictionary: Dictionary = {
  ...englishDictionary, // if a Spanish translation is missing, an English translation will be used

  'onboarding.chooseMotherLanguage.title': '¿Cuál es tu idioma nativo?',
  'onboarding.chooseMotherLanguage.subtitle': '(Puedes cambiarlo más tarde)',
  'onboarding.chooseMotherLanguage.searchPlaceholder': 'Escribe tu idioma nativo',
  'onboarding.chooseMotherLanguage.other': 'Otro',
  'onboarding.chooseMotherLanguage.skip': 'Omitir',
  'onboarding.chooseMotherLanguage.next': 'Siguiente',
  'onboarding.chooseMotherLanguage.loading': 'Cargando...',

  'onboarding.chooseStudyLanguage.title': '¿Qué idioma quieres estudiar?',
  'onboarding.chooseStudyLanguage.subtitle': '(Puedes cambiarlo más tarde)',
  'onboarding.chooseStudyLanguage.previous': 'Anterior',
  'onboarding.chooseStudyLanguage.next': 'Siguiente',
  'onboarding.chooseStudyLanguage.loading': 'Cargando...',

  'onboarding.termsAndConditions.title': 'Prepárate',
  'onboarding.termsAndConditions.subtitle': 'para clonar tu voz',
  'onboarding.termsAndConditions.removeVoiceInfo':
    'Siempre puedes eliminar tu clon de voz más tarde. No es utilizado por nadie fuera de la aplicación.',
  'onboarding.termsAndConditions.step1.title': 'Paso 1',
  'onboarding.termsAndConditions.step1.description':
    'En el siguiente paso, leerás un texto corto en voz alta. Para mejores resultados, asegúrate de estar en un ambiente silencioso. Puedes cometer errores, no afectarán la clonación de voz.',
  'onboarding.termsAndConditions.step2.title': 'Paso 2',
  'onboarding.termsAndConditions.step2.description':
    'Tu clon de voz se integrará en tu experiencia de aprendizaje, adaptando los ejercicios específicamente para ti.',
  'onboarding.termsAndConditions.consent':
    'Doy mi consentimiento para que mi voz sea clonada para experiencias de aprendizaje personalizadas dentro de YourBestAccent.com, y acepto los',
  'onboarding.termsAndConditions.termsOfService': 'Términos de Servicio',
  'onboarding.termsAndConditions.and': 'y',
  'onboarding.termsAndConditions.privacyPolicy': 'Política de Privacidad',
  'onboarding.termsAndConditions.previous': 'Anterior',
  'onboarding.termsAndConditions.next': 'Ir a Clonación de Voz',
  'onboarding.termsAndConditions.marketingConsent.label': 'Recibir actualizaciones y consejos',
  'onboarding.termsAndConditions.marketingConsent.info':
    'Mantente informado sobre nuevas funciones, consejos de aprendizaje y ofertas especiales para mejorar tu experiencia de aprendizaje de idiomas. Puedes cancelar la suscripción en cualquier momento.',

  'onboarding.chooseDialect.title': 'Elige el dialecto que quieres estudiar:',
  'onboarding.chooseDialect.subtitle': '(Puedes cambiarlo más tarde)',
  'onboarding.chooseDialect.previous': 'Anterior',
  'onboarding.chooseDialect.next': 'Siguiente',
  'onboarding.chooseDialect.loading': 'Cargando...',

  'onboarding.chooseTopics.title': '¿En qué tema quieres enfocarte?',
  'onboarding.chooseTopics.subtitle': '(Puedes cambiarlo más tarde)',
  'onboarding.chooseTopics.previous': 'Anterior',
  'onboarding.chooseTopics.next': 'Siguiente',
  'onboarding.chooseTopics.loading': 'Cargando...',
  'onboarding.chooseTopics.skip': 'Omitir',

  'auth.signIn.title': 'Comienza a dominar la pronunciación',
  'auth.signIn.subtitle': 'tu pronunciación',
  'auth.signIn.withEmail': 'Iniciar sesión con Email',
  'auth.signIn.withGoogle': 'Iniciar sesión con Google',
  'auth.signUp.withEmail': 'Registrarse con Email',
  'auth.signUp.withGoogle': 'Registrarse con Google',

  'auth.signInEmail.title': 'Iniciar sesión con Email',
  'auth.signUpEmail.title': 'Registrarse con Email',
  'auth.email.placeholder': 'Dirección de email',
  'auth.email.invalidError': 'Por favor, introduce un email válido',
  'auth.email.sendingEmail': 'Enviando email',
  'auth.email.or': 'O',
  'auth.email.noAccount': '¿no tienes una cuenta?',
  'auth.email.haveAccount': '¿Ya tienes una cuenta?',
  'auth.email.signUp': 'Registrarse',
  'auth.email.signIn': 'Iniciar sesión',

  'auth.emailVerify.title': 'Verifica Tu Email',
  'auth.emailVerify.verifying': 'Verificando...',
  'auth.emailVerify.verify': 'Verificar',
  'auth.emailVerify.error.title': 'El enlace de email no es válido o ha expirado',
  'auth.emailVerify.error.returnToSignIn': 'Volver a Iniciar Sesión',

  'auth.verificationSent.title': 'Email de Verificación Enviado',
  'auth.verificationSent.sentTo': 'Hemos enviado un email de verificación a:',
  'auth.verificationSent.checkInbox': 'Por favor, revisa tu bandeja de entrada y haz clic en el',
  'auth.verificationSent.linkToSignIn': 'enlace para iniciar sesión',
  'auth.verificationSent.linkToSignUp': 'enlace para completar tu registro',

  'languages.english': 'inglés',
  'languages.spanish': 'español',
  'languages.german': 'alemán',
  'languages.french': 'francés',
  'languages.italian': 'italiano',
  'languages.polish': 'polaco',
  'languages.czech': 'checo',
  'languages.ukrainian': 'ucraniano',
  'languages.russian': 'ruso',
  'languages.portuguese': 'portugués',
  'languages.vietnamese': 'vietnamita',
  'languages.chinese': 'chino',
  'languages.hindi': 'hindi',
  'languages.indonesian': 'indonesio',
  'languages.malay': 'malayo',
  'languages.japanese': 'japonés',
  'languages.korean': 'coreano',
  'languages.tamil': 'tamil',
  'languages.turkish': 'turco',
  'languages.romanian': 'rumano',
  'languages.swedish': 'sueco',
  'languages.norwegian': 'noruego',
  'languages.danish': 'danés',
  'languages.slovak': 'eslovaco',
  'languages.dutch': 'holandés',
  'languages.thai': 'tailandés',
  'languages.hungarian': 'húngaro',
  'languages.greek': 'griego',
  'languages.finnish': 'finés',
  'languages.bulgarian': 'búlgaro',
  'languages.catalan': 'catalán',

  'dialects.americanEnglish': 'inglés americano',
  'dialects.britishEnglish': 'inglés británico',
  'dialects.australianEnglish': 'inglés australiano',
  'dialects.scottishEnglish': 'inglés escocés',
  'dialects.castilianSpanish': 'español castellano',
  'dialects.mexicanSpanish': 'español mexicano',
  'dialects.colombianSpanish': 'español colombiano',
  'dialects.argentinianSpanish': 'español argentino',
  'dialects.peruvianSpanish': 'español peruano',
  'dialects.parisianFrench': 'francés parisino',
  'dialects.belgianFrench': 'francés belga',
  'dialects.canadianFrench': 'francés canadiense',
  'dialects.standardGerman': 'alemán estándar',
  'dialects.standardItalian': 'italiano estándar',
  'dialects.standardPolish': 'polaco estándar',
  'dialects.brazilianPortuguese': 'portugués brasileño',
  'dialects.europeanPortuguese': 'portugués europeo',
  'dialects.standardRussian': 'ruso estándar',
  'dialects.standardUkrainian': 'ucraniano estándar',
  'dialects.standardCzech': 'checo estándar',
  'dialects.standardDanish': 'danés estándar',
  'dialects.standardDutch': 'holandés estándar',
  'dialects.standardFinnish': 'finés estándar',
  'dialects.standardIndonesian': 'indonesio estándar',
  'dialects.standardMalay': 'malayo estándar',
  'dialects.standardRomanian': 'rumano estándar',
  'dialects.standardSlovak': 'eslovaco estándar',
  'dialects.standardSwedish': 'sueco estándar',
  'dialects.standardTurkish': 'turco estándar',
  'dialects.standardHungarian': 'húngaro estándar',
  'dialects.standardNorwegian': 'noruego estándar',

  'dashboard.exercises.title': 'Ejercicios',
  'dashboard.exercises.subtitle': 'Continúa donde lo dejaste o explora nuevos ejercicios.',
  'dashboard.streak.title': 'Tu Racha de Aprendizaje',
  'dashboard.streak.subtitle': 'Explora tu progreso',
  'dashboard.exercises.beginner.title': '{language} para principiantes',
  'dashboard.exercises.beginner.description': 'palabras y expresiones comunes',
  'dashboard.exercises.levelBased.title': 'Por nivel',
  'dashboard.exercises.levelBased.description': 'palabras adaptadas a tu nivel de idioma',
  'dashboard.exercises.random.title': 'Aleatorio',
  'dashboard.exercises.random.description': 'Practica con palabras aleatorias',
  'dashboard.exercises.custom.title': 'Personalizado',
  'dashboard.exercises.custom.description': 'Practica con tus propias palabras',
  'dashboard.savedWords.button': 'Palabras guardadas',
  'dashboard.feedback.button': 'Comparte tu opinión',
  'dashboard.mobile.exercises.title': 'Nuestros ejercicios',
  'dashboard.mobile.streak.title': 'Tu racha de aprendizaje',
  'dashboard.streak.popup.info':
    'Un día cuenta para tu racha si aprendes al menos una palabra. Una palabra se considera aprendida cuando se marca en verde por primera vez en una evaluación de ejercicio.',
  'dashboard.streak.current.title': 'Racha de {count} días',
  'dashboard.streak.current.subtitle': '¡{count} días más para conseguir la insignia de racha de {target} días!',
  'dashboard.streak.longest.title': 'Racha de {count} días',
  'dashboard.streak.longest.subtitle': 'Tu racha más larga',

  'exercise.record.instructions':
    'Haz clic en el botón de grabar y lee el texto en voz alta. Cuando termines, haz clic en detener y analizaremos tu pronunciación.',
  'exercise.button.tryAgain': 'Intentar de nuevo',
  'exercise.button.anotherExercise': 'Otro ejercicio',
  'exercise.button.nextSentence': 'Siguiente frase',
  'exercise.button.anotherText': 'Otro texto',
  'exercise.button.differentText': 'Texto diferente',

  'exercise.controls.translate': 'Traducir',
  'exercise.controls.share': 'Compartir',
  'exercise.controls.settings': 'Configuración',
  'exercise.controls.searchLanguage': 'Buscar idioma...',
  'exercise.controls.noLanguageFound': 'No se encontró el idioma.',

  'exercise.custom.startPracticing': 'Empezar a practicar',
  'exercise.custom.title': 'Introduce texto para practicar',
  'exercise.custom.languageSelect.help':
    'Intentaremos detectar el idioma de tu texto automáticamente, pero es mejor si lo seleccionas manualmente.',
  'exercise.custom.languageSelect.switchTo': 'cambiar a:',
  'exercise.custom.textInput.placeholder':
    'Escribe aquí el texto para practicar. Ejemplo: "Betty Botter compró mantequilla pero dijo que la mantequilla era amarga."',
  'exercise.custom.error.tooShort': 'El texto debe tener al menos 2 caracteres',
  'exercise.custom.error.tooLong': 'El texto debe tener menos de 750 caracteres',
  'exercise.custom.success.languageUpdate': 'Idioma de estudio y dialecto actualizados correctamente',
  'exercise.custom.error.languageUpdate': 'No se pudo actualizar el idioma de estudio',

  'exercise.skeleton.loading': 'Preparando tu ejercicio...',

  'exercise.cefr.title': 'Nivel MCER',
  'exercise.cefr.info':
    'El Marco Común Europeo de Referencia (MCER) define el dominio del idioma en seis niveles: A1 y A2 (Usuario básico), B1 y B2 (Usuario independiente), C1 y C2 (Usuario competente). Cada nivel representa una capacidad lingüística creciente, desde principiante hasta casi nativo. Estos niveles estandarizados ayudan a estudiantes, profesores y empleadores a entender y comparar las habilidades lingüísticas internacionalmente.',
  'exercise.cefr.position': 'Posición:',
  'exercise.cefr.beginner': 'Principiante',
  'exercise.cefr.advanced': 'Avanzado',
  'exercise.cefr.frequency.info':
    'Generaremos frases con palabras alrededor de esta posición en la lista de frecuencia. Una lista de frecuencia muestra qué palabras se usan más a menudo en un idioma. Cuanto más baja sea la posición de la palabra en la lista de frecuencia, más a menudo aparece en el idioma. Las palabras en posiciones más altas son menos frecuentes, lo que muy a menudo significa que son más difíciles de aprender.',

  'exercise.settings.length.title': 'Longitud del ejercicio',
  'exercise.settings.length.wordLength': 'longitud de palabras:',
  'exercise.settings.length.info':
    'La longitud del ejercicio real puede diferir ligeramente de la longitud solicitada.',

  'exercise.settings.ipaAndTransliteration': 'IPA y transliteración',
  'exercise.settings.ipaInfo':
    'El alfabeto fonético internacional (IPA) y la transliteración aparecerán sobre las palabras de estudio',

  'exercise.settings.ipa.title': 'IPA',
  'exercise.settings.ipa.info':
    'La transcripción IPA (Alfabeto Fonético Internacional) es una función experimental y puede contener errores.',

  'exercise.settings.transliteration.title': 'Transliteración',
  'exercise.settings.transliteration.info':
    'Proporcionamos transliteración para idiomas que usan alfabetos no latinos.',

  'exercise.settings.title': 'Configuración del ejercicio',
  'exercise.settings.level': 'Nivel:',

  'translate.modal.originalText': 'Texto Original',
  'translate.modal.translation': 'Traducción',

  'navbar.contactUs': CONTACT_US_TEXT,
  'navbar.dropdown.account': 'Cuenta',
  'navbar.dropdown.settings': 'Configuración',
  'navbar.dropdown.exercises': 'Ejercicios',
  'navbar.dropdown.leaderboard': 'Clasificación',
  'navbar.dropdown.progress': 'Progreso',
  'navbar.dropdown.pricing': 'Precios',
  'navbar.dropdown.contact': 'Contacto',
  'navbar.dropdown.about': 'Acerca de',
  'navbar.dropdown.signOut': 'Cerrar sesión',

  'settings.title': 'Configuración',
  'settings.description': 'Realiza cambios en tu configuración aquí',
  'settings.studyLanguage.title': 'Estoy aprendiendo:',
  'settings.studyLanguage.description': 'Este es el idioma que se usará en los ejercicios.',
  'settings.motherLanguage.title': 'Mi idioma nativo:',
  'settings.dialect.title': 'Mi acento preferido:',
  'settings.dialect.description': 'Nota: algunos acentos están en beta. La velocidad del audio puede variar.',
  'settings.searchLanguage': 'Buscar idioma...',
  'settings.noLanguageFound': 'No se encontró el idioma.',
  'settings.success.motherLanguage': 'Idioma nativo actualizado correctamente',
  'settings.error.motherLanguage': 'No se pudo actualizar el idioma nativo',
  'settings.success.studyLanguage': 'Idioma de estudio y dialecto actualizados correctamente',
  'settings.error.studyLanguage': 'No se pudo actualizar el idioma de estudio',
  'settings.success.dialect': 'Dialecto actualizado correctamente',
  'settings.error.dialect': 'No se pudo actualizar el acento',

  'account.modal.title': 'Cuenta',
  'account.modal.description': 'Tu información y configuración de cuenta.',
  'account.modal.fullName': 'Nombre completo',
  'account.modal.email': 'Email',
  'account.modal.publicNickname': 'Apodo público',
  'account.modal.noNickname': 'Sin apodo establecido',
  'account.modal.billing': 'Facturación',
  'account.modal.dangerZone': 'Zona de peligro',
  'account.modal.marketingEmails.title': 'Notificaciones por email',
  'account.modal.marketingEmails.info': 'Actualizaciones del producto',
  'account.modal.marketingEmails.popoverInfo':
    'Mantente informado sobre nuevas funciones, consejos de aprendizaje y ofertas especiales para mejorar tu experiencia de aprendizaje de idiomas. Puedes cancelar la suscripción en cualquier momento.',
  'account.modal.marketingEmails.updateSuccess': 'Preferencias de email actualizadas correctamente',
  'account.modal.marketingEmails.updateError': 'No se pudieron actualizar las preferencias de email',

  'nickname.title': 'Elige tu apodo',
  'nickname.description': 'Este apodo será público. Puedes cambiarlo más tarde.',
  'nickname.input.placeholder': 'Escribe tu apodo',
  'nickname.button.saving': 'Guardando...',
  'nickname.button.join': 'Unirse a la Clasificación',

  'leaderboard.title': 'Clasificación',
  'leaderboard.description': 'Mira cómo te comparas con otros estudiantes',
  'leaderboard.empty': '¡No hay entradas todavía. ¡Sé el primero en llegar a la clasificación!',
  'leaderboard.position.title': 'Tu Posición',
  'leaderboard.position.notOnBoard':
    '¡Aún no estás en la clasificación. ¡Practica algunos ejercicios para empezar a competir con otros!',
  'leaderboard.position.of': 'de',
  'leaderboard.words': 'palabras',
  'leaderboard.anonymous': 'Usuario Anónimo',

  'filter.allLanguages': 'Todos los idiomas',

  'progress.title': 'Progreso',
  'progress.tabs.streak': 'Racha',
  'progress.tabs.badges': 'Insignias',
  'progress.tabs.stats': 'Estadísticas',

  'stats.title': 'Estadísticas',
  'stats.tabs.learnedWords': 'Palabras Aprendidas',
  'stats.tabs.savedWords': 'Palabras Guardadas',

  'stats.learnedWords.empty': 'No hay palabras aprendidas para {language} todavía.',
  'stats.learnedWords.noWords': 'Aún no tienes palabras aprendidas',
  'stats.learnedWords.goToExercises': 'Ve a los ejercicios para aprender nuevas palabras',
  'stats.learnedWords.button': 'IR A EJERCICIOS',
  'stats.learnedWords.table.word': 'Palabra',
  'stats.learnedWords.table.language': 'Idioma',
  'stats.learnedWords.table.learnedOn': 'Aprendida el',
  'stats.learnedWords.chart.title': 'Palabras aprendidas',
  'stats.learnedWords.chart.subtitle': 'agrupadas por idioma',

  'streak.title': 'Tu Racha de Aprendizaje',
  'streak.info':
    'Un día cuenta para tu racha si aprendes al menos una palabra. Una palabra se considera aprendida cuando se marca en verde por primera vez en una evaluación de ejercicio.',
  'streak.current': '¡Racha de {count} días!',
  'streak.nextBadge': '¡{count} días más para conseguir la insignia de racha de {target} días!',
  'streak.highestBadge': '¡Has alcanzado la insignia más alta! ¡Sigue así!',
  'streak.longest.title': '{count} días',
  'streak.longest.subtitle': 'Racha más larga',
  'streak.longest.info': '¡Esta es la racha más larga que has logrado. ¡Sigue así!',
  'streak.progress': 'Progreso',
  'streak.progress.days': 'días',
  'streak.badges': 'Insignias Ganadas: {count}',

  'pricing.premium.title': 'Elige tu plan',
  'pricing.features.title': 'Características',
  'pricing.button.practice': 'Practica Ahora',

  'pricing.plans.monthly': 'Mensual',
  'pricing.plans.yearly': 'Anual',
  'pricing.plans.lifetime': 'De por vida',
  'pricing.plans.freeTrial': 'Prueba gratuita',
  'pricing.plans.billedYearly': 'facturado anualmente',
  'pricing.plans.mostPopular': 'más popular',
  'pricing.plans.bestValue': 'mejor valor',
  'pricing.plans.current': '(Actual)',
  'pricing.plans.expired': '(Expirado)',
  'pricing.plans.freeTrialDays': `Gratis por ${NUMBER_OF_DAYS_IN_FREE_TRIAL} días`,
  'pricing.plans.perMonth': '€{amount}/mes',
  'pricing.plans.yearlyBilling': 'Facturado anualmente a €{amount}',
  'pricing.plans.referralDiscount': '¡{discount}% de descuento con tu referido para siempre!',
  'pricing.plans.referralDiscountMonth': '¡{discount}% de descuento con tu referido por 1 mes!',
  'pricing.plans.referralDiscountMonths': '¡{discount}% de descuento con tu referido por {duration} meses!',
  'pricing.plans.referralDiscountYear': '¡{discount}% de descuento con tu referido por 1 año!',
  'pricing.plans.referralDiscountYears': '¡{discount}% de descuento con tu referido por {duration} años!',
  'pricing.plans.pastReferralDiscount': '¡{discount}% de descuento gracias a tu referido anterior!',

  'pricing.button.subscribe': 'Suscribirse',
  'pricing.button.manageSubscription': 'Gestionar Suscripción',
  'pricing.button.startPracticing': 'Empezar a Practicar',
  'pricing.button.loading': 'Cargando...',
  'pricing.button.currentPlan': 'Tu plan actual',
  'pricing.button.usedTrial': 'Ya usaste tu prueba gratuita',
  'pricing.button.startTrial': `COMENZAR PRUEBA GRATUITA DE ${NUMBER_OF_DAYS_IN_FREE_TRIAL} DÍAS`,
  'pricing.button.subscribeNow': 'SUSCRIBIRSE AHORA',
  'pricing.button.goPractice': 'IR A PRACTICAR',
  'pricing.button.start': 'COMENZAR',

  'pricing.faq.title': 'Preguntas Frecuentes',
  'pricing.faq.trial.question': '¿Cómo obtengo una prueba gratuita?',
  'pricing.faq.trial.answer.withCard': `Puedes obtener una prueba gratuita de ${NUMBER_OF_DAYS_IN_FREE_TRIAL} días registrándote e ingresando los detalles de tu tarjeta de crédito. No te preocupes, no se te cobrará durante el período de prueba y puedes cancelar en cualquier momento. Incluso si olvidas cancelar y se te cobra, tenemos una garantía de reembolso de ${REFUND_PERIOD_IN_DAYS} días.`,
  'pricing.faq.trial.answer.noCard': `Puedes obtener una prueba gratuita registrándote en nuestro plan de Prueba Gratuita. No se requiere tarjeta de crédito, y la prueba se cancela automáticamente después de ${NUMBER_OF_DAYS_IN_FREE_TRIAL} días.`,
  'pricing.faq.security.question': '¿Es seguro el servicio de pago?',
  'pricing.faq.security.answer':
    'Sí, nuestro servicio de pago es seguro. Utilizamos Stripe para el procesamiento financiero y no almacenamos ninguna información sobre tu tarjeta. Stripe garantiza estándares de seguridad de nivel bancario.',
  'pricing.faq.upgrade.question': '¿Puedo mejorar o reducir mi suscripción?',
  'pricing.faq.upgrade.answer':
    'Sí, puedes mejorar o reducir tu suscripción en cualquier momento. Ve a la parte superior derecha de la aplicación cuando hayas iniciado sesión, haz clic en tu imagen de perfil, abre la sección Cuenta en el menú y haz clic en Gestionar Suscripción, serás redirigido al portal de facturación de Stripe donde podrás cambiar de plan.',
  'pricing.faq.cancel.question': '¿Puedo cancelar mi suscripción en cualquier momento?',
  'pricing.faq.cancel.answer':
    'Puedes cancelar tu suscripción en cualquier momento. Ve a la sección Cuenta cuando hayas iniciado sesión y haz clic en Gestionar Suscripción, serás redirigido al portal de facturación de Stripe donde podrás descargar facturas, cambiar de plan y cancelar tu suscripción.',
  'pricing.faq.refund.question': '¿Puedo obtener un reembolso?',
  'pricing.faq.refund.answer': `Sí, puedes obtener un reembolso dentro de los ${REFUND_PERIOD_IN_DAYS} días después de que se te haya cobrado. No queremos cobrar a los clientes que no están satisfechos con el producto. Por favor, lee más sobre nuestra política de reembolso aquí:`,
  'pricing.faq.refund.policy': 'Política de Reembolso',

  'contact.title': 'Contacto',
  'contact.help.title': 'Ayúdanos a mejorar YourBestAccent',
  'contact.help.description':
    '¡Comparte tu opinión o sugiere mejoras a los fundadores. ¡Leemos y respondemos a cada envío!',
  'contact.form.message.label': 'Mensaje para los fundadores',
  'contact.form.message.placeholder': 'Cuéntanos qué piensas o describe cualquier problema que hayas encontrado...',
  'contact.form.email.label': 'Email',
  'contact.form.email.placeholder': 'tu@email.com',
  'contact.form.name.label': 'Nombre (opcional)',
  'contact.form.name.placeholder': 'Tu nombre',
  'contact.button.cancel': 'Cancelar',
  'contact.button.send': 'Enviar Mensaje',
  'contact.button.sending': 'Enviando...',
  'contact.button.error': 'Ocurrió un error. Por favor, inténtalo de nuevo.',
  'contact.button.close': 'Cerrar',
  'contact.success.message': '¡Tu email ha sido enviado!',
  'contact.success.toast': '¡Gracias por tu opinión!',
  'contact.error.toast': 'No se pudo enviar la opinión. Por favor, inténtalo de nuevo.',
  'contact.discord.message': 'O envíanos un email para preguntas o ayuda.',

  'dangerZone.title': 'Zona de Peligro',
  'dangerZone.warning.title': 'Ten cuidado',
  'dangerZone.warning.description': 'Estas acciones no se pueden deshacer. Por favor, procede con precaución.',

  'exercise.length.short': 'Corto',
  'exercise.length.medium': 'Medio',
  'exercise.length.long': 'Largo',

  'topics.greetings': 'Saludos',
  'topics.intro': 'Presentaciones',
  'topics.numbers': 'Números',
  'topics.family': 'Familia',
  'topics.food': 'Comida',
  'topics.directions': 'Direcciones',
  'topics.shopping': 'Compras',
  'topics.travel': 'Viajes',
  'topics.medicine': 'Medicina',
  'topics.weather': 'Clima',
  'topics.business': 'Negocios',
  'topics.letters': 'Cartas',
  'topics.chatting': 'Charla',
  'topics.slang': 'Jerga',
  'topics.news': 'Noticias',
  'topics.academia': 'Academia',
  'topics.tech': 'Tecnología',
  'topics.art': 'Arte',
  'topics.history': 'Historia',
  'topics.socializing': 'Socializar',
  'topics.music': 'Música',
  'topics.emotions': 'Emociones',
  'topics.nature': 'Naturaleza',
  'topics.career': 'Carrera',
  'topics.interview': 'Entrevista',
  'topics.cooking': 'Cocina',
  'topics.gaming': 'Videojuegos',
  'topics.sports': 'Deportes',
  'topics.finance': 'Finanzas',
  'topics.cinema': 'Cine',
  'topics.dancing': 'Baile',
  'topics.socialMedia': 'Redes Sociales',
  'topics.gardening': 'Jardinería',
  'topics.photography': 'Fotografía',
  'topics.fashion': 'Moda',

  'signUpPrompt.pronunciation': 'Regístrate para escuchar la pronunciación correcta generada con tu clon de voz',
  'signUpPrompt.downloadAudio': 'Regístrate para descargar el audio generado con tu clon de voz',
  'signUpPrompt.savedWords': 'Regístrate para ir a tus "palabras guardadas"',
  'signUpPrompt.addToSavedWords': 'Regístrate para añadir la palabra a tus "palabras guardadas"',
  'signUpPrompt.tryAnotherExercise': 'Regístrate para probar otro ejercicio',
  'signUpPrompt.tryAnotherText': 'Regístrate para probar otro texto',

  'pricing.freeTrial.title': 'Cómo funciona tu prueba gratuita',
  'pricing.freeTrial.timeline.today': 'Hoy',
  'pricing.freeTrial.timeline.today.description': 'Introduce los datos de tu tarjeta y obtén acceso instantáneo',
  'pricing.freeTrial.timeline.sevenDays': '7 días',
  'pricing.freeTrial.timeline.sevenDays.description': 'Último día para cancelar antes del primer cobro',
  'pricing.freeTrial.timeline.twentyOneDays': '21 días',
  'pricing.freeTrial.timeline.twentyOneDays.description': `Incluso si se te cobra, puedes solicitar un reembolso incondicional. Solo háznos saber haciendo clic en el botón "${CONTACT_US_TEXT}" arriba.`,

  'pricing.toast.alreadyUsedTrial': 'Ya has utilizado tu prueba gratuita.',
  'pricing.toast.changePlanInfo': 'Para cambiar tu plan actual, por favor haz clic en "Gestionar Suscripción".',
  'pricing.toast.portalError':
    'No se puede acceder a tu sección de facturación en este momento. Por favor, inténtalo más tarde o contacta con soporte si el problema persiste.',

  'exercise.pronunciation.your': 'Tu pronunciación',
  'exercise.pronunciation.yourBetter': 'Tu mejor pronunciación',
}
