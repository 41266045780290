import { Button } from '../../../../../shadcn/button.tsx'
import { UserSettings } from '@shared/frontend-and-backend/body-types/user-settings/user-settings.types.ts'
import { cloneDeep } from 'lodash'
import { MediaRemoteControl, useMediaRemote } from '@vidstack/react'
import { useUpdateAudioSpeedMutation } from '../../../../../../hooks/use-update-audio-speed-mutation.ts'
import { useUserSettings } from '../../../../../../transport/transport/our-backend/api/users/users-hooks.ts'
import { useSelector } from 'react-redux'
import { selectAccountAccessToken } from '../../../../../../state/slices/account-slice.ts'
import { AudioSpeedType } from '../../types'

export const SpeedButtons = ({ audioSpeedType }: { audioSpeedType: AudioSpeedType }) => {
  const accessToken = useSelector(selectAccountAccessToken)
  const remote: MediaRemoteControl = useMediaRemote()
  const { mutate } = useUpdateAudioSpeedMutation(accessToken)
  const { data: userSettings } = useUserSettings()

  const updateAudioSpeed = (audioSpeed: number) => {
    if (!userSettings) {
      return
    }
    const updatedSettings: UserSettings = cloneDeep(userSettings)
    updatedSettings.preferences.exercises.audioSpeed[audioSpeedType] = audioSpeed
    mutate(updatedSettings)
  }

  const handleSpeedChange = (speed: number) => {
    remote.changePlaybackRate(speed)
    updateAudioSpeed(speed)
  }

  const speeds: number[] = [0.8, 0.85, 0.9, 0.95, 1]
  return (
    <div className='flex justify-center gap-4 py-8'>
      {speeds.map((speed) => (
        <Button
          key={speed}
          className='h-10 w-10 rounded-full bg-gray-200 text-stone-900 hover:bg-gray-300 active:bg-gray-400'
          variant='ghost'
          onClick={() => handleSpeedChange(speed)}
        >
          {speed}
        </Button>
      ))}
    </div>
  )
}
