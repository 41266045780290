import { Checkbox } from '../../../shadcn/checkbox.tsx'
import { Popover, PopoverContent, PopoverTrigger } from '../../../shadcn/popover.tsx'
import { CircleHelp } from 'lucide-react'
import { t } from '../../../../i18n/translate.ts'
import { EXTERNAL_LINKS } from '../../../../constants/external-links.ts'

interface VoiceCloningAndNotificationsConsentProps {
  isConsentChecked: boolean
  setIsConsentChecked: (checked: boolean) => void
  shouldReceiveMarketingEmails: boolean
  onMarketingEmailsChange: (checked: boolean) => void
}

export const VoiceCloningAndNotificationsConsent = ({
  isConsentChecked,
  setIsConsentChecked,
  shouldReceiveMarketingEmails,
  onMarketingEmailsChange,
}: VoiceCloningAndNotificationsConsentProps) => {
  return (
    <div className='space-y-4'>
      <div className='flex items-center space-x-3'>
        <Checkbox
          id='marketing-consent'
          checked={shouldReceiveMarketingEmails}
          onCheckedChange={onMarketingEmailsChange}
          className='mt-0'
        />
        <div className='flex items-center space-x-2'>
          <label htmlFor='marketing-consent' className='cursor-pointer text-sm text-gray-600'>
            {t('onboarding.termsAndConditions.marketingConsent.label')}
          </label>
          <Popover>
            <PopoverTrigger className='mt-0'>
              <CircleHelp className='h-4 w-4 text-gray-400 hover:text-gray-500' />
            </PopoverTrigger>
            <PopoverContent className='w-64 p-3 text-sm text-gray-600'>
              {t('onboarding.termsAndConditions.marketingConsent.info')}
            </PopoverContent>
          </Popover>
        </div>
      </div>

      <div className='flex items-center space-x-3'>
        <Checkbox
          id='voice-consent'
          checked={isConsentChecked}
          onCheckedChange={(checked) => setIsConsentChecked(checked as boolean)}
          className='mt-0'
        />
        <label htmlFor='voice-consent' className='cursor-pointer text-sm text-gray-600'>
          {t('onboarding.termsAndConditions.consent')}{' '}
          <a
            href={EXTERNAL_LINKS.TERMS_OF_SERVICE_URL}
            className='text-indigo-600 hover:underline'
            target='_blank'
            rel='noopener noreferrer'
          >
            {t('onboarding.termsAndConditions.termsOfService')}
          </a>{' '}
          {t('onboarding.termsAndConditions.and')}{' '}
          <a
            href={EXTERNAL_LINKS.PRIVACY_POLICY_URL}
            className='text-indigo-600 hover:underline'
            target='_blank'
            rel='noopener noreferrer'
          >
            {t('onboarding.termsAndConditions.privacyPolicy')}
          </a>
          .
        </label>
      </div>
    </div>
  )
}
