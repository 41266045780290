import posthog from 'posthog-js'
import { PlanType } from '@shared/frontend-and-backend/body-types/payments/subscription-account.types.ts'

export enum PLAYER_TYPE_FOR_ANALYTICS {
  USER_PRONUNCIATION = 'user_pronunciation',
  USER_CLONED_PRONUNCIATION = 'native_pronunciation',
  USER_CLONED_VOICE_DEMO_ON_ONBOARDING = 'user_cloned_voice_demo_on_onboarding',
  EXPECTED_WORD_PRONUNCIATION = 'expected_word_pronunciation',
  ACTUAL_WORD_PRONUNCIATION = 'actual_word_pronunciation',
}

const defaultProperties = () => ({
  href: window.location.href,
  path_name: window.location.pathname,
  hash: window.location.hash,
  pathAndHash: window.location.pathname + window.location.hash,
  domain: window.location.hostname,
})

const captureWithDefaults = (eventName: string, properties: Record<string, string> = {}) => {
  posthog.capture(eventName, { ...defaultProperties(), ...properties })
}

// use snake case for everything sent to posthog
export const POSTHOG_EVENTS = {
  click: (clickName: string) => {
    captureWithDefaults('click', { click_name: clickName })
  },
  clickPlan: (clickName: string, planType: PlanType) => {
    captureWithDefaults('click', { click_name: clickName, plan_type: planType })
  },
  playAudio: (playerType: PLAYER_TYPE_FOR_ANALYTICS) => {
    captureWithDefaults('play_audio', { player_type: playerType })
  },
  viewPage: () => {
    captureWithDefaults('page_view')
  },
  viewModal: (modalId: string) => {
    captureWithDefaults('modal_view', { modal_id: modalId })
  },
  magicLinkFailureOrExpiration: () => {
    captureWithDefaults('magic_link_failure_or_expiration')
  },
  showPaywallToUser: () => {
    captureWithDefaults('show_paywall_to_user')
  },
  rateLimitUser: () => {
    captureWithDefaults('rate_limit_user')
  },
  frontendAuthenticationError: () => {
    captureWithDefaults('frontend_authentication_error')
  },
}
