import { AVAILABLE_TOPICS, Topic } from '@shared/frontend-and-backend/constants/topics'
import { LANGUAGES_WITH_MULTIPLE_DIALECTS } from '@shared/frontend-and-landing-and-backend/constants/lang-codes'
import { useEffect, useState } from 'react'
import {
  accountActions,
  selectAccountAccessToken,
  selectStudyLanguageOrEnglish,
} from '../../../../state/slices/account-slice'
import { useDispatch, useSelector } from 'react-redux'
import { NavigationButton } from '../navigation-button'
import { POSTHOG_EVENTS } from '../../../../analytics/posthog/posthog-events'
import { ROUTE_PATHS } from '../../../../routing/route-paths'
import { useNavigate } from 'react-router-dom'
import { useApiErrorHandler } from '../../../../hooks/use-api-error-handler'
import { t } from '../../../../i18n/translate'
import { useMutation } from '@tanstack/react-query'
import { updateTopics } from '../../../../transport/transport/our-backend/api/users/users'
import { clsx } from 'clsx'
import {
  Brain,
  Briefcase,
  Camera,
  ChefHat,
  Clapperboard,
  Dumbbell,
  Gamepad2,
  GraduationCap,
  HandshakeIcon,
  Heart,
  History,
  Home,
  LucideIcon,
  MapPin,
  MessageCircle,
  Mic2,
  Music2,
  Newspaper,
  Palette,
  PiggyBank,
  ShoppingBag,
  Sun,
  Syringe,
  Tv,
  Users2,
  Mail,
  ArrowLeft,
} from 'lucide-react'

type TopicIcon = {
  type: 'lucide' | 'emoji'
  icon: LucideIcon | string
}

const TOPIC_ICONS: Record<Topic, TopicIcon> = {
  greetings: { type: 'lucide', icon: HandshakeIcon },
  intro: { type: 'lucide', icon: Users2 },
  numbers: { type: 'emoji', icon: '➊' },
  family: { type: 'emoji', icon: '⚭' },
  food: { type: 'lucide', icon: ChefHat },
  directions: { type: 'lucide', icon: MapPin },
  shopping: { type: 'lucide', icon: ShoppingBag },
  travel: { type: 'lucide', icon: Home },
  medicine: { type: 'lucide', icon: Syringe },
  weather: { type: 'lucide', icon: Sun },
  business: { type: 'lucide', icon: Briefcase },
  letters: { type: 'lucide', icon: Mail },
  chatting: { type: 'lucide', icon: MessageCircle },
  slang: { type: 'lucide', icon: Mic2 },
  news: { type: 'lucide', icon: Newspaper },
  academia: { type: 'lucide', icon: GraduationCap },
  tech: { type: 'lucide', icon: Brain },
  art: { type: 'lucide', icon: Palette },
  history: { type: 'lucide', icon: History },
  socializing: { type: 'lucide', icon: Users2 },
  music: { type: 'lucide', icon: Music2 },
  emotions: { type: 'lucide', icon: Heart },
  nature: { type: 'emoji', icon: '❀' },
  career: { type: 'lucide', icon: Briefcase },
  interview: { type: 'lucide', icon: Users2 },
  cooking: { type: 'lucide', icon: ChefHat },
  gaming: { type: 'lucide', icon: Gamepad2 },
  sports: { type: 'lucide', icon: Dumbbell },
  finance: { type: 'lucide', icon: PiggyBank },
  cinema: { type: 'lucide', icon: Clapperboard },
  dancing: { type: 'lucide', icon: Music2 },
  socialMedia: { type: 'lucide', icon: Tv },
  gardening: { type: 'emoji', icon: '✿' },
  photography: { type: 'lucide', icon: Camera },
  fashion: { type: 'lucide', icon: ShoppingBag },
}

export const ChooseTopicsView = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const studyLanguage = useSelector(selectStudyLanguageOrEnglish)
  const accessToken = useSelector(selectAccountAccessToken)
  const [selectedTopic, setSelectedTopic] = useState<Topic | null>(null)

  const { mutate, isPending, error } = useMutation({
    mutationFn: (topic: Topic | null) => updateTopics(accessToken, topic ? [topic] : []),
    onSuccess: () => {
      dispatch(accountActions.setTopics(selectedTopic ? [selectedTopic] : []))
      navigate(ROUTE_PATHS.ONBOARDING_TERMS_AND_CONDITIONS)
    },
  })

  useApiErrorHandler(error, 'Error when updating topics')

  useEffect(() => {
    POSTHOG_EVENTS.viewPage()
  }, [])

  const onPreviousClick = () => {
    if (studyLanguage && LANGUAGES_WITH_MULTIPLE_DIALECTS.includes(studyLanguage)) {
      dispatch(accountActions.setDialect(null))
      navigate(ROUTE_PATHS.ONBOARDING_CHOOSE_DIALECT)
    } else {
      dispatch(accountActions.resetStudyLanguage())
      navigate(ROUTE_PATHS.ONBOARDING_CHOOSE_STUDY_LANGUAGE)
    }
  }

  const onNextClick = () => {
    if (selectedTopic) {
      mutate(selectedTopic)
    }
  }

  const onSkipClick = () => {
    mutate(null)
  }

  return (
    <div className='flex w-full flex-col'>
      <div className='flex w-full items-center px-4 py-4'>
        <div className='mx-auto flex h-full w-full items-center justify-between md:w-1/2 3xl:w-1/3'>
          <button onClick={onPreviousClick} className='text-gray-500 hover:text-gray-700' disabled={isPending}>
            <ArrowLeft size={24} />
          </button>
          <button onClick={onSkipClick} disabled={isPending} className='text-gray-500 hover:text-gray-700'>
            {t('onboarding.chooseTopics.skip')}
          </button>
        </div>
      </div>

      <div className='mb-52 flex justify-center'>
        <div className='flex w-full flex-col items-center gap-y-4 px-4 pt-0 md:w-1/2 3xl:w-1/3'>
          <div className='flex max-w-md flex-col items-center'>
            <h1 className='max-w-xl text-center text-4xl font-bold tracking-tighter'>
              {t('onboarding.chooseTopics.title')}
            </h1>
            <span className='text-md max-w-xl text-center tracking-tighter text-gray-400'>
              {t('onboarding.chooseTopics.subtitle')}
            </span>
          </div>

          <div className='flex w-full flex-wrap justify-center gap-2'>
            {AVAILABLE_TOPICS.map((topic) => {
              const iconData = TOPIC_ICONS[topic]
              return (
                <button
                  key={topic}
                  onClick={() => setSelectedTopic(topic)}
                  className={clsx(
                    'flex h-8 items-center gap-1.5 rounded-xl border px-3 shadow transition-all focus:outline-none sm:h-10 sm:gap-2 sm:px-4',
                    'text-sm sm:text-lg',
                    selectedTopic === topic
                      ? 'bg-gradient-to-r from-orange-300 to-yellow-300 text-gray-700'
                      : 'bg-white text-gray-500 hover:bg-gray-100'
                  )}
                >
                  {iconData.type === 'lucide' ? (
                    <iconData.icon className='h-4 w-4 sm:h-5 sm:w-5' />
                  ) : (
                    <span className='text-lg sm:text-xl'>{iconData.icon as string}</span>
                  )}
                  <span>{t(`topics.${topic}`)}</span>
                </button>
              )
            })}
          </div>
        </div>
      </div>

      <div className='fixed bottom-0 w-full bg-gray-50'>
        <div className='pointer-events-none absolute bottom-full left-0 right-0 h-40 bg-gradient-to-b from-transparent to-gray-50' />
        <div className='mx-auto flex w-full px-4 pb-8 pt-4 md:w-1/2 3xl:w-1/3'>
          <NavigationButton
            onClick={onNextClick}
            disabled={!selectedTopic || isPending}
            className='w-full bg-gradient-to-r from-indigo-500 to-indigo-600 text-white'
          >
            {isPending ? t('onboarding.chooseTopics.loading') : t('onboarding.chooseTopics.next')}
          </NavigationButton>
        </div>
      </div>
    </div>
  )
}
