import { useSelector } from 'react-redux'
import {
  selectAccountAccessToken,
  selectDialectOrDefaultDialectOrEnglishDefaultDialect,
  selectMotherLanguageOrEnglish,
} from '../../../state/slices/account-slice.ts'
import {
  DialectCode,
  LANGUAGES_WITH_TRANSLITERATION,
  SupportedMotherLanguage,
  SupportedStudyLanguage,
} from '@shared/frontend-and-landing-and-backend/constants/lang-codes'
import { Popover, PopoverContent, PopoverTrigger } from '../../shadcn/popover.tsx'
import { useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { ResponseWrapper } from '@shared/frontend-and-backend/body-types/response-wrapper.types.ts'
import {
  TranslateTextResponse,
  TranslateWordResponse,
} from '@shared/frontend-and-backend/body-types/translation/translation.types.ts'
import { QUERY_KEYS } from '../../../transport/transport/our-backend/api/query-keys.ts'
import {
  getTextTranslation,
  getWordTranslation,
} from '../../../transport/transport/our-backend/api/translation/translation.ts'
import { getGeneratedIpaTranscription } from '../../../transport/transport/our-backend/api/pronunciation/pronunciation.ts'
import { GetIpaResponse } from '@shared/frontend-and-backend/body-types/pronunciation/transcribe-ipa.types.ts'
import { selectShouldShowIpa, selectShouldShowTransliteration } from '../../../state/slices/preferences-slice.ts'
import { splitByEmptySpacesAndMergeLonelyPunctuation } from '@shared/frontend-and-backend/utils/text-utils.ts'
import { CopyableIpaWord, CopyableTranslation, CopyableTransliteratedWord, NarrowSkeleton } from './exercise-atoms.tsx'
import { getTransliteration } from '../../../transport/transport/our-backend/api/transliteration/translation.ts'
import { TransliterationResponse } from '@shared/frontend-and-backend/body-types/transliteration/transliteration.types.ts'
import { useApiErrorHandler } from '../../../hooks/use-api-error-handler.ts'
import { AddOrDeleteFromSavedWordsSection } from './evaluation/atoms/add-or-delete-from-saved-words-section.tsx'
import { Separator } from '../../design-system/separator.tsx'

type Props = {
  text: string
  studyLanguage: SupportedStudyLanguage
}

export const TextForExercise = ({ text, studyLanguage }: Props) => {
  const accessToken: string = useSelector(selectAccountAccessToken)
  const words: string[] = splitByEmptySpacesAndMergeLonelyPunctuation(text)
  const motherLanguage: SupportedMotherLanguage = useSelector(selectMotherLanguageOrEnglish)
  const [textToTranslate, setTextToTranslate] = useState<string>('')
  const dialect: DialectCode = useSelector(selectDialectOrDefaultDialectOrEnglishDefaultDialect)
  const shouldShowIpa: boolean = useSelector(selectShouldShowIpa)
  const shouldShowTransliteration =
    useSelector(selectShouldShowTransliteration) && LANGUAGES_WITH_TRANSLITERATION.includes(studyLanguage)
  const [selectedWordIndex, setSelectedWordIndex] = useState<number>(0)

  const { data: translationData, error: translationError } = useQuery<ResponseWrapper<TranslateWordResponse>>({
    queryKey: [
      QUERY_KEYS.TRANSLATE_WORD,
      textToTranslate,
      motherLanguage,
      dialect,
      words,
      selectedWordIndex,
      accessToken,
    ],
    queryFn: () => getWordTranslation(textToTranslate, dialect, motherLanguage, words, selectedWordIndex, accessToken),
    enabled: !!textToTranslate,
    staleTime: Infinity,
  })

  const { data: ipaTranscriptionData, error: ipaTranscriptionError } = useQuery<ResponseWrapper<GetIpaResponse>>({
    // we do not need to include accessToken in the query key
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: [QUERY_KEYS.IPA_TRANSCRIPTION, text, motherLanguage, studyLanguage, dialect],
    queryFn: () => getGeneratedIpaTranscription(text, studyLanguage, dialect, accessToken),
    enabled: !!text,
    staleTime: Infinity,
  })

  const { data: transliterationData, error: transliterationError } = useQuery<ResponseWrapper<TransliterationResponse>>(
    {
      // we do not need to include accessToken in the query key
      // eslint-disable-next-line @tanstack/query/exhaustive-deps
      queryKey: [QUERY_KEYS.TRANSLITERATION, text, motherLanguage, studyLanguage, dialect],
      queryFn: () => getTransliteration(text, studyLanguage, accessToken),
      enabled: !!text && LANGUAGES_WITH_TRANSLITERATION.includes(studyLanguage),
      staleTime: Infinity,
    }
  )

  // We call the text translation query here so that it's already loaded when the user opens the translation modal
  useQuery<ResponseWrapper<TranslateTextResponse>>({
    // we do not need to include accessToken in the query key
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: [QUERY_KEYS.TRANSLATE_TEXT, text, motherLanguage, dialect],
    queryFn: () => getTextTranslation(text, dialect, motherLanguage, accessToken),
    enabled: !!text,
    staleTime: Infinity,
  })

  useApiErrorHandler(
    translationError,
    `translation error in TextForExercise component, studyLanguage - ${studyLanguage}, motherLanguage - ${motherLanguage}, textToTranslate ${textToTranslate}, ipaTranscriptionError - ${ipaTranscriptionError}`,
    'There was a translation error. Please try again.'
  )
  useApiErrorHandler(
    transliterationError,
    `transliteration error in TextForExercise component, studyLanguage - ${studyLanguage}, motherLanguage - ${motherLanguage}, textToTranslate ${textToTranslate}, ipaTranscriptionError - ${ipaTranscriptionError}`
  )
  useApiErrorHandler(
    ipaTranscriptionError,
    `ipaTranscription error in TextForExercise component, studyLanguage - ${studyLanguage}, motherLanguage - ${motherLanguage}, textToTranslate ${textToTranslate}, ipaTranscriptionError - ${ipaTranscriptionError}`
  )

  const translation: string | undefined = translationData?.data?.translation
  const ipaTranscriptionWords: string[] | undefined = ipaTranscriptionData?.data?.ipaTranscription
  const transliterationWords: string[] | undefined = splitByEmptySpacesAndMergeLonelyPunctuation(
    transliterationData?.data?.transliteration || ''
  )

  const handleTap = (text: string | null, wordIndex: number) => {
    if (text) {
      setSelectedWordIndex(wordIndex)
      setTextToTranslate(text)
    }
  }

  return (
    <div className='mb-4 flex flex-col items-center gap-2 px-1 md:max-w-3xl'>
      <div className='mb-4 flex w-full flex-wrap justify-center gap-2 px-1'>
        {words.map((word: string, index: number) => {
          const hasTransliteration = transliterationWords && transliterationWords[index]
          const hasIpa = ipaTranscriptionWords && ipaTranscriptionWords[index]
          return (
            <div className='flex flex-col gap-y-0' key={'word' + index}>
              {/*to understand this hell have a look at:*/}
              {/*https://www.notion.so/grammarians/Tons-of-divs-for-below-and-above-the-words-21efda77261a4161b2018f6470ff7803*/}
              {LANGUAGES_WITH_TRANSLITERATION.includes(studyLanguage) && !shouldShowTransliteration && (
                <div className='h-6' />
              )}
              {!shouldShowIpa && <div className='h-6' />}
              {LANGUAGES_WITH_TRANSLITERATION.includes(studyLanguage) &&
                shouldShowTransliteration &&
                hasTransliteration && <CopyableTransliteratedWord text={transliterationWords[index]} />}
              {shouldShowTransliteration && !hasTransliteration && <NarrowSkeleton />}
              {shouldShowIpa && hasIpa && <CopyableIpaWord text={ipaTranscriptionWords[index]} />}
              {shouldShowIpa && !hasIpa && <NarrowSkeleton />}
              <Popover>
                <PopoverTrigger>
                  <div
                    onClick={() => handleTap(word, index)}
                    className='flex h-8 items-center rounded-xl border border-gray-200 transition-colors duration-100 hover:bg-gray-100 active:bg-gray-200 md:h-10'
                  >
                    <span className='w-full px-2 text-gray-700 md:px-4 md:text-gray-600'>{word}</span>
                  </div>
                </PopoverTrigger>
                <PopoverContent className='bg-white shadow-lg'>
                  <div className='flex flex-col items-start justify-center gap-4 p-2'>
                    <CopyableTranslation translation={translation} />
                    <Separator />
                    <AddOrDeleteFromSavedWordsSection language={studyLanguage} contextWords={words} wordIndex={index} />
                  </div>
                </PopoverContent>
              </Popover>
            </div>
          )
        })}
      </div>
    </div>
  )
}
