import { Award, CircleHelp, Flame, Trophy } from 'lucide-react'
import { useEffect, useState } from 'react'
import { ROUTE_PATHS } from '../../../../routing/route-paths.ts'
import { Link } from 'react-router-dom'
import { Popover, PopoverContent, PopoverTrigger } from '../../../shadcn/popover.tsx'
import { t } from '../../../../i18n/translate'

type Props = {
  currentStreak: number
  longestStreak: number
  totalDays: number
  numberOfDaysOfNextStreakBadge: number
  numberOfAchievedStreakBadges: number
}

export const Streak = ({
  currentStreak,
  longestStreak,
  totalDays,
  numberOfDaysOfNextStreakBadge,
  numberOfAchievedStreakBadges,
}: Props) => {
  const [progressWidth, setProgressWidth] = useState(0)

  useEffect(() => {
    setProgressWidth((currentStreak / numberOfDaysOfNextStreakBadge) * 100)
  }, [totalDays])

  const daysUntilNextBadge = numberOfDaysOfNextStreakBadge - currentStreak

  return (
    <div className='rounded-lg border bg-white p-6 shadow-lg transition-all duration-1000 ease-in-out'>
      <h2 className='mb-4 flex flex-row items-center gap-x-2 text-center text-2xl font-bold text-gray-900'>
        {t('streak.title')}
        <div className='flex flex-row'>
          <Popover>
            <PopoverTrigger>
              <CircleHelp className='h-4 w-4 text-stone-400' />
            </PopoverTrigger>
            <PopoverContent className='bg-white text-center text-sm shadow-lg'>{t('streak.info')}</PopoverContent>
          </Popover>
        </div>
      </h2>
      <div className='mb-6 flex items-center justify-center'>
        <div className='relative'>
          <Flame className='h-20 w-20 animate-pulse text-orange-500' />
        </div>
      </div>
      <p className='mb-2 flex flex-row items-center justify-center gap-x-2 text-center text-lg font-semibold text-gray-700'>
        {t('streak.current').replace('{count}', String(currentStreak))}
      </p>
      <p className='mb-6 text-center text-sm text-indigo-600'>
        {daysUntilNextBadge > 0
          ? t('streak.nextBadge')
              .replace('{count}', String(daysUntilNextBadge))
              .replace('{target}', String(numberOfDaysOfNextStreakBadge))
          : t('streak.highestBadge')}
      </p>
      <div className='mb-6 grid grid-cols-1 gap-4'>
        <Popover>
          <PopoverTrigger>
            <div className='rounded-lg bg-purple-50 p-4'>
              <div className='mb-2 flex items-center justify-center'>
                <Trophy className='mr-2 h-6 w-6 text-purple-600' />
                <span className='text-xl font-semibold text-purple-600'>{longestStreak} days</span>
              </div>
              <p className='text-center text-sm text-gray-600'>{t('streak.longest.subtitle')}</p>
            </div>
          </PopoverTrigger>
          <PopoverContent className='bg-white text-center text-sm shadow-lg'>{t('streak.longest.info')}</PopoverContent>
        </Popover>
      </div>
      <div className='mt-4'>
        <div className='mb-2 flex items-center justify-between'>
          <span className='text-sm font-medium text-gray-600'>{t('streak.progress')}</span>
          <span className='text-sm font-semibold text-gray-900'>
            {currentStreak}/{numberOfDaysOfNextStreakBadge} {t('streak.progress.days')}
          </span>
        </div>
        <div className='h-2 overflow-hidden rounded-full bg-gray-200'>
          <div
            className='h-full rounded-full bg-gradient-to-r from-indigo-500 to-purple-500 transition-all duration-1000 ease-in'
            style={{ width: `${progressWidth}%` }}
          />
        </div>
      </div>
      <div className='mt-6 flex w-full items-center justify-between'>
        <Link to={ROUTE_PATHS.PROGRESS_BADGES_STREAK} className='flex w-full justify-center'>
          <div className='flex w-full justify-center'>
            <Award className='mr-2 h-5 w-5 text-yellow-500' />
            <span className='text-sm text-gray-600'>
              {t('streak.badges').replace('{count}', String(numberOfAchievedStreakBadges))}
            </span>
          </div>
        </Link>
      </div>
    </div>
  )
}
