import {
  NUMBER_OF_DAYS_IN_FREE_TRIAL,
  REFUND_PERIOD_IN_DAYS,
} from '@shared/frontend-and-landing-and-backend/constants/pricing-constants.ts'
import { Dictionary } from '../translate.ts'
import { englishDictionary } from './english-dictionary.ts'

const CONTACT_US_TEXT = 'Kontakt'

export const polishDictionary: Dictionary = {
  ...englishDictionary, // if a Polish translation is missing, an English translation is used
  'onboarding.chooseMotherLanguage.title': 'Jaki jest Twój język ojczysty?',
  'onboarding.chooseMotherLanguage.subtitle': '(Zawsze możesz go zmienić później)',
  'onboarding.chooseMotherLanguage.searchPlaceholder': 'Wpisz swój język ojczysty',
  'onboarding.chooseMotherLanguage.other': 'Inny',
  'onboarding.chooseMotherLanguage.skip': 'Pomiń',
  'onboarding.chooseMotherLanguage.next': 'Dalej',
  'onboarding.chooseMotherLanguage.loading': 'Ładowanie...',

  'onboarding.chooseStudyLanguage.title': 'Jakiego języka chcesz się uczyć?',
  'onboarding.chooseStudyLanguage.subtitle': '(Zawsze możesz go zmienić później)',
  'onboarding.chooseStudyLanguage.previous': 'Wstecz',
  'onboarding.chooseStudyLanguage.next': 'Dalej',
  'onboarding.chooseStudyLanguage.loading': 'Ładowanie...',

  'onboarding.termsAndConditions.title': 'Przygotuj się',
  'onboarding.termsAndConditions.subtitle': 'do sklonowania głosu',
  'onboarding.termsAndConditions.removeVoiceInfo':
    'Zawsze możesz później usunąć swój sklonowany głos. Nie jest on używany przez nikogo poza aplikacją.',
  'onboarding.termsAndConditions.step1.title': 'Krok 1',
  'onboarding.termsAndConditions.step1.description':
    'W następnym kroku przeczytasz na głos krótki tekst. Dla najlepszych rezultatów upewnij się, że jesteś w cichym otoczeniu. Możesz popełniać błędy, nie wpłyną one na klonowanie głosu.',
  'onboarding.termsAndConditions.step2.title': 'Krok 2',
  'onboarding.termsAndConditions.step2.description':
    'Twój sklonowany głos zostanie zintegrowany z procesem nauki, dostosowując ćwiczenia specjalnie dla Ciebie.',
  'onboarding.termsAndConditions.consent':
    'Wyrażam zgodę na sklonowanie mojego głosu w celu spersonalizowanej nauki w ramach YourBestAccent.com i akceptuję',
  'onboarding.termsAndConditions.termsOfService': 'Regulamin',
  'onboarding.termsAndConditions.and': 'oraz',
  'onboarding.termsAndConditions.privacyPolicy': 'Politykę Prywatności',
  'onboarding.termsAndConditions.previous': 'Wstecz',
  'onboarding.termsAndConditions.next': 'Przejdź do klonowania',
  'onboarding.termsAndConditions.marketingConsent.label': 'Otrzymuj aktualizacje i wskazówki',
  'onboarding.termsAndConditions.marketingConsent.info':
    'Bądź na bieżąco z nowymi funkcjami, wskazówkami do nauki i specjalnymi ofertami, które pomogą Ci w nauce języka. Możesz zrezygnować w każdej chwili.',

  'onboarding.chooseDialect.title': 'Wybierz dialekt, którego chcesz się uczyć:',
  'onboarding.chooseDialect.subtitle': '(Zawsze możesz go zmienić później)',
  'onboarding.chooseDialect.previous': 'Wstecz',
  'onboarding.chooseDialect.next': 'Dalej',
  'onboarding.chooseDialect.loading': 'Ładowanie...',

  'onboarding.chooseTopics.title': 'Na jakim temacie chcesz się skupić?',
  'onboarding.chooseTopics.subtitle': '(Zawsze możesz to później zmienić)',
  'onboarding.chooseTopics.previous': 'Poprzedni',
  'onboarding.chooseTopics.next': 'Dalej',
  'onboarding.chooseTopics.loading': 'Ładowanie...',
  'onboarding.chooseTopics.skip': 'Pomiń',

  'auth.signIn.title': 'Zacznij doskonalić wymowę',
  'auth.signIn.subtitle': 'swoją wymowę',
  'auth.signIn.withEmail': 'Zaloguj się przez Email',
  'auth.signIn.withGoogle': 'Zaloguj się przez Google',
  'auth.signUp.withEmail': 'Zarejestruj się przez Email',
  'auth.signUp.withGoogle': 'Zarejestruj się przez Google',

  'auth.signInEmail.title': 'Zaloguj się przez Email',
  'auth.signUpEmail.title': 'Zarejestruj się przez Email',
  'auth.email.placeholder': 'Adres email',
  'auth.email.invalidError': 'Wprowadź poprawny adres email',
  'auth.email.sendingEmail': 'Wysyłanie emaila',
  'auth.email.or': 'Lub',
  'auth.email.noAccount': 'Nie masz konta?',
  'auth.email.haveAccount': 'Masz już konto?',
  'auth.email.signUp': 'Zarejestruj się',
  'auth.email.signIn': 'Zaloguj się',

  'auth.emailVerify.title': 'Zweryfikuj swój Email',
  'auth.emailVerify.verifying': 'Weryfikacja...',
  'auth.emailVerify.verify': 'Zweryfikuj',
  'auth.emailVerify.error.title': 'Link jest nieprawidłowy lub wygasł',
  'auth.emailVerify.error.returnToSignIn': 'Wróć do logowania',

  'auth.verificationSent.title': 'Email weryfikacyjny wysłany',
  'auth.verificationSent.sentTo': 'Wysłaliśmy email weryfikacyjny na adres:',
  'auth.verificationSent.checkInbox': 'Sprawdź swoją skrzynkę i kliknij w',
  'auth.verificationSent.linkToSignIn': 'link aby się zalogowa',
  'auth.verificationSent.linkToSignUp': 'link aby dokończyć rejestrację',

  'languages.english': 'angielski',
  'languages.spanish': 'hiszpański',
  'languages.german': 'niemiecki',
  'languages.french': 'francuski',
  'languages.italian': 'włoski',
  'languages.polish': 'polski',
  'languages.czech': 'czeski',
  'languages.ukrainian': 'ukraiński',
  'languages.russian': 'rosyjski',
  'languages.portuguese': 'portugalski',
  'languages.vietnamese': 'wietnamski',
  'languages.chinese': 'chiński',
  'languages.hindi': 'hindi',
  'languages.indonesian': 'indonezyjski',
  'languages.malay': 'malajski',
  'languages.japanese': 'japoński',
  'languages.korean': 'koreański',
  'languages.tamil': 'tamilski',
  'languages.turkish': 'turecki',
  'languages.romanian': 'rumuński',
  'languages.swedish': 'szwedzki',
  'languages.norwegian': 'norweski',
  'languages.danish': 'duński',
  'languages.slovak': 'słowacki',
  'languages.dutch': 'holenderski',
  'languages.thai': 'tajski',
  'languages.hungarian': 'węgierski',
  'languages.greek': 'grecki',
  'languages.finnish': 'fiński',
  'languages.bulgarian': 'bułgarski',
  'languages.catalan': 'kataloński',

  'dialects.americanEnglish': 'angielski amerykański',
  'dialects.britishEnglish': 'angielski brytyjski',
  'dialects.australianEnglish': 'angielski australijski',
  'dialects.scottishEnglish': 'angielski szkocki',
  'dialects.castilianSpanish': 'hiszpański kastylijski',
  'dialects.mexicanSpanish': 'hiszpański meksykański',
  'dialects.colombianSpanish': 'hiszpański kolumbijski',
  'dialects.argentinianSpanish': 'hiszpański argentyński',
  'dialects.peruvianSpanish': 'hiszpański peruwiański',
  'dialects.parisianFrench': 'francuski paryski',
  'dialects.belgianFrench': 'francuski belgijski',
  'dialects.canadianFrench': 'francuski kanadyjski',
  'dialects.standardGerman': 'niemiecki standardowy',
  'dialects.standardItalian': 'włoski standardowy',
  'dialects.standardPolish': 'polski standardowy',
  'dialects.brazilianPortuguese': 'portugalski brazylijski',
  'dialects.europeanPortuguese': 'portugalski europejski',
  'dialects.standardRussian': 'rosyjski standardowy',
  'dialects.standardUkrainian': 'ukraiński standardowy',
  'dialects.standardCzech': 'czeski standardowy',
  'dialects.standardDanish': 'duński standardowy',
  'dialects.standardDutch': 'holenderski standardowy',
  'dialects.standardFinnish': 'fiński standardowy',
  'dialects.standardIndonesian': 'indonezyjski standardowy',
  'dialects.standardMalay': 'malajski standardowy',
  'dialects.standardRomanian': 'rumuński standardowy',
  'dialects.standardSlovak': 'słowacki standardowy',
  'dialects.standardSwedish': 'szwedzki standardowy',
  'dialects.standardTurkish': 'turecki standardowy',
  'dialects.standardHungarian': 'węgierski standardowy',
  'dialects.standardNorwegian': 'norweski standardowy',

  'dashboard.exercises.title': 'Ćwiczenia',
  'dashboard.exercises.subtitle': 'Kontynuuj od ostatniego miejsca lub odkryj nowe ćwiczenia.',
  'dashboard.streak.title': 'Twoja seria nauki',
  'dashboard.streak.subtitle': 'Sprawdź swoje postępy',
  'dashboard.exercises.beginner.title': 'Początkujący {language}',
  'dashboard.exercises.beginner.description': 'Podstawowe słowa i wyrażenia',
  'dashboard.exercises.levelBased.title': 'Poziom',
  'dashboard.exercises.levelBased.description': 'słowa dopasowane do twojego poziomu',
  'dashboard.exercises.random.title': 'Losowe zdania',
  'dashboard.exercises.random.description': 'Ćwicz z losowo wybranymi zdaniami',
  'dashboard.exercises.custom.title': 'Własny tekst',
  'dashboard.exercises.custom.description': 'Użyj własnego tekstu do ukierunkowanej praktyki',
  'dashboard.savedWords.button': 'zapisane słowa',
  'dashboard.feedback.button': 'Podziel się opinią',
  'dashboard.mobile.exercises.title': 'Nasze ćwiczenia',
  'dashboard.mobile.streak.title': 'Twoja seria nauki',
  'dashboard.streak.popup.info':
    'Dzień jest liczony do twojej serii, jeśli nauczysz się co najmniej jednego słowa. Słowo jest uznawane za nauczone, gdy po raz pierwszy zostanie oznaczone na zielono w ocenie ćwiczenia.',
  'dashboard.streak.current.title': '{count}-dniowa seria',
  'dashboard.streak.current.subtitle': 'Pozostało {count} dni do zdobycia odznaki za {target}-dniową serię!',
  'dashboard.streak.longest.title': '{count}-dniowa seria',
  'dashboard.streak.longest.subtitle': 'Twoja najdłuższa seria',

  'exercise.record.instructions':
    'Kliknij przycisk nagrywania i przeczytaj powyższy tekst na głos. Gdy skończysz, kliknij stop, a my przeanalizujemy twoją wymowę.',
  'exercise.button.tryAgain': 'Spróbuj ponownie',
  'exercise.button.anotherExercise': 'Inne ćwiczenie',
  'exercise.button.nextSentence': 'Następne zdanie',
  'exercise.button.anotherText': 'Inny tekst',
  'exercise.button.differentText': 'Inny tekst',

  'exercise.controls.translate': 'Przetłumacz',
  'exercise.controls.share': 'Udostępnij',
  'exercise.controls.settings': 'Ustawienia',
  'exercise.controls.searchLanguage': 'Szukaj języka...',
  'exercise.controls.noLanguageFound': 'Nie znaleziono języka.',

  'exercise.custom.startPracticing': 'Rozpocznij ćwiczenie',
  'exercise.custom.title': 'Wprowadź tekst do ćwiczeń',
  'exercise.custom.languageSelect.help':
    'Postaramy się wykryć język twojego tekstu automatycznie, ale lepiej jeśli wybierzesz go manualnie.',
  'exercise.custom.languageSelect.switchTo': 'przełącz na:',
  'exercise.custom.textInput.placeholder':
    'Wpisz tutaj tekst do ćwiczeń. Przykład: "W Szczebrzeszynie chrząszcz brzmi w trzcinie."',
  'exercise.custom.error.tooShort': 'Tekst do ćwiczeń musi mieć co najmniej 2 znaki',
  'exercise.custom.error.tooLong': 'Tekst do ćwiczeń musi być krótszy niż 750 znaków',
  'exercise.custom.success.languageUpdate': 'Język nauki i dialekt zostały zaktualizowane pomyślnie',
  'exercise.custom.error.languageUpdate': 'Nie udało się zaktualizować języka nauki',

  'exercise.skeleton.loading': 'Tworzenie ćwiczenia...',

  'exercise.cefr.title': 'Poziom CEFR',
  'exercise.cefr.info':
    'Europejski System Opisu Kształcenia Językowego (CEFR) definiuje biegłość językową w sześciu poziomach: A1 i A2 (Poziom podstawowy), B1 i B2 (Poziom samodzielności), C1 i C2 (Poziom biegłości). Każdy poziom reprezentuje rosnące umiejętności językowe, od początkującego do prawie natywnego. Te standardowe poziomy pomagają uczącym się, nauczycielom i pracodawcom zrozumieć i porównać umiejętności językowe w skali międzynarodowej.',
  'exercise.cefr.position': 'Pozycja:',
  'exercise.cefr.beginner': 'Początkujący',
  'exercise.cefr.advanced': 'Zaawansowany',
  'exercise.cefr.frequency.info':
    'Wygenerujemy zdania ze słowami znajdującymi się w okolicy tej pozycji na liście frekwencyjnej. Lista frekwencyjna pokazuje, które słowa są najczęściej używane w języku. Im niższa pozycja słowa na liście frekwencyjnej, tym częściej pojawia się w języku. Słowa na wyższych pozycjach są rzadsze, co często oznacza, że są trudniejsze do nauczenia.',

  'exercise.settings.length.title': 'Długość ćwiczenia',
  'exercise.settings.length.wordLength': 'ilość słów:',
  'exercise.settings.length.info': 'Długość ćwiczenia może się nieznacznie różnić od długości, którą zażądałeś.',

  'exercise.settings.ipaAndTransliteration': 'IPA i transliteracja',
  'exercise.settings.ipaInfo':
    'Międzynarodowy alfabet fonetyczny (IPA) i transliteracja pojawią się nad słowami do nauki',
  'exercise.settings.ipa.title': 'IPA',
  'exercise.settings.ipa.info':
    'Transkrypcja IPA (Międzynarodowy Alfabet Fonetyczny) jest funkcją eksperymentalną i może zawierać błędy.',

  'translate.modal.originalText': 'Tekst oryginalny',
  'translate.modal.translation': 'Tłumaczenie',

  'exercise.settings.title': 'Ustawienia ćwiczenia',
  'exercise.settings.level': 'Poziom:',

  'navbar.contactUs': CONTACT_US_TEXT,

  'settings.title': 'Ustawienia',
  'settings.description': 'Wprowadź zmiany w swoich ustawieniach',
  'settings.studyLanguage.title': 'Uczę się:',
  'settings.studyLanguage.description': 'Ten język będzie używany w ćwiczeniach.',
  'settings.motherLanguage.title': 'Mój język ojczysty:',
  'settings.dialect.title': 'Mój preferowany akcent:',
  'settings.dialect.description': 'Uwaga: niektóre akcenty są w wersji beta. Szybkość audio może się różnić.',
  'settings.searchLanguage': 'Szukaj języka...',
  'settings.noLanguageFound': 'Nie znaleziono języka.',
  'settings.success.motherLanguage': 'Język ojczysty zaktualizowany pomyślnie',
  'settings.error.motherLanguage': 'Nie udało się zaktualizować języka ojczystego',
  'settings.success.studyLanguage': 'Język nauki i dialekt zaktualizowane pomyślnie',
  'settings.error.studyLanguage': 'Nie udało się zaktualizować języka nauki',
  'settings.success.dialect': 'Dialekt zaktualizowany pomyślnie',
  'settings.error.dialect': 'Nie udało się zaktualizować akcentu',

  'exercise.settings.transliteration.title': 'Transliteracja',
  'exercise.settings.transliteration.info':
    'Zapewniamy transliterację dla języków używających alfabetów innych niż łaciński.',

  'account.modal.title': 'Konto',
  'account.modal.description': 'Twoje informacje i ustawienia konta.',
  'account.modal.fullName': 'Imię i nazwisko',
  'account.modal.email': 'Email',
  'account.modal.publicNickname': 'Publiczny pseudonim',
  'account.modal.noNickname': 'Brak ustawionego pseudonimu',
  'account.modal.billing': 'Płatności',
  'account.modal.dangerZone': 'Strefa zagrożenia',
  'account.modal.marketingEmails.title': 'Powiadomienia emailowe',
  'account.modal.marketingEmails.info': 'Aktualizacje produktów',
  'account.modal.marketingEmails.popoverInfo':
    'Bądź na bieżąco z nowymi funkcjami, wskazówkami do nauki i specjalnymi ofertami, które pomogą Ci w nauce języka. Możesz zrezygnować w każdej chwili.',
  'account.modal.marketingEmails.updateSuccess': 'Preferencje emailowe zostały zaktualizowane',
  'account.modal.marketingEmails.updateError': 'Nie udało się zaktualizować preferencji emailowych',

  'nickname.title': 'Wybierz swój pseudonim',
  'nickname.description': 'Ten pseudonim będzie publiczny. Zawsze możesz go zmienić później.',
  'nickname.input.placeholder': 'Wpisz swój pseudonim',
  'nickname.button.saving': 'Zapisywanie...',
  'nickname.button.join': 'Dołącz do rankingu',

  'leaderboard.title': 'Ranking',
  'leaderboard.description': 'Zobacz, jak wypadasz na tle innych uczących się',
  'leaderboard.empty': 'Brak wpisów. Bądź pierwszą osobą w rankingu!',
  'leaderboard.position.title': 'Twoja pozycja',
  'leaderboard.position.notOnBoard': 'Nie ma Cię jeszcze w rankingu. Ćwicz, aby zacząć rywalizować z innymi!',
  'leaderboard.position.of': 'z',
  'leaderboard.words': 'słów',
  'leaderboard.anonymous': 'Anonimowy użytkownik',

  'filter.allLanguages': 'Wszystkie języki',

  'navbar.dropdown.account': 'Konto',
  'navbar.dropdown.settings': 'Ustawienia',
  'navbar.dropdown.exercises': 'Ćwiczenia',
  'navbar.dropdown.leaderboard': 'Ranking',
  'navbar.dropdown.progress': 'Postępy',
  'navbar.dropdown.pricing': 'Cennik',
  'navbar.dropdown.contact': 'Kontakt',
  'navbar.dropdown.about': 'O nas',
  'navbar.dropdown.signOut': 'Wyloguj się',

  'pricing.premium.title': 'Wybierz plan',
  'pricing.features.title': 'Funkcje',
  'pricing.button.practice': 'Zacznij ćwiczyć',

  'pricing.faq.title': 'Często Zadawane Pytania',
  'pricing.faq.trial.question': 'Jak mogę uzyskać okres próbny?',
  'pricing.faq.trial.answer.withCard': `Możesz uzyskać ${NUMBER_OF_DAYS_IN_FREE_TRIAL}-dniowy okres próbny, rejestrując się i podając dane karty. Nie martw się, nie zostaniesz obciążony w okresie próbnym i możesz anulować w dowolnym momencie. Nawet jeśli zapomnisz i zostaniesz obciążony, mamy ${REFUND_PERIOD_IN_DAYS}-dniową gwarancję zwrotu pieniędzy.`,
  'pricing.faq.trial.answer.noCard': `Możesz uzyskać okres próbny, rejestrując się w planie Free Trial. Karta kredytowa nie jest wymagana, a okres próbny automatycznie kończy się po ${NUMBER_OF_DAYS_IN_FREE_TRIAL} dniach.`,
  'pricing.faq.security.question': 'Czy usługa płatności jest bezpieczna?',
  'pricing.faq.security.answer':
    'Tak, nasza usługa płatności jest bezpieczna. Używamy Stripe do przetwarzania płatności i nie przechowujemy żadnych informacji o Twojej karcie. Stripe zapewnia standardy bezpieczeństwa na poziomie bankowym.',
  'pricing.faq.upgrade.question': 'Czy mogę zmienić swój plan subskrypcji?',
  'pricing.faq.upgrade.answer':
    'Tak, możesz zmienić swój plan w dowolnym momencie. Przejdź do prawego górnego rogu aplikacji po zalogowaniu, kliknij swoje zdjęcie profilowe, otwórz sekcję Konto w menu i kliknij Zarządzaj Subskrypcją, zostaniesz przekierowany do portalu płatności Stripe, gdzie możesz zmienić plany.',
  'pricing.faq.cancel.question': 'Czy mogę anulować subskrypcję w dowolnym momencie?',
  'pricing.faq.cancel.answer':
    'Możesz anulować subskrypcję w dowolnym momencie. Przejdź do sekcji Konto po zalogowaniu i kliknij Zarządzaj Subskrypcją, zostaniesz przekierowany do portalu płatności Stripe, gdzie możesz pobrać faktury, zmienić plany i anulować subskrypcję.',
  'pricing.faq.refund.question': 'Czy mogę otrzymać zwrot pieniędzy?',
  'pricing.faq.refund.answer': `Tak, możesz otrzymać zwrot pieniędzy w ciągu ${REFUND_PERIOD_IN_DAYS} dni od obciążenia. Nie chcemy obciążać klientów, którzy nie są zadowoleni z produktu. Przeczytaj więcej o naszej polityce zwrotów tutaj:`,
  'pricing.faq.refund.policy': 'Polityka zwrotów',

  'dangerZone.title': 'Strefa zagrożenia',
  'dangerZone.warning.title': 'Zachowaj ostrożność',
  'dangerZone.warning.description': 'Tych działań nie można cofnąć. Zachowaj ostrożność.',

  'progress.title': 'Postęp',
  'progress.tabs.streak': 'Seria',
  'progress.tabs.badges': 'Odznaki',
  'progress.tabs.stats': 'Statystyki',

  'stats.title': 'Statystyki',
  'stats.tabs.learnedWords': 'Nauczone słowa',
  'stats.tabs.savedWords': 'Zapisane słowa',

  'streak.title': 'Twoja seria nauki',
  'streak.info':
    'Dzień jest liczony do twojej serii, jeśli nauczysz się co najmniej jednego słowa. Słowo jest uznawane za nauczone, gdy po raz pierwszy zostanie oznaczone na zielono w ocenie ćwiczenia.',
  'streak.current': '{count}-dniowa seria!',
  'streak.nextBadge': 'Pozostało {count} dni do zdobycia odznaki za {target}-dniową serię!',
  'streak.highestBadge': 'Osiągnąłeś najwyższą odznakę! Tak trzymaj!',
  'streak.longest.title': '{count} dni',
  'streak.longest.subtitle': 'Najdłuższa seria',
  'streak.longest.info': 'To najdłuższa seria, jaką osiągnąłeś. Tak trzymaj!',
  'streak.progress': 'Postęp',
  'streak.progress.days': 'dni',
  'streak.badges': 'Zdobyte odznaki: {count}',

  'stats.learnedWords.empty': 'Brak nauczonych słów dla języka: {language}.',
  'stats.learnedWords.noWords': 'Nie masz jeszcze żadnych nauczonych słów',
  'stats.learnedWords.goToExercises': 'Przejdź do ćwiczeń, aby nauczyć się nowych słów',
  'stats.learnedWords.button': 'PRZEJDŹ DO ĆWICZEŃ',
  'stats.learnedWords.table.word': 'Słowo',
  'stats.learnedWords.table.language': 'Język',
  'stats.learnedWords.table.learnedOn': 'Nauczone',
  'stats.learnedWords.chart.title': 'Nauczone słowa',
  'stats.learnedWords.chart.subtitle': 'pogrupowane według języka',

  'contact.title': 'Kontakt',
  'contact.help.title': 'Pomóż nam ulepszyć YourBestAccent',
  'contact.help.description':
    'Podziel się swoją opinią lub zasugeruj ulepszenia założycielom. Czytamy i odpowiadamy na każde zgłoszenie!',
  'contact.form.message.label': 'Wiadomość do założycieli',
  'contact.form.message.placeholder': 'Powiedz nam, co myślisz lub opisz napotkane problemy...',
  'contact.form.email.label': 'Email',
  'contact.form.email.placeholder': 'twoj@email.com',
  'contact.form.name.label': 'Imię (opcjonalne)',
  'contact.form.name.placeholder': 'Twoje imię',
  'contact.button.cancel': 'Anuluj',
  'contact.button.send': 'Wyślij wiadomość',
  'contact.button.sending': 'Wysyłanie...',
  'contact.button.error': 'Wystąpił błąd. Spróbuj ponownie.',
  'contact.button.close': 'Zamknij',
  'contact.success.message': 'Twój email został wysłany!',
  'contact.success.toast': 'Dziękujemy za opinię!',
  'contact.error.toast': 'Nie udało się wysłać opinii. Spróbuj ponownie.',
  'contact.discord.message': 'Lub wyślij nam email z pytaniami lub w sprawie pomocy.',

  'pricing.plans.monthly': 'Miesięcznie',
  'pricing.plans.yearly': 'Rocznie',
  'pricing.plans.lifetime': 'Dożywotnio',
  'pricing.plans.freeTrial': 'Okres próbny',
  'pricing.plans.billedYearly': 'płatne rocznie',
  'pricing.plans.mostPopular': 'najpopularniejszy',
  'pricing.plans.bestValue': 'najlepsza wartość',

  'pricing.button.subscribe': 'Subskrybuj',
  'pricing.button.manageSubscription': 'Zarządzaj subskrypcją',
  'pricing.button.startPracticing': 'Zacznij ćwiczyć',

  'pricing.toast.alreadyUsedTrial': 'Wykorzystałeś już swój okres próbny.',
  'pricing.toast.changePlanInfo': 'Aby zmienić obecny plan, kliknij "Zarządzaj subskrypcją".',
  'pricing.toast.portalError':
    'Nie można uzyskać dostępu do sekcji płatności w tej chwili. Spróbuj ponownie później lub skontaktuj się z pomocą techniczną, jeśli problem będzie się powtarzał.',

  'pricing.plans.current': '(Aktualny)',
  'pricing.plans.expired': '(Wygasł)',
  'pricing.plans.freeTrialDays': `Darmowy przez ${NUMBER_OF_DAYS_IN_FREE_TRIAL} dni`,
  'pricing.plans.perMonth': '€{amount}/miesiąc',
  'pricing.plans.yearlyBilling': 'Płatne rocznie €{amount}',
  'pricing.plans.referralDiscount': '{discount}% zniżki z polecenia na zawsze!',
  'pricing.plans.referralDiscountMonth': '{discount}% zniżki z polecenia przez {duration} miesiąc!',
  'pricing.plans.referralDiscountMonths': '{discount}% zniżki z polecenia przez {duration} miesiące!',
  'pricing.plans.referralDiscountYear': '{discount}% zniżki z polecenia przez 1 rok!',
  'pricing.plans.referralDiscountYears': '{discount}% zniżki z polecenia przez {duration} lata!',
  'pricing.plans.pastReferralDiscount': '{discount}% zniżki dzięki Twojemu poprzedniemu poleceniu!',

  'pricing.button.loading': 'Ładowanie...',
  'pricing.button.currentPlan': 'Twój obecny plan',
  'pricing.button.usedTrial': 'Wykorzystałeś okres próbny',
  'pricing.button.startTrial': `ZACZNIJ OKRES PRÓBNY (${NUMBER_OF_DAYS_IN_FREE_TRIAL} DNI)`,
  'pricing.button.subscribeNow': 'SUBSKRYBUJ TERAZ',
  'pricing.button.goPractice': 'PRZEJDŹ DO ĆWICZEŃ',
  'pricing.button.start': 'ROZPOCZNIJ',

  'pricing.freeTrial.title': 'Jak działa Twój okres próbny',
  'pricing.freeTrial.timeline.today': 'Dzisiaj',
  'pricing.freeTrial.timeline.today.description': 'Wprowadź dane karty i uzyskaj natychmiastowy dostęp',
  'pricing.freeTrial.timeline.sevenDays': '7 dni',
  'pricing.freeTrial.timeline.sevenDays.description': 'Ostatni dzień na anulowanie przed pierwszym pobraniem opłaty',
  'pricing.freeTrial.timeline.twentyOneDays': '21 dni',
  'pricing.freeTrial.timeline.twentyOneDays.description': `Nawet jeśli zostaniesz obciążony, możesz poprosić o bezwarunkowy zwrot pieniędzy. Po prostu daj nam znać, klikając przycisk "${CONTACT_US_TEXT}" powyżej.`,

  'signUpPrompt.pronunciation': 'Zarejestruj się, aby odsłuchać poprawną wymowę wygenerowaną z klonu Twojego głosu',
  'signUpPrompt.downloadAudio': 'Zarejestruj się, aby pobrać audio wygenerowane z klonu Twojego głosu',
  'signUpPrompt.savedWords': 'Zarejestruj się, aby przejść do "zapisanych słów"',
  'signUpPrompt.addToSavedWords': 'Zarejestruj się, aby dodać słowo do "zapisanych słów"',
  'signUpPrompt.tryAnotherExercise': 'Zarejestruj się, aby spróbować innego ćwiczenia',
  'signUpPrompt.tryAnotherText': 'Zarejestruj się, aby spróbować innego tekstu',

  'exercise.length.short': 'Krótkie',
  'exercise.length.medium': 'Średnie',
  'exercise.length.long': 'Długie',

  'topics.greetings': 'Powitania',
  'topics.intro': 'Przedstawianie się',
  'topics.numbers': 'Liczby',
  'topics.family': 'Rodzina',
  'topics.food': 'Jedzenie',
  'topics.directions': 'Wskazówki',
  'topics.shopping': 'Zakupy',
  'topics.travel': 'Podróże',
  'topics.medicine': 'Medycyna',
  'topics.weather': 'Pogoda',
  'topics.business': 'Biznes',
  'topics.letters': 'Listy',
  'topics.chatting': 'Rozmowy',
  'topics.slang': 'Slang',
  'topics.news': 'Wiadomości',
  'topics.academia': 'Akademia',
  'topics.tech': 'Technologia',
  'topics.art': 'Sztuka',
  'topics.history': 'Historia',
  'topics.socializing': 'Kontakty towarzyskie',
  'topics.music': 'Muzyka',
  'topics.emotions': 'Emocje',
  'topics.nature': 'Natura',
  'topics.career': 'Kariera',
  'topics.interview': 'Rozmowa kwalifikacyjna',
  'topics.cooking': 'Gotowanie',
  'topics.gaming': 'Gry',
  'topics.sports': 'Sport',
  'topics.finance': 'Finanse',
  'topics.cinema': 'Kino',
  'topics.dancing': 'Taniec',
  'topics.socialMedia': 'Media społecznościowe',
  'topics.gardening': 'Ogrodnictwo',
  'topics.photography': 'Fotografia',
  'topics.fashion': 'Moda',

  'exercise.pronunciation.your': 'Twoja wymowa',
  'exercise.pronunciation.yourBetter': 'Twoja lepsza wymowa',
}
