import {
  NUMBER_OF_DAYS_IN_FREE_TRIAL,
  REFUND_PERIOD_IN_DAYS,
} from '@shared/frontend-and-landing-and-backend/constants/pricing-constants.ts'

const CONTACT_US_TEXT = 'Contact Us'

export const englishDictionary = {
  'onboarding.chooseMotherLanguage.title': "What's your native language?",
  'onboarding.chooseMotherLanguage.subtitle': '(You can always change it later)',
  'onboarding.chooseMotherLanguage.searchPlaceholder': 'Type your native language',
  'onboarding.chooseMotherLanguage.other': 'Other',
  'onboarding.chooseMotherLanguage.skip': 'Skip',
  'onboarding.chooseMotherLanguage.next': 'Next',
  'onboarding.chooseMotherLanguage.loading': 'Loading...',

  'onboarding.chooseStudyLanguage.title': 'Which language do you want to study?',
  'onboarding.chooseStudyLanguage.subtitle': '(You can always change it later)',
  'onboarding.chooseStudyLanguage.previous': 'Previous',
  'onboarding.chooseStudyLanguage.next': 'Next',
  'onboarding.chooseStudyLanguage.loading': 'Loading...',

  'onboarding.termsAndConditions.title': 'Get ready',
  'onboarding.termsAndConditions.subtitle': 'to clone your voice',
  'onboarding.termsAndConditions.removeVoiceInfo':
    "You can always remove your voice clone later. It's not used by anyone outside the app.",
  'onboarding.termsAndConditions.step1.title': 'Step 1',
  'onboarding.termsAndConditions.step1.description':
    "In the next step, you'll read a short text aloud. For best results, ensure you're in a quiet environment. You can make mistakes, they will not affect the voice cloning.",
  'onboarding.termsAndConditions.step2.title': 'Step 2',
  'onboarding.termsAndConditions.step2.description':
    'Your voice clone will be integrated into your learning experience, tailoring exercises just for you.',
  'onboarding.termsAndConditions.consent':
    'I consent to having my voice cloned for personalized learning experiences within YourBestAccent.com, and I agree to the',
  'onboarding.termsAndConditions.termsOfService': 'Terms of Service',
  'onboarding.termsAndConditions.and': 'and',
  'onboarding.termsAndConditions.privacyPolicy': 'Privacy Policy',
  'onboarding.termsAndConditions.previous': 'Previous',
  'onboarding.termsAndConditions.next': 'Go to Voice Cloning',
  'onboarding.termsAndConditions.marketingConsent.label': 'Receive updates and tips',
  'onboarding.termsAndConditions.marketingConsent.info':
    'Stay informed about new features, learning tips, and special offers to enhance your language learning journey. You can unsubscribe at any time.',

  'onboarding.chooseDialect.title': 'Choose the dialect you want to study:',
  'onboarding.chooseDialect.subtitle': '(You can always change it later)',
  'onboarding.chooseDialect.previous': 'Previous',
  'onboarding.chooseDialect.next': 'Next',
  'onboarding.chooseDialect.loading': 'Loading...',

  'onboarding.chooseTopics.title': 'Which topic do you want to focus on?',
  'onboarding.chooseTopics.subtitle': '(You can always change it later)',
  'onboarding.chooseTopics.previous': 'Previous',
  'onboarding.chooseTopics.next': 'Next',
  'onboarding.chooseTopics.loading': 'Loading...',
  'onboarding.chooseTopics.skip': 'Skip',

  'auth.signIn.title': 'Start mastering pronunciation',
  'auth.signIn.subtitle': 'your pronunciation',
  'auth.signIn.withEmail': 'Sign in with Email',
  'auth.signIn.withGoogle': 'Sign in with Google',
  'auth.signUp.withEmail': 'Sign up with Email',
  'auth.signUp.withGoogle': 'Sign up with Google',

  'auth.signInEmail.title': 'Sign in with Email',
  'auth.signUpEmail.title': 'Sign up with Email',
  'auth.email.placeholder': 'Email address',
  'auth.email.invalidError': 'Please enter a valid email address',
  'auth.email.sendingEmail': 'Sending an email',
  'auth.email.or': 'Or',
  'auth.email.noAccount': "don't have an account?",
  'auth.email.haveAccount': 'Have an account?',
  'auth.email.signUp': 'Sign up',
  'auth.email.signIn': 'Sign in',

  'auth.emailVerify.title': 'Verify Your Email',
  'auth.emailVerify.verifying': 'Verifying...',
  'auth.emailVerify.verify': 'Verify',
  'auth.emailVerify.error.title': 'Email link is invalid or has expired',
  'auth.emailVerify.error.returnToSignIn': 'Return to Sign In',

  'auth.verificationSent.title': 'Email Verification Sent',
  'auth.verificationSent.sentTo': "We've sent a verification email to:",
  'auth.verificationSent.checkInbox': 'Please check your inbox and click on the',
  'auth.verificationSent.linkToSignIn': 'link to sign in',
  'auth.verificationSent.linkToSignUp': 'link to complete your sign up',

  'languages.english': 'English',
  'languages.spanish': 'Spanish',
  'languages.german': 'German',
  'languages.french': 'French',
  'languages.italian': 'Italian',
  'languages.polish': 'Polish',
  'languages.czech': 'Czech',
  'languages.ukrainian': 'Ukrainian',
  'languages.russian': 'Russian',
  'languages.portuguese': 'Portuguese',
  'languages.vietnamese': 'Vietnamese',
  'languages.chinese': 'Chinese',
  'languages.hindi': 'Hindi',
  'languages.indonesian': 'Indonesian',
  'languages.malay': 'Malay',
  'languages.japanese': 'Japanese',
  'languages.korean': 'Korean',
  'languages.tamil': 'Tamil',
  'languages.turkish': 'Turkish',
  'languages.romanian': 'Romanian',
  'languages.swedish': 'Swedish',
  'languages.norwegian': 'Norwegian',
  'languages.danish': 'Danish',
  'languages.slovak': 'Slovak',
  'languages.dutch': 'Dutch',
  'languages.thai': 'Thai',
  'languages.hungarian': 'Hungarian',
  'languages.greek': 'Greek',
  'languages.finnish': 'Finnish',
  'languages.bulgarian': 'Bulgarian',
  'languages.catalan': 'Catalan',

  'dialects.americanEnglish': 'American English',
  'dialects.britishEnglish': 'British English',
  'dialects.australianEnglish': 'Australian English',
  'dialects.scottishEnglish': 'Scottish English',
  'dialects.castilianSpanish': 'Castilian Spanish',
  'dialects.mexicanSpanish': 'Mexican Spanish',
  'dialects.colombianSpanish': 'Colombian Spanish',
  'dialects.argentinianSpanish': 'Argentinian Spanish',
  'dialects.peruvianSpanish': 'Peruvian Spanish',
  'dialects.parisianFrench': 'Parisian French',
  'dialects.belgianFrench': 'Belgian French',
  'dialects.canadianFrench': 'Canadian French',
  'dialects.standardGerman': 'Standard German',
  'dialects.standardItalian': 'Standard Italian',
  'dialects.standardPolish': 'Standard Polish',
  'dialects.brazilianPortuguese': 'Brazilian Portuguese',
  'dialects.europeanPortuguese': 'European Portuguese',
  'dialects.standardRussian': 'Standard Russian',
  'dialects.standardUkrainian': 'Standard Ukrainian',
  'dialects.standardCzech': 'Standard Czech',
  'dialects.standardDanish': 'Standard Danish',
  'dialects.standardDutch': 'Standard Dutch',
  'dialects.standardFinnish': 'Standard Finnish',
  'dialects.standardIndonesian': 'Standard Indonesian',
  'dialects.standardMalay': 'Standard Malay',
  'dialects.standardRomanian': 'Standard Romanian',
  'dialects.standardSlovak': 'Standard Slovak',
  'dialects.standardSwedish': 'Standard Swedish',
  'dialects.standardTurkish': 'Standard Turkish',
  'dialects.standardHungarian': 'Standard Hungarian',
  'dialects.standardNorwegian': 'Standard Norwegian',

  'dashboard.exercises.title': 'Exercises',
  'dashboard.exercises.subtitle': 'Continue where you left off or explore new exercises.',
  'dashboard.streak.title': 'Your Learning Streak',
  'dashboard.streak.subtitle': 'Explore your progress',
  'dashboard.exercises.beginner.title': 'Beginner {language}',
  'dashboard.exercises.beginner.description': 'Embark on your journey with common words and expressions',
  'dashboard.exercises.levelBased.title': 'Level-based',
  'dashboard.exercises.levelBased.description': 'Improve your pronunciation with words tailored to your language level',
  'dashboard.exercises.random.title': 'Random sentences',
  'dashboard.exercises.random.description': 'Practice with randomly selected sentences',
  'dashboard.exercises.custom.title': 'Custom text',
  'dashboard.exercises.custom.description': 'Use your own text for targeted practice',
  'dashboard.savedWords.button': 'Go to saved words',
  'dashboard.feedback.button': 'Share your feedback',
  'dashboard.mobile.exercises.title': 'Our Exercises',
  'dashboard.mobile.streak.title': 'Your Learning Streak',
  'dashboard.streak.popup.info':
    "A day counts towards your streak if you learn at least one word. A word is considered learned when it's marked in green for the first time in an exercise evaluation.",
  'dashboard.streak.current.title': '{count}-day streak',
  'dashboard.streak.current.subtitle': '{count} days left to achieve {target}-day streak badge!',
  'dashboard.streak.longest.title': '{count}-day streak',
  'dashboard.streak.longest.subtitle': 'Your Longest Streak',

  'exercise.record.instructions':
    "Click the record button and read the above text aloud. When you're done, click stop and we'll analyze your pronunciation.",
  'exercise.button.tryAgain': 'Try again',
  'exercise.button.anotherExercise': 'Try another exercise',
  'exercise.button.nextSentence': 'Next sentence',
  'exercise.button.anotherText': 'Try another text',
  'exercise.button.differentText': 'Try a different text',

  'exercise.controls.translate': 'Translate',
  'exercise.controls.share': 'Share',
  'exercise.controls.settings': 'Settings',
  'exercise.controls.searchLanguage': 'Search language...',
  'exercise.controls.noLanguageFound': 'No language found.',

  'exercise.custom.startPracticing': 'Start practicing',
  'exercise.custom.title': 'Enter text to practice',
  'exercise.custom.languageSelect.help':
    "We will try to detect the language of your text automatically but it's better if you select it manually.",
  'exercise.custom.languageSelect.switchTo': 'switch to:',
  'exercise.custom.textInput.placeholder':
    'Type your text to practice here. Example: "Betty Botter bought some butter but she said the butter\'s bitter."',
  'exercise.custom.error.tooShort': 'Text to practice must be at least 2 characters long',
  'exercise.custom.error.tooLong': 'Text to practice must be shorter than 750 characters',
  'exercise.custom.success.languageUpdate': 'Study language and dialect updated successfully',
  'exercise.custom.error.languageUpdate': 'Failed to update study language',

  'exercise.skeleton.loading': 'Getting your exercise ready...',

  'exercise.cefr.title': 'CEFR Level',
  'exercise.cefr.info':
    'The Common European Framework of Reference (CEFR) defines language proficiency across six levels: A1 and A2 (Basic User), B1 and B2 (Independent User), C1 and C2 (Proficient User). Each level represents increasing language ability, from beginner to near-native fluency. These standardized levels help learners, teachers, and employers understand and compare language skills internationally.',
  'exercise.cefr.position': 'Position:',
  'exercise.cefr.beginner': 'Beginner',
  'exercise.cefr.advanced': 'Advanced',
  'exercise.cefr.frequency.info':
    'We will generate sentences with words at around this position in the frequency list. A frequency list shows which words are used most often in a language. The lower the position of the word in a frequency list the more often it appears in the language. Words at a higher position are less frequent which very often means they are more difficult to learn.',

  'exercise.settings.length.title': 'Exercise Length',
  'exercise.settings.length.wordLength': 'word length:',
  'exercise.settings.length.info': 'The length of the actual exercise might differ slightly from length you requested.',

  'exercise.settings.ipaAndTransliteration': 'IPA and transliteration',
  'exercise.settings.ipaInfo':
    'International phonetic alphabet (IPA) and transliteration will appear above the study words',

  'exercise.settings.ipa.title': 'IPA',
  'exercise.settings.ipa.info':
    'IPA (International Phonetic Alphabet) transcription is an experimental feature and might contain errors.',

  'translate.modal.originalText': 'Original Text',
  'translate.modal.translation': 'Translation',

  'exercise.settings.title': 'Exercise Settings',
  'exercise.settings.level': 'Level:',

  'navbar.contactUs': CONTACT_US_TEXT,

  'settings.title': 'Settings',
  'settings.description': 'Make changes to your settings here',
  'settings.studyLanguage.title': 'I am learning:',
  'settings.studyLanguage.description': 'This is the language that will be used in the exercises.',
  'settings.motherLanguage.title': 'My native language:',
  'settings.dialect.title': 'My preferred accent:',
  'settings.dialect.description': 'Note: some accents are in beta. The speed of the audio might vary.',
  'settings.searchLanguage': 'Search language...',
  'settings.noLanguageFound': 'No language found.',
  'settings.success.motherLanguage': 'Mother language updated successfully',
  'settings.error.motherLanguage': 'Failed to update mother language',
  'settings.success.studyLanguage': 'Study language and dialect updated successfully',
  'settings.error.studyLanguage': 'Failed to update study language',
  'settings.success.dialect': 'Dialect updated successfully',
  'settings.error.dialect': 'Failed to update accent',

  'exercise.settings.transliteration.title': 'Transliteration',
  'exercise.settings.transliteration.info': 'We provide transliteration for languages that use non-Latin scripts.',

  'account.modal.title': 'Account',
  'account.modal.description': 'Your account information and settings.',
  'account.modal.fullName': 'Full Name',
  'account.modal.email': 'Email',
  'account.modal.publicNickname': 'Public nickname',
  'account.modal.noNickname': 'No nickname set',
  'account.modal.billing': 'Billing',
  'account.modal.dangerZone': 'Danger Zone',
  'account.modal.marketingEmails.title': 'Email notifications',
  'account.modal.marketingEmails.info': 'Product updates',
  'account.modal.marketingEmails.popoverInfo':
    'Stay informed about new features, learning tips, and special offers to enhance your language learning journey. You can unsubscribe at any time.',
  'account.modal.marketingEmails.updateSuccess': 'Email preferences updated successfully',
  'account.modal.marketingEmails.updateError': 'Failed to update email preferences',

  'nickname.title': 'Choose your nickname',
  'nickname.description': 'This nickname will be public. You can always change it later.',
  'nickname.input.placeholder': 'Type your nickname',
  'nickname.button.saving': 'Saving...',
  'nickname.button.join': 'Join Leaderboard',

  'leaderboard.title': 'Leaderboard',
  'leaderboard.description': 'See how you rank against other learners',
  'leaderboard.empty': 'No entries yet. Be the first one to make it to the leaderboard!',
  'leaderboard.position.title': 'Your Position',
  'leaderboard.position.notOnBoard':
    "You're not on the leaderboard yet. Practice some exercises to start competing with others!",
  'leaderboard.position.of': 'of',
  'leaderboard.words': 'words',
  'leaderboard.anonymous': 'Anonymous User',

  'filter.allLanguages': 'All languages',

  'navbar.dropdown.account': 'Account',
  'navbar.dropdown.settings': 'Settings',
  'navbar.dropdown.exercises': 'Exercises',
  'navbar.dropdown.leaderboard': 'Leaderboard',
  'navbar.dropdown.progress': 'Progress',
  'navbar.dropdown.pricing': 'Pricing',
  'navbar.dropdown.contact': 'Contact',
  'navbar.dropdown.about': 'About',
  'navbar.dropdown.signOut': 'Sign out',

  'pricing.premium.title': 'Choose your plan',
  'pricing.features.title': 'Features',
  'pricing.button.practice': 'Go Practice Now',

  'pricing.faq.title': 'Frequently Asked Questions',
  'pricing.faq.trial.question': 'How do I get a free trial?',
  'pricing.faq.trial.answer.withCard': `You can get a ${NUMBER_OF_DAYS_IN_FREE_TRIAL}-day free trial by signing up and entering your credit card details. Don't worry, you won't be charged during the trial period, and you can cancel anytime before it ends. Even if you forget and get charged, we do have a ${REFUND_PERIOD_IN_DAYS}-day refund guarantee.`,
  'pricing.faq.trial.answer.noCard': `You can get a free trial by signing up to our Free Trial plan. No credit card is required, and the trial automatically cancels after ${NUMBER_OF_DAYS_IN_FREE_TRIAL} days.`,
  'pricing.faq.security.question': 'Is the payment service secure?',
  'pricing.faq.security.answer':
    'Yes, our payment service is secure. We use Stripe for financial processing, and we do not store any information about your card. Stripe ensures bank-level security standards.',
  'pricing.faq.upgrade.question': 'Can I upgrade or downgrade my subscription?',
  'pricing.faq.upgrade.answer':
    "Yes, you can upgrade or downgrade your subscription at any time. Go to the top right of the app when you are logged in, click on your profile image, open the Account section in the menu and click Manage Subscription, you will be redirected to Stripe's billing portal where you can switch plans.",
  'pricing.faq.cancel.question': 'Can I cancel my subscription anytime?',
  'pricing.faq.cancel.answer':
    "You can cancel your subscription at any time. Go to the Account section when you are logged in and click on Manage Subscription, you will be redirected to Stripe's billing portal where you can download invoices, switch plans and cancel your subscription.",
  'pricing.faq.refund.question': 'Can I get a refund?',
  'pricing.faq.refund.answer': `Yes, you can get a refund within ${REFUND_PERIOD_IN_DAYS} after you've been charged. We do not want to charge customers who are not happy about the product. Please read more about our refund policy here:`,
  'pricing.faq.refund.policy': 'Refund Policy',

  'dangerZone.title': 'Danger Zone',
  'dangerZone.warning.title': 'Be careful',
  'dangerZone.warning.description': 'These actions cannot be undone. Please proceed with caution.',

  'progress.title': 'Progress',
  'progress.tabs.streak': 'Streak',
  'progress.tabs.badges': 'Badges',
  'progress.tabs.stats': 'Stats',

  'stats.title': 'Stats',
  'stats.tabs.learnedWords': 'Learned Words',
  'stats.tabs.savedWords': 'Saved Words',

  'streak.title': 'Your Learning Streak',
  'streak.info':
    "A day counts towards your streak if you learn at least one word. A word is considered learned when it's marked in green for the first time in an exercise evaluation.",
  'streak.current': '{count} Day Streak!',
  'streak.nextBadge': '{count} days left to achieve {target}-day streak badge!',
  'streak.highestBadge': "You've reached the highest badge! Keep it up!",
  'streak.longest.title': '{count} days',
  'streak.longest.subtitle': 'Longest Streak',
  'streak.longest.info': "This is the longest streak you've achieved. Keep it up!",
  'streak.progress': 'Progress',
  'streak.progress.days': 'days',
  'streak.badges': 'Badges Earned: {count}',

  'stats.learnedWords.empty': 'No words learned for {language} yet.',
  'stats.learnedWords.noWords': "You don't have any words learned yet",
  'stats.learnedWords.goToExercises': 'Go to exercises to learn new words',
  'stats.learnedWords.button': 'GO TO EXERCISES',
  'stats.learnedWords.table.word': 'Word',
  'stats.learnedWords.table.language': 'Language',
  'stats.learnedWords.table.learnedOn': 'Learned on',
  'stats.learnedWords.chart.title': 'Words learned',
  'stats.learnedWords.chart.subtitle': 'grouped by language',

  'contact.title': 'Contact Us',
  'contact.help.title': 'Help us improve YourBestAccent',
  'contact.help.description':
    'Share your feedback or suggest improvements to the founders. We read and reply to every submission!',
  'contact.form.message.label': 'Message to the founders',
  'contact.form.message.placeholder': 'Tell us what you think or describe any issues you encountered...',
  'contact.form.email.label': 'Email',
  'contact.form.email.placeholder': 'your@email.com',
  'contact.form.name.label': 'Name (optional)',
  'contact.form.name.placeholder': 'Your name',
  'contact.button.cancel': 'Cancel',
  'contact.button.send': 'Send Message',
  'contact.button.sending': 'Sending...',
  'contact.button.error': 'An error occurred. Please try again.',
  'contact.button.close': 'Close',
  'contact.success.message': 'Your email has been sent!',
  'contact.success.toast': 'Thank you for your feedback!',
  'contact.error.toast': 'Failed to send feedback. Please try again.',
  'contact.discord.message': 'Or send us an email for questions or help.',

  'pricing.plans.monthly': 'Monthly',
  'pricing.plans.yearly': 'Yearly',
  'pricing.plans.lifetime': 'Lifetime',
  'pricing.plans.freeTrial': 'Free Trial',
  'pricing.plans.billedYearly': 'billed yearly',
  'pricing.plans.mostPopular': 'Most Popular',
  'pricing.plans.bestValue': 'best value',

  'pricing.button.subscribe': 'Subscribe',
  'pricing.button.manageSubscription': 'Manage Subscription',
  'pricing.button.startPracticing': 'Start Practicing',

  'pricing.toast.alreadyUsedTrial': 'You have already used your free trial.',
  'pricing.toast.changePlanInfo': 'To change your current plan type, please click on "Manage Subscription".',
  'pricing.toast.portalError':
    'Unable to access your billing section at the moment. Please try again later or contact support if the issue persists.',

  'pricing.plans.current': '(Current)',
  'pricing.plans.expired': '(Expired)',
  'pricing.plans.freeTrialDays': `Free for ${NUMBER_OF_DAYS_IN_FREE_TRIAL} days`,
  'pricing.plans.perMonth': '€{amount}/month',
  'pricing.plans.yearlyBilling': 'Billed yearly at €{amount}',
  'pricing.plans.referralDiscount': '{discount}% off with your referral forever!',
  'pricing.plans.referralDiscountMonth': '{discount}% off with your referral for 1 month!',
  'pricing.plans.referralDiscountMonths': '{discount}% off with your referral for {duration} months!',
  'pricing.plans.referralDiscountYear': '{discount}% off with your referral for 1 year!',
  'pricing.plans.referralDiscountYears': '{discount}% off with your referral for {duration} years!',
  'pricing.plans.pastReferralDiscount': '{discount}% off thanks to your past referral!',

  'pricing.button.loading': 'Loading...',
  'pricing.button.currentPlan': 'Your current plan',
  'pricing.button.usedTrial': 'You used your free trial',
  'pricing.button.startTrial': `START ${NUMBER_OF_DAYS_IN_FREE_TRIAL}-DAY FREE TRIAL`,
  'pricing.button.subscribeNow': 'SUBSCRIBE NOW',
  'pricing.button.goPractice': 'GO PRACTICE NOW',
  'pricing.button.start': 'START',

  'pricing.freeTrial.title': 'How your free trial works',
  'pricing.freeTrial.timeline.today': 'Today',
  'pricing.freeTrial.timeline.today.description': 'Introduce your card details and get instant access',
  'pricing.freeTrial.timeline.sevenDays': '7 days',
  'pricing.freeTrial.timeline.sevenDays.description': 'Last day to cancel before first charge',
  'pricing.freeTrial.timeline.twentyOneDays': '21 days',
  'pricing.freeTrial.timeline.twentyOneDays.description': `Even if you get charged you can request an unconditional refund. Just let us know by clicking the "${CONTACT_US_TEXT}" button above.`,

  'signUpPrompt.pronunciation': 'Sign up to listen to the correct pronunciation generated with your voice clone',
  'signUpPrompt.downloadAudio': 'Sign up to download audio generated with your voice clone',
  'signUpPrompt.savedWords': 'Sign up to go to your "saved words"',
  'signUpPrompt.addToSavedWords': 'Sign up to add the word to your "saved words"',
  'signUpPrompt.tryAnotherExercise': 'Sign up to try another exercise',
  'signUpPrompt.tryAnotherText': 'Sign up to try another text',

  'exercise.length.short': 'Short',
  'exercise.length.medium': 'Medium',
  'exercise.length.long': 'Long',

  'topics.greetings': 'Greetings',
  'topics.intro': 'Introductions',
  'topics.numbers': 'Numbers',
  'topics.family': 'Family',
  'topics.food': 'Food',
  'topics.directions': 'Directions',
  'topics.shopping': 'Shopping',
  'topics.travel': 'Travel',
  'topics.medicine': 'Medicine',
  'topics.weather': 'Weather',
  'topics.business': 'Business',
  'topics.letters': 'Letters',
  'topics.chatting': 'Chatting',
  'topics.slang': 'Slang',
  'topics.news': 'News',
  'topics.academia': 'Academia',
  'topics.tech': 'Tech',
  'topics.art': 'Art',
  'topics.history': 'History',
  'topics.socializing': 'Socializing',
  'topics.music': 'Music',
  'topics.emotions': 'Emotions',
  'topics.nature': 'Nature',
  'topics.career': 'Career',
  'topics.interview': 'Interview',
  'topics.cooking': 'Cooking',
  'topics.gaming': 'Gaming',
  'topics.sports': 'Sports',
  'topics.finance': 'Finance',
  'topics.cinema': 'Cinema',
  'topics.dancing': 'Dancing',
  'topics.socialMedia': 'Social Media',
  'topics.gardening': 'Gardening',
  'topics.photography': 'Photography',
  'topics.fashion': 'Fashion',

  'exercise.pronunciation.your': 'Your pronunciation',
  'exercise.pronunciation.yourBetter': 'Your better pronunciation',
}
