import React from 'react'
import { FAQItem } from '../FAQItem'
import { EXTERNAL_LINKS } from '../../constants/external-links'
import { useSelector } from 'react-redux'
import { selectHasReferral } from '../../state/slices/account-slice.ts'
import { getConfig } from '../../config/environment-config.ts'
import { t } from '../../i18n/translate'

export const PricingFaqItems: React.FC = () => {
  const hasReferral = useSelector(selectHasReferral)

  const pricingFaqItems = [
    {
      question: t('pricing.faq.trial.question'),
      answer:
        getConfig().featureFlags.isFreeTrialEnabled() && !hasReferral
          ? t('pricing.faq.trial.answer.noCard')
          : t('pricing.faq.trial.answer.withCard'),
    },
    {
      question: t('pricing.faq.refund.question'),
      answer: (
        <>
          {t('pricing.faq.refund.answer')}{' '}
          <a
            href={EXTERNAL_LINKS.REFUND_POLICY}
            target='_blank'
            rel='noopener noreferrer'
            className='text-indigo-600 underline hover:text-indigo-800'
          >
            {t('pricing.faq.refund.policy')}
          </a>
        </>
      ),
    },
    {
      question: t('pricing.faq.security.question'),
      answer: t('pricing.faq.security.answer'),
    },
    {
      question: t('pricing.faq.upgrade.question'),
      answer: t('pricing.faq.upgrade.answer'),
    },
    {
      question: t('pricing.faq.cancel.question'),
      answer: t('pricing.faq.cancel.answer'),
    },
  ]

  return (
    <>
      {pricingFaqItems.map((item, index) => (
        <FAQItem
          key={index}
          question={item.question}
          answer={item.answer}
          isLast={index === pricingFaqItems.length - 1}
        />
      ))}
    </>
  )
}
