import { useIsFetching, useQuery } from '@tanstack/react-query'
import { WordPair, WordPairWithIpaAndTransliteration } from '../../exercise/evaluation/utils/types.ts'
import { useDispatch, useSelector } from 'react-redux'
import {
  DEFAULT_DIALECTS,
  DialectCode,
  LangCode,
  LANGUAGES_WITH_TRANSLITERATION,
} from '@shared/frontend-and-landing-and-backend/constants/lang-codes.ts'
import { useRef, useState } from 'react'
import { ResponseWrapper } from '@shared/frontend-and-backend/body-types/response-wrapper.types.ts'
import { TranslateWordResponse } from '@shared/frontend-and-backend/body-types/translation/translation.types.ts'
import { Popover, PopoverContent, PopoverTrigger } from '../../../shadcn/popover.tsx'
import { EvaluationForOpenExerciseProps } from '../../exercise/evaluation/types.ts'
import {
  addIpaAndTransliterationToPairsThatHaveExpectedWords,
  getEvaluationScoreInPercentage,
} from '../../exercise/evaluation/utils/evaluation-utils.ts'
import { QUERY_KEYS } from '../../../../transport/transport/our-backend/api/query-keys.ts'
import { selectShouldShowIpa, selectShouldShowTransliteration } from '../../../../state/slices/preferences-slice.ts'
import { GetIpaResponse } from '@shared/frontend-and-backend/body-types/pronunciation/transcribe-ipa.types.ts'
import {
  CopyableIpaWord,
  CopyableTranslation,
  CopyableTransliteratedWord,
  NarrowSkeleton,
} from '../../exercise/exercise-atoms.tsx'
import { TransliterationResponse } from '@shared/frontend-and-backend/body-types/transliteration/transliteration.types.ts'
import { splitByEmptySpacesAndMergeLonelyPunctuation } from '@shared/frontend-and-backend/utils/text-utils.ts'
import { useApiErrorHandler } from '../../../../hooks/use-api-error-handler.ts'
import { Download } from 'lucide-react'
import { Score } from '../../exercise/evaluation/score/score.tsx'
import { Separator } from '../../../design-system/separator.tsx'
import { ActualWord } from '../../exercise/evaluation/atoms/actual-word.tsx'
import { EmptySlotForExpectedWord } from '../../exercise/evaluation/atoms/empty-slot-for-expected-word.tsx'
import { EmptySlotForActualWord } from '../../exercise/evaluation/atoms/empty-slot-for-actual-word.tsx'
import { ExpectedWordForOpenExercise } from '../../exercise/evaluation/molecules/expected-word-for-open-exercise.tsx'
import { PlayWordForOpenExercise } from '../../exercise/evaluation/atoms/play-word-for-open-exercise.tsx'
import { POSTHOG_EVENTS } from '../../../../analytics/posthog/posthog-events.ts'
import { AddOrDeleteFromSavedWordsSectionForOpenExercise } from '../../exercise/evaluation/atoms/add-or-delete-from-saved-words-section-for-open-exercise.tsx'
import { modalActions } from '../../../../state/slices/modal-slice.ts'
import { getBrowserLanguageAsLangCodeOrEnglish } from '../../../../utils/language-detection-utils.ts'
import { getWordTranslationViaOpenApi } from '../../../../transport/transport/our-backend/open-api/translation/translation.ts'
import { getGeneratedIpaTranscriptionViaOpenApi } from '../../../../transport/transport/our-backend/open-api/pronunciation/pronunciation.ts'
import { getTransliterationViaOpenApi } from '../../../../transport/transport/our-backend/open-api/transliteration/translation.ts'
import { PlayActualWord } from '../../exercise/evaluation/atoms/play-actual-word.tsx'
import { MediaPlayerInstance } from '@vidstack/react'
import { t } from '../../../../i18n/translate.ts'

export const EvaluationForOpenExercise = ({
  wordPairs,
  text,
  studyLanguage,
  recordedAudioBlob,
}: EvaluationForOpenExerciseProps) => {
  const dispatch = useDispatch()
  const motherLanguage: LangCode = getBrowserLanguageAsLangCodeOrEnglish()
  const dialect: DialectCode = DEFAULT_DIALECTS[studyLanguage]

  const [textToTranslate, setTextToTranslate] = useState<string | null>(null)
  const shouldShowIpa = useSelector(selectShouldShowIpa)
  const shouldShowTransliteration =
    useSelector(selectShouldShowTransliteration) && LANGUAGES_WITH_TRANSLITERATION.includes(studyLanguage)

  const actualWordPlayerRef = useRef<MediaPlayerInstance>(null)
  const scoreInPercentage: number = getEvaluationScoreInPercentage(wordPairs)
  const expectedWordsPairs: WordPair[] = wordPairs.filter((pair) => pair.expectedWord !== null)
  const contextWords: string[] = expectedWordsPairs.map((pair) => pair.expectedWord as string)
  const [selectedWordIndex, setSelectedWordIndex] = useState<number>(0)

  const { data: translationData, error: translationError } = useQuery<ResponseWrapper<TranslateWordResponse>>({
    queryKey: [QUERY_KEYS.TRANSLATE_WORD, textToTranslate, motherLanguage, dialect, contextWords, selectedWordIndex],
    queryFn: () =>
      getWordTranslationViaOpenApi(textToTranslate!, dialect, motherLanguage, contextWords, selectedWordIndex),
    enabled: !!textToTranslate,
    staleTime: Infinity,
  })

  const {
    data: ipaTranscriptionData,
    isFetching: isFetchingIpa,
    error: ipaTranscriptionError,
  } = useQuery<ResponseWrapper<GetIpaResponse>>({
    queryKey: [QUERY_KEYS.IPA_TRANSCRIPTION_WITH_OPEN_API, text, studyLanguage, dialect],
    queryFn: () => getGeneratedIpaTranscriptionViaOpenApi(text, studyLanguage, dialect),
    enabled: !!text,
    staleTime: Infinity,
  })

  const {
    data: transliterationData,
    isFetching: isFetchingTransliteration,
    error: transliterationError,
  } = useQuery<ResponseWrapper<TransliterationResponse>>({
    queryKey: [QUERY_KEYS.TRANSLITERATION, text, motherLanguage, studyLanguage, dialect],
    queryFn: () => getTransliterationViaOpenApi(text, studyLanguage),
    enabled: !!text && LANGUAGES_WITH_TRANSLITERATION.includes(studyLanguage),
    staleTime: Infinity,
  })

  useApiErrorHandler(
    translationError,
    `Translation error in evaluation component: ${studyLanguage}, ${motherLanguage}, ${textToTranslate}, ${translationError}`,
    'There was a translation error. Please try again.'
  )
  useApiErrorHandler(
    ipaTranscriptionError,
    `IPA transcription error in evaluation component: ${studyLanguage}, ${motherLanguage}, ${textToTranslate}, ${ipaTranscriptionError}`
  )
  useApiErrorHandler(
    transliterationError,
    `Transliteration error in evaluation component: ${studyLanguage}, ${motherLanguage}, ${textToTranslate}, ${transliterationError}`
  )

  const translation: string | undefined = translationData?.data?.translation

  const handleTap = (text: string | null, wordIndex: number) => {
    if (text) {
      setTextToTranslate(text)
      setSelectedWordIndex(wordIndex)
    }
  }

  const isFetchingAudioIndividualWord = useIsFetching({
    queryKey: [QUERY_KEYS.AUDIO_INDIVIDUAL_WORD],
  })

  const onDownload = () => {
    POSTHOG_EVENTS.click('download_pronunciation')
    dispatch(modalActions.openSignUpPromptModal(t('signUpPrompt.downloadAudio')))
  }

  const ipaTranscriptionWords: string[] = ipaTranscriptionData?.data?.ipaTranscription || []
  const transliterationWords: string[] | undefined = splitByEmptySpacesAndMergeLonelyPunctuation(
    transliterationData?.data?.transliteration || ''
  )

  const wordPairsWithIpaAndTransliteration: WordPairWithIpaAndTransliteration[] =
    addIpaAndTransliterationToPairsThatHaveExpectedWords(wordPairs, ipaTranscriptionWords, transliterationWords)
  return (
    <div className='flex w-full flex-col items-center gap-2 md:max-w-4xl md:gap-4 lg:max-w-6xl'>
      <Score scoreInPercentage={scoreInPercentage} />
      <div className='mb-4 flex flex-wrap justify-center gap-x-2 gap-y-2 px-1 md:max-w-3xl md:gap-x-3 md:gap-y-4'>
        {wordPairsWithIpaAndTransliteration.map((w: WordPairWithIpaAndTransliteration, index: number) => (
          <div key={index} className='flex flex-col gap-y-0'>
            {/*to understand this hell have a look at:*/}
            {/*https://www.notion.so/grammarians/Tons-of-divs-for-below-and-above-the-words-21efda77261a4161b2018f6470ff7803*/}
            {LANGUAGES_WITH_TRANSLITERATION.includes(studyLanguage) && !shouldShowTransliteration && (
              <div className='h-6' />
            )}
            {!shouldShowIpa && <div className='h-6' />}
            {LANGUAGES_WITH_TRANSLITERATION.includes(studyLanguage) && (
              <>
                {shouldShowTransliteration && w.transliteration && (
                  <CopyableTransliteratedWord text={w.transliteration} />
                )}
                {shouldShowTransliteration && !w.transliteration && <div className='h-6' />}
                {shouldShowTransliteration && isFetchingTransliteration && <NarrowSkeleton />}
              </>
            )}
            {shouldShowIpa && w.ipa && <CopyableIpaWord text={w.ipa} />}
            {shouldShowIpa && !w.ipa && <div className='h-6' />}
            {shouldShowIpa && isFetchingIpa && <NarrowSkeleton />}
            {w.expectedWord ? (
              <Popover>
                <PopoverTrigger>
                  <ExpectedWordForOpenExercise
                    wordPair={w}
                    onClick={() =>
                      handleTap(
                        w.expectedWord,
                        expectedWordsPairs.findIndex((pair) => pair === w)
                      )
                    }
                  />
                </PopoverTrigger>

                <PopoverContent className='min-w-[19rem] max-w-[24rem] bg-white shadow-lg'>
                  <div className='flex flex-col items-start gap-2'>
                    <CopyableTranslation translation={translation} />
                    <Separator className='my-2' />
                    <div className='flex w-full items-center justify-between gap-2'>
                      <div className='flex items-center gap-2'>
                        <PlayActualWord
                          recordedAudioBlob={recordedAudioBlob}
                          startTimeInSeconds={w.actualStartTimeInSeconds}
                          endTimeInSeconds={w.actualEndTimeInSeconds}
                          actualWordPlayerRef={actualWordPlayerRef}
                        />
                        <span className='whitespace-nowrap text-sm'>Your pronunciation</span>
                      </div>
                    </div>
                    <div className='flex w-full items-center justify-between gap-2'>
                      <div className='flex items-center gap-2'>
                        <PlayWordForOpenExercise />
                        <span className='whitespace-nowrap text-sm'>Slow pronunciation</span>
                      </div>
                      <button
                        onClick={onDownload}
                        disabled={!!isFetchingAudioIndividualWord}
                        className='flex h-10 w-10 items-center justify-center rounded-full hover:bg-gray-200 active:bg-gray-300 active:text-stone-900 disabled:cursor-not-allowed disabled:opacity-50'
                      >
                        <Download className='h-6 w-6 text-stone-700 hover:text-stone-900 active:text-stone-900' />
                      </button>
                    </div>
                    <AddOrDeleteFromSavedWordsSectionForOpenExercise />
                  </div>
                </PopoverContent>
              </Popover>
            ) : (
              <EmptySlotForExpectedWord />
            )}
            {w.actualWord ? (
              <ActualWord pair={w} onClick={() => handleTap(w.actualWord, index)} />
            ) : (
              <EmptySlotForActualWord />
            )}
          </div>
        ))}
      </div>
    </div>
  )
}
