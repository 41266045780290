import { zodResolver } from '@hookform/resolvers/zod'
import {
  DEFAULT_DIALECTS,
  DialectCode,
  LangCode,
  LANGUAGES_TO_DIALECT_MAP,
  SUPPORTED_MOTHER_LANGUAGES,
  SUPPORTED_STUDY_LANGUAGES,
  SupportedMotherLanguage,
  SupportedStudyLanguage,
} from '@shared/frontend-and-landing-and-backend/constants/lang-codes'
import { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'sonner'
import { z } from 'zod'
import {
  accountActions,
  selectAccountAccessToken,
  selectDialectOrDefaultDialectOrEnglishDefaultDialect,
  selectMotherLanguageOrEnglish,
  selectStudyLanguageOrEnglish,
} from '../../state/slices/account-slice'
import { exerciseSliceActions } from '../../state/slices/exercise-slice.ts'
import DialectSelector from './dialect-selector'
import { LanguageComboBox } from './language-combo-box'
import { SettingsFormProps } from './types'
import {
  useFrequencySliderPosition,
  useUserSettings,
} from '../../transport/transport/our-backend/api/users/users-hooks.ts'
import { UserSettings } from '@shared/frontend-and-backend/body-types/user-settings/user-settings.types.ts'
import { cloneDeep } from 'lodash'
import { useUpdateFrequencyListPositionMutation } from '../../hooks/use-update-frequency-list-position-mutation.ts'
import {
  preferencesActions,
  selectShouldShowIpa,
  selectShouldShowTransliteration,
} from '../../state/slices/preferences-slice.ts'
import { FormControl, FormDescription, FormItem, FormLabel } from '../shadcn/form.tsx'
import { IpaToggle } from '../exercises/exercise/controls/atoms/ipa-toggle.tsx'
import { TransliterationToggle } from '../exercises/exercise/controls/atoms/transliteration-toggle.tsx'
import { CefrLevelSelector } from '../exercises/exercise/controls/atoms/cefr-level-selector/cefr-level-selector.tsx'
import { useMutation } from '@tanstack/react-query'
import {
  updateMotherLanguage,
  updateStudyDialect,
  updateStudyLanguageAndStudyDialect,
} from '../../transport/transport/our-backend/api/users/users.ts'
import { useApiErrorHandler } from '../../hooks/use-api-error-handler.ts'
import { MAX_NUMBER_OF_WORDS_IN_FREQUENCY_LIST } from '@shared/frontend-and-backend/constants/constants.ts'
import { t } from '../../i18n/translate.ts'

const FormSchema = z.object({
  studyLanguage: z.nativeEnum(LangCode, {
    required_error: 'Please select a language.',
  }),
  motherLanguage: z.nativeEnum(LangCode, {
    required_error: 'Please select a language.',
  }),
  frequencyListPosition: z.number().int().min(0).max(MAX_NUMBER_OF_WORDS_IN_FREQUENCY_LIST),
})

type FormInputs = z.infer<typeof FormSchema>

export const SettingsForm = ({ onSubmit }: SettingsFormProps) => {
  const accessToken: string = useSelector(selectAccountAccessToken)
  const studyLanguage: SupportedStudyLanguage = useSelector(selectStudyLanguageOrEnglish)
  const motherLanguage: SupportedMotherLanguage = useSelector(selectMotherLanguageOrEnglish)
  const selectedDialect: DialectCode =
    useSelector(selectDialectOrDefaultDialectOrEnglishDefaultDialect) || DEFAULT_DIALECTS[studyLanguage]
  const position = useFrequencySliderPosition(studyLanguage)
  const { data: userSettings } = useUserSettings()

  const { mutate } = useUpdateFrequencyListPositionMutation(accessToken, studyLanguage)
  const shouldShowIpa = useSelector(selectShouldShowIpa)
  const shouldShowTransliteration = useSelector(selectShouldShowTransliteration)
  const dispatch = useDispatch()

  const { mutate: updateMotherLang, error: motherLangError } = useMutation({
    mutationFn: (newMotherLang: LangCode) => updateMotherLanguage(accessToken, newMotherLang),
    onSuccess: () => {
      toast.success('Mother language updated successfully')
    },
    onError: () => {
      toast.error('Failed to update mother language')
    },
  })

  const { mutate: updateStudyLangAndDialect, error: studyLangError } = useMutation({
    mutationFn: ({ studyLang, dialect }: { studyLang: SupportedStudyLanguage; dialect: DialectCode }) =>
      updateStudyLanguageAndStudyDialect(accessToken, studyLang, dialect),
    onSuccess: () => {
      toast.success('Study language and dialect updated successfully')
    },
    onError: () => {
      toast.error('Failed to update study language')
    },
  })

  const { mutate: updateDialect, error: dialectError } = useMutation({
    mutationFn: (newDialect: DialectCode) => updateStudyDialect(accessToken, newDialect),
    onSuccess: () => {
      toast.success('Dialect updated successfully')
    },
    onError: () => {
      toast.error('Failed to update accent')
    },
  })

  useApiErrorHandler(motherLangError, 'SettingsForm: Error updating mother language')
  useApiErrorHandler(studyLangError, 'SettingsForm: Error updating study language and dialect')
  useApiErrorHandler(dialectError, 'SettingsForm: Error updating dialect')

  const handleIpaClick = () => {
    dispatch(preferencesActions.setShouldShowIpa(!shouldShowIpa))
  }

  const handleTransliterationClick = () => {
    dispatch(preferencesActions.setShouldShowTransliteration(!shouldShowTransliteration))
  }

  const form = useForm<FormInputs>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      studyLanguage: studyLanguage,
      motherLanguage: motherLanguage,
    },
  })

  const dialectsForSelectedLanguage = LANGUAGES_TO_DIALECT_MAP[studyLanguage]

  useEffect(() => {
    if (!dialectsForSelectedLanguage.includes(selectedDialect)) {
      dispatch(accountActions.setDialect(DEFAULT_DIALECTS[studyLanguage]))
    }
  }, [dispatch, studyLanguage, selectedDialect, dialectsForSelectedLanguage])

  const handleDialectSelect = (value: DialectCode) => {
    if (value) {
      updateDialect(value)
      dispatch(accountActions.setDialect(value))
    }
  }

  const handleMotherLanguageSelect = (value: LangCode) => {
    updateMotherLang(value)
    dispatch(accountActions.setMotherLanguage(value))
  }

  const handleStudyLanguageSelect = (value: LangCode) => {
    const newDialect = DEFAULT_DIALECTS[value as SupportedStudyLanguage]
    updateStudyLangAndDialect({
      studyLang: value as SupportedStudyLanguage,
      dialect: newDialect,
    })
    dispatch(exerciseSliceActions.setWordsToInclude({ wordsToInclude: [] }))
    dispatch(accountActions.setStudyLanguage(value as SupportedStudyLanguage))
    dispatch(accountActions.setDialect(newDialect))
  }

  const updateFrequencySliderPosition = (language: LangCode, position: number) => {
    if (!userSettings) {
      return
    }
    const updatedSettings: UserSettings = cloneDeep(userSettings)
    updatedSettings.preferences.exercises.frequencyList.position.byLanguage.map((lang) => {
      if (lang.language === language) {
        lang.position = position
      }
      return lang
    })
    mutate(updatedSettings)
  }

  const handlePositionChange = (newPosition: number) => {
    updateFrequencySliderPosition(studyLanguage, newPosition)
  }

  return (
    <FormProvider {...form}>
      <form className='space-y-6' onSubmit={form.handleSubmit(onSubmit)}>
        <LanguageComboBox
          name='studyLanguage'
          label={t('settings.studyLanguage.title')}
          description={t('settings.studyLanguage.description')}
          onLanguageSelect={handleStudyLanguageSelect}
          langCodes={SUPPORTED_STUDY_LANGUAGES}
        />

        {dialectsForSelectedLanguage.length > 1 && (
          <DialectSelector
            dialects={dialectsForSelectedLanguage}
            selectedDialect={selectedDialect}
            onDialectSelect={handleDialectSelect}
          />
        )}
        <LanguageComboBox
          name='motherLanguage'
          label={t('settings.motherLanguage.title')}
          onLanguageSelect={handleMotherLanguageSelect}
          langCodes={SUPPORTED_MOTHER_LANGUAGES}
        />
        <CefrLevelSelector initialPosition={position} onPositionCommit={handlePositionChange} />

        <FormItem className='space-y-2'>
          <div>
            <FormLabel className='text-base font-semibold'>IPA and transliteration</FormLabel>
          </div>
          <FormControl>
            <div className='space-y-3'>
              <IpaToggle shouldShowIpa={shouldShowIpa} handleIpaClick={handleIpaClick} />
              <TransliterationToggle
                shouldShowTransliteration={shouldShowTransliteration}
                handleTransliterationClick={handleTransliterationClick}
              />
            </div>
          </FormControl>
          <FormDescription className='mt-1 text-sm text-gray-400'>{t('exercise.settings.ipaInfo')}</FormDescription>
        </FormItem>
      </form>
    </FormProvider>
  )
}
