import { englishDictionary } from './dictionaries/english-dictionary'
import { polishDictionary } from './dictionaries/polish-dictionary'
import { ENGLISH_LOCALE, FRENCH_LOCALE, i18nConfig, Locale, POLISH_LOCALE, SPANISH_LOCALE } from './i18n-config'
import { spanishDictionary } from './dictionaries/spanish-dictionary.ts'
import { frenchDictionary } from './dictionaries/french-dictionary.ts'

const dictionaries = {
  [ENGLISH_LOCALE]: englishDictionary,
  [POLISH_LOCALE]: polishDictionary,
  [SPANISH_LOCALE]: spanishDictionary,
  [FRENCH_LOCALE]: frenchDictionary,
} as const

export type DictionaryKey = keyof typeof englishDictionary

export type Dictionary = typeof englishDictionary

const getBrowserLocale = (): Locale => {
  if (typeof navigator === 'undefined') {
    return ENGLISH_LOCALE
  }

  const browserLang = navigator.language.split('-')[0]

  if (browserLang === SPANISH_LOCALE) {
    return SPANISH_LOCALE
  }

  if (browserLang === POLISH_LOCALE) {
    return POLISH_LOCALE
  }

  if (browserLang === FRENCH_LOCALE) {
    return FRENCH_LOCALE
  }

  return ENGLISH_LOCALE
}

export const t = (dictionaryKey: DictionaryKey): string => {
  const locale: Locale = getBrowserLocale()
  const dictionary: Dictionary = dictionaries[locale]
  return dictionary[dictionaryKey] || dictionaries[i18nConfig.defaultLocale][dictionaryKey] || dictionaryKey
}
