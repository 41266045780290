import {
  DialectCode,
  LANGUAGES_TO_DIALECT_MAP,
  SupportedStudyLanguage,
} from '@shared/frontend-and-landing-and-backend/constants/lang-codes'
import { useEffect, useState } from 'react'
import {
  accountActions,
  selectAccountAccessToken,
  selectStudyLanguageOrEnglish,
} from '../../../../state/slices/account-slice.ts'
import { useDispatch, useSelector } from 'react-redux'
import { NavigationButton } from '../navigation-button.tsx'
import { POSTHOG_EVENTS } from '../../../../analytics/posthog/posthog-events.ts'
import { DialectCard } from './dialect-card.tsx'
import { ROUTE_PATHS } from '../../../../routing/route-paths.ts'
import { useNavigate } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'
import { updateStudyDialect } from '../../../../transport/transport/our-backend/api/users/users.ts'
import { useApiErrorHandler } from '../../../../hooks/use-api-error-handler.ts'
import { t } from '../../../../i18n/translate.ts'
import { ArrowLeft } from 'lucide-react'

export const ChooseDialectView = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const studyLanguageOrEnglish: SupportedStudyLanguage = useSelector(selectStudyLanguageOrEnglish)
  const accessToken = useSelector(selectAccountAccessToken)
  const [selectedDialect, setSelectedDialect] = useState<DialectCode | null>(null)

  const { mutate, isPending, error } = useMutation({
    mutationFn: (dialect: DialectCode) => updateStudyDialect(accessToken, dialect),
    onSuccess: () => {
      dispatch(accountActions.setDialect(selectedDialect))
      navigate(ROUTE_PATHS.ONBOARDING_CHOOSE_TOPICS)
    },
  })

  useApiErrorHandler(error, 'Error when updating study dialect')

  useEffect(() => {
    POSTHOG_EVENTS.viewPage()
  }, [])

  const onPreviousClick = () => {
    dispatch(accountActions.resetStudyLanguage())
    navigate(ROUTE_PATHS.ONBOARDING_CHOOSE_STUDY_LANGUAGE)
  }

  const onNextClick = () => {
    if (selectedDialect) {
      mutate(selectedDialect)
    }
  }

  const handleDialectClick = (dialect: DialectCode) => {
    if (selectedDialect === dialect) {
      setSelectedDialect(null)
    } else {
      setSelectedDialect(dialect)
    }
  }

  const title = t('onboarding.chooseDialect.title')

  return (
    <div className='flex w-full flex-col'>
      <div className='flex w-full items-center px-4 py-4'>
        <div className='mx-auto flex h-full w-full items-center md:w-1/2 3xl:w-1/3'>
          <button onClick={onPreviousClick} className='text-gray-500 hover:text-gray-700' disabled={isPending}>
            <ArrowLeft size={24} />
          </button>
        </div>
      </div>

      <div className='mb-52 flex justify-center'>
        <div className='flex w-full flex-col items-center gap-y-4 px-4 pt-0 md:w-1/2 3xl:w-1/3'>
          <div className='flex max-w-md flex-col items-center'>
            <h1 className='max-w-xl text-center text-4xl font-bold tracking-tighter'>{title}</h1>
            <span className='text-md max-w-xl text-center tracking-tighter text-gray-400'>
              {t('onboarding.chooseDialect.subtitle')}
            </span>
          </div>
          <div className='grid w-full grid-cols-1 gap-4 md:grid-cols-2'>
            {LANGUAGES_TO_DIALECT_MAP[studyLanguageOrEnglish].map((dialect) => (
              <DialectCard
                key={dialect}
                dialect={dialect}
                handleClick={handleDialectClick}
                isSelected={selectedDialect === dialect}
              />
            ))}
          </div>
        </div>
      </div>

      <div className='fixed bottom-0 w-full bg-gray-50'>
        <div className='pointer-events-none absolute bottom-full left-0 right-0 h-40 bg-gradient-to-b from-transparent to-gray-50' />
        <div className='mx-auto flex w-full px-4 pb-8 pt-4 md:w-1/2 3xl:w-1/3'>
          <NavigationButton
            onClick={onNextClick}
            disabled={!selectedDialect || isPending}
            className='w-full bg-gradient-to-r from-indigo-500 to-indigo-600 text-white'
          >
            {isPending ? t('onboarding.chooseDialect.loading') : t('onboarding.chooseDialect.next')}
          </NavigationButton>
        </div>
      </div>
    </div>
  )
}
