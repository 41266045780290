import { useMutation, useQueryClient } from '@tanstack/react-query'
import { UserSettings } from '@shared/frontend-and-backend/body-types/user-settings/user-settings.types.ts'
import { updateUserSettings } from '../transport/transport/our-backend/api/user-settings/user-settings.ts'
import { QUERY_KEYS } from '../transport/transport/our-backend/api/query-keys.ts'
import { toast } from 'sonner'
import { logWithRollbar } from '../analytics/rollbar/log-with-rollbar.ts'

export const useUpdateAudioSpeedMutation = (accessToken: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (newSettings: UserSettings) => updateUserSettings(accessToken, newSettings),
    onSuccess: (response) => {
      queryClient.setQueryData<UserSettings>([QUERY_KEYS.USER_SETTINGS], response.data)
      toast.info(`Audio speed updated`)
    },
    onError: (error) => {
      logWithRollbar(`Failed to update audio speed: + ${JSON.stringify(error)} ${error}`)
      toast.error('Failed to update audio speed')
    },
  })
}
