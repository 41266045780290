import { MuteButton, PlayButton, useMediaState } from '@vidstack/react'

import {
  Pause as PauseIcon,
  Play as PlayIcon,
  Volume as MuteIcon,
  Volume1 as VolumeLowIcon,
  Volume2 as VolumeHighIcon,
} from 'lucide-react'
import * as Sliders from './sliders.tsx'

import { Drawer, DrawerContent, DrawerHeader, DrawerTitle, DrawerTrigger } from '../../../../shadcn/drawer.tsx'

import { Popover, PopoverContent, PopoverTrigger } from '../../../../shadcn/popover.tsx'
import { ShadcnTooltip } from '../../../../design-system/tooltip.tsx'
import { MediaButtonProps } from './types.ts'
import { SpeedButtons } from './atoms/speed-buttons.tsx'
import { AudioSpeedType } from '../types.ts'

export const Play = ({ side }: MediaButtonProps) => {
  const isPaused = useMediaState('paused')
  return (
    <ShadcnTooltip content={isPaused ? 'Play' : 'Pause'} side={side}>
      <PlayButton
        className={
          'ring-media-focus group relative inline-flex h-10 w-10 cursor-pointer items-center justify-center rounded-md outline-none ring-inset hover:bg-gray-100 data-[focus]:ring-4'
        }
      >
        {isPaused ? (
          <PlayIcon className='h-8 w-8 text-stone-700 transition-colors duration-200 hover:text-stone-900 active:text-stone-900' />
        ) : (
          <PauseIcon className='h-8 w-8 text-stone-700 transition-colors duration-200 hover:text-stone-900 active:text-stone-900' />
        )}
      </PlayButton>
    </ShadcnTooltip>
  )
}

export const Mute = ({ side }: MediaButtonProps) => {
  const volume = useMediaState('volume'),
    isMuted = useMediaState('muted')
  return (
    <ShadcnTooltip content={isMuted ? 'Unmute' : 'Mute'} side={side}>
      <MuteButton
        className={
          'ring-media-focus group relative inline-flex h-8 w-8 cursor-pointer items-center justify-center rounded-md outline-none ring-inset hover:bg-gray-100 active:bg-gray-200 data-[focus]:ring-4'
        }
      >
        {isMuted || volume == 0 ? (
          <MuteIcon className='h-6 w-6 text-stone-700 hover:text-stone-900 active:text-stone-900' />
        ) : volume < 0.5 ? (
          <VolumeLowIcon className='h-6 w-6 text-stone-700 hover:text-stone-900 active:text-stone-900' />
        ) : (
          <VolumeHighIcon className='h-6 w-6 text-stone-700 hover:text-stone-900 active:text-stone-900' />
        )}
      </MuteButton>
    </ShadcnTooltip>
  )
}

export const Speed = ({ isSmOrLarger, audioSpeedType }: { isSmOrLarger: boolean; audioSpeedType: AudioSpeedType }) => {
  const playbackRate: number = useMediaState('playbackRate')

  return (
    <>
      {isSmOrLarger ? (
        <Popover>
          <ShadcnTooltip content='Change speed' side='top'>
            <PopoverTrigger asChild>
              <div className='flex h-8 cursor-pointer items-center rounded text-center text-sm font-medium text-stone-700 hover:bg-gray-100 hover:text-stone-900 active:bg-gray-200 active:text-stone-900'>
                {playbackRate.toFixed(2)}x
              </div>
            </PopoverTrigger>
          </ShadcnTooltip>
          <PopoverContent side='top' className='z-50 bg-white' sideOffset={20}>
            <div className='pb-2 text-center text-lg font-semibold leading-none tracking-tight'>Speed</div>
            <Sliders.Speed audioSpeedType={audioSpeedType} />
            <SpeedButtons audioSpeedType={audioSpeedType} />
          </PopoverContent>
        </Popover>
      ) : (
        <Drawer>
          <DrawerTrigger>
            <div className='flex items-center text-center text-sm font-medium hover:bg-gray-100'>
              {playbackRate.toFixed(2)}x
            </div>
          </DrawerTrigger>
          <DrawerContent className='bg-white pb-10'>
            <div className='mx-auto mt-4 h-2 w-[100px] rounded-full bg-muted' />
            <DrawerHeader>
              <DrawerTitle>Speed</DrawerTitle>
            </DrawerHeader>
            <div className='mx-auto w-11/12'>
              <Sliders.Speed data-vaul-no-drag audioSpeedType={audioSpeedType} />
              <SpeedButtons audioSpeedType={audioSpeedType} />
            </div>
          </DrawerContent>
        </Drawer>
      )}
    </>
  )
}
