import {
  selectAccountAccessToken,
  selectIsUserInfoFromAllSourcesLoaded,
  selectReferral,
} from '../state/slices/account-slice.ts'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useCheckoutMutation } from '../hooks/use-checkout-mutation.ts'
import { useNavigate, useParams } from 'react-router-dom'
import { buildPricingFreeTrialPath, ROUTE_PATHS } from '../routing/route-paths.ts'
import { FullViewSquaresLoader } from './loader/full-view-squares-loader.tsx'
import { getConfig } from '../config/environment-config.ts'

// This component is reached after the route /from-landing.
// We want to redirect users who clicked on the premium button on the landing page to be directed to the checkout page
// right after signing up. We need this additional route because Supabase login will strip search params from the url,
// so we have to send the user to a new route that uses a query param in order to preserve the priceId.
export const RedirectToCheckOut = () => {
  const accessToken = useSelector(selectAccountAccessToken)

  const navigate = useNavigate()
  const { priceId } = useParams<{ priceId: string }>()
  const referral = useSelector(selectReferral)
  const isUserInfoFromAllSourcesLoaded = useSelector(selectIsUserInfoFromAllSourcesLoaded)

  const { mutate } = useCheckoutMutation(accessToken)

  useEffect(() => {
    if (isUserInfoFromAllSourcesLoaded) {
      if (priceId) {
        if (referral) {
          const plan: 'month' | 'year' = priceId === getConfig().stripeMonthlyPriceId ? 'month' : 'year'
          navigate(buildPricingFreeTrialPath(plan))
        } else {
          mutate({
            priceId,
            successPathAndHash: ROUTE_PATHS.CHECKOUT_SUCCESS,
            cancelPathAndHash: ROUTE_PATHS.DASHBOARD,
          })
        }
      } else {
        navigate(ROUTE_PATHS.DASHBOARD)
      }
    }
  }, [priceId, mutate, isUserInfoFromAllSourcesLoaded, referral])

  return <FullViewSquaresLoader />
}
