import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { modalActions } from '../state/slices/modal-slice'
import { logWithRollbar } from '../analytics/rollbar/log-with-rollbar'
import { ApiError } from '../transport/transport/utils'
import { ROUTE_PATHS } from '../routing/route-paths.ts'
import { toast } from 'sonner'
import { POSTHOG_EVENTS } from '../analytics/posthog/posthog-events.ts'
import { ResponseWrapper } from '@shared/frontend-and-backend/body-types/response-wrapper.types.ts'
import { ERROR_CODE_FOR_AUTHENTICATING_FRONTEND } from '@shared/frontend-and-backend/key-generation/frontend-api-key-constants.ts'
import { USER_FACING_ERROR_CODE } from '../components/modal/modal-contents/something-went-wrong/types.ts'

export const useApiErrorHandler = (
  error: unknown,
  errorMessage: string,
  toastMessage?: string,
  rateLimitMessage?: string
) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    if (error) {
      const apiError = error as ApiError
      const status = apiError.status
      let ourBackendErrorCode: string | undefined
      if (400 <= status && status < 500) {
        const body = apiError.body as ResponseWrapper<never>
        ourBackendErrorCode = body.errors?.[0]?.code
      }
      if (status === 403) {
        POSTHOG_EVENTS.showPaywallToUser()
        navigate(ROUTE_PATHS.PRICING)
      } else if (status === 429) {
        POSTHOG_EVENTS.rateLimitUser()
        if (rateLimitMessage) {
          toast.error(rateLimitMessage)
        }
      } else if (status === 401 && ourBackendErrorCode === ERROR_CODE_FOR_AUTHENTICATING_FRONTEND) {
        POSTHOG_EVENTS.frontendAuthenticationError()
        dispatch(modalActions.openErrorModal(USER_FACING_ERROR_CODE.FRONTEND_AUTHENTICATION_ERROR))
        const date = new Date()
        // this used to happen on Kamil's xiaomi, whose time was messed up
        // read more in: GRAM-1004/fix/frontend-key-is-not-sent-to-backend-on-android-xiaomi
        logWithRollbar(
          `frontend authenticating error: date: ${date}, timestamp - ${date.getTime()}, error message - ${JSON.stringify(errorMessage)}`
        )
      } else {
        logWithRollbar(
          `error message - ${errorMessage}, apiError.message - ${apiError.message}, apiError.status - ${status}, JSON.stringify(apiError.body) - ${JSON.stringify(apiError.body)}, JSON.stringify(apiError) - ${JSON.stringify(apiError)}`
        )
        dispatch(modalActions.openErrorModal(USER_FACING_ERROR_CODE.GENERIC_ERROR))
        if (toastMessage) {
          toast.error(toastMessage)
        }
      }
    }
  }, [error, errorMessage, dispatch, navigate])
}
