import { LangCode } from '@shared/frontend-and-landing-and-backend/constants/lang-codes'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { UserSettings } from '@shared/frontend-and-backend/body-types/user-settings/user-settings.types.ts'
import { updateUserSettings } from '../transport/transport/our-backend/api/user-settings/user-settings.ts'
import { QUERY_KEYS } from '../transport/transport/our-backend/api/query-keys.ts'
import { toast } from 'sonner'
import { langCodeToLanguageName } from '../constants/lang-code-utils.ts'
import { logWithRollbar } from '../analytics/rollbar/log-with-rollbar.ts'

export const useUpdateFrequencyListPositionMutation = (accessToken: string, studyLanguage: LangCode) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (newSettings: UserSettings) => updateUserSettings(accessToken, newSettings),
    onSuccess: (response) => {
      queryClient.setQueryData<UserSettings>([QUERY_KEYS.USER_SETTINGS], response.data)
      toast.info(`Frequency list position updated for ${langCodeToLanguageName(studyLanguage)}`)
    },
    onError: (error) => {
      logWithRollbar(`Failed to update frequency list position: + ${JSON.stringify(error)} ${error}`)
      toast.error('Failed to update frequency list position.')
    },
  })
}
