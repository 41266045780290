export const AVAILABLE_TOPICS = [
  'greetings',
  'intro',
  'numbers',
  'family',
  'food',
  'directions',
  'shopping',
  'travel',
  'medicine',
  'weather',
  'business',
  'letters',
  'chatting',
  'slang',
  'news',
  'academia',
  'tech',
  'art',
  'history',
  'socializing',
  'music',
  'emotions',
  'nature',
  'career',
  'interview',
  'cooking',
  'gaming',
  'sports',
  'finance',
  'cinema',
  'dancing',
  'socialMedia',
  'gardening',
  'photography',
  'fashion',
] as const

export type Topic = (typeof AVAILABLE_TOPICS)[number]
