import { Button } from '../design-system/button.tsx'
import { Card } from '../design-system/card.tsx'
import { useEffect } from 'react'
import { POSTHOG_EVENTS } from '../../analytics/posthog/posthog-events.ts'
import { useSelector } from 'react-redux'
import { selectAccountAccessToken } from '../../state/slices/account-slice.ts'
import { useCheckoutMutation } from '../../hooks/use-checkout-mutation.ts'
import { ROUTE_PATHS } from '../../routing/route-paths.ts'
import { getConfig } from '../../config/environment-config.ts'
import { useSearchParams } from 'react-router-dom'
import { t } from '../../i18n/translate.ts'

const TimelineItem = ({ day, description }: { day: string; description: string }) => {
  return (
    <div className='relative flex h-16 items-start gap-3 pl-8'>
      <div className='absolute left-0 top-[6px] flex h-3 w-3 items-center justify-center rounded-full bg-indigo-600'></div>
      <div className='flex-1'>
        <p className='text-lg font-semibold text-indigo-700'>{day}</p>
        <p className='mt-1 text-sm text-gray-600'>{description}</p>
      </div>
    </div>
  )
}

export const FreeTrialExplanationView = () => {
  const accessToken = useSelector(selectAccountAccessToken)
  const { mutate, isPending: isPendingCheckoutMutation } = useCheckoutMutation(accessToken)
  const [searchParams] = useSearchParams()
  const plan = searchParams.get('plan')

  const monthlyPriceId = getConfig().stripeMonthlyPriceId
  const yearlyPriceId = getConfig().stripeYearlyPriceId

  useEffect(() => {
    POSTHOG_EVENTS.viewPage()
  }, [])

  const handleClick = () => {
    POSTHOG_EVENTS.click('subscribe_button')
    const priceId = plan === 'monthly' ? monthlyPriceId : yearlyPriceId

    mutate({
      priceId,
      successPathAndHash: ROUTE_PATHS.CHECKOUT_SUCCESS,
      cancelPathAndHash: ROUTE_PATHS.PRICING_FREE_TRIAL,
    })
  }

  return (
    <div className='mx-auto flex w-full max-w-6xl flex-col items-center gap-y-4 px-1 py-2 md:gap-y-8 md:px-4 md:py-4 md:py-8'>
      <Card className='max-w-2xl p-4 shadow'>
        <div className='py-4 md:py-8'>
          <h2 className='w-full text-center text-xl font-bold text-stone-800 md:text-3xl'>
            {t('pricing.freeTrial.title')}
          </h2>
        </div>

        <div className='flex flex-col gap-y-12 px-0 pb-6 md:px-6'>
          <div className='relative space-y-2'>
            <div className='absolute left-[5px] top-4 h-48 w-0.5 bg-indigo-100'></div>
            <div className='flex flex-col gap-8'>
              <TimelineItem
                day={t('pricing.freeTrial.timeline.today')}
                description={t('pricing.freeTrial.timeline.today.description')}
              />
              <TimelineItem
                day={t('pricing.freeTrial.timeline.sevenDays')}
                description={t('pricing.freeTrial.timeline.sevenDays.description')}
              />
              <TimelineItem
                day={t('pricing.freeTrial.timeline.twentyOneDays')}
                description={t('pricing.freeTrial.timeline.twentyOneDays.description')}
              />
            </div>
          </div>

          <div className='mt-6 space-y-4'>
            <Button className='h-12 w-full bg-green-500 text-lg text-white' onClick={handleClick}>
              {isPendingCheckoutMutation ? t('pricing.button.loading') : t('pricing.button.startTrial')}
            </Button>
          </div>
        </div>
      </Card>
    </div>
  )
}
