import { LangCode, SupportedMotherLanguage } from '@shared/frontend-and-landing-and-backend/constants/lang-codes'
import { ChangeEvent, useEffect, useState } from 'react'
import { accountActions, selectAccountAccessToken } from '../../../../state/slices/account-slice.ts'
import { useDispatch, useSelector } from 'react-redux'
import { LanguageCard } from './language-card.tsx'
import { NavigationButton } from '../navigation-button.tsx'
import { POSTHOG_EVENTS } from '../../../../analytics/posthog/posthog-events.ts'
import clsx from 'clsx'
import { filterMotherLangsByInput } from './mother-lang-filter-utils.ts'
import { Input } from '../../../shadcn/input.tsx'
import { ROUTE_PATHS } from '../../../../routing/route-paths.ts'
import { useNavigate } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'
import { updateMotherLanguage } from '../../../../transport/transport/our-backend/api/users/users.ts'
import { useApiErrorHandler } from '../../../../hooks/use-api-error-handler.ts'
import { t } from '../../../../i18n/translate.ts'

export const ChooseMotherLanguageView = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const accessToken = useSelector(selectAccountAccessToken)
  const [selectedMotherLanguage, setSelectedMotherLanguage] = useState<SupportedMotherLanguage | null>(null)
  const [hasSelectedOther, setHasSelectedOther] = useState<boolean>(false)
  const [text, setText] = useState('')
  const filteredLangs = filterMotherLangsByInput(text)

  const { mutate, isPending, error } = useMutation({
    mutationFn: (motherLanguage: LangCode) => updateMotherLanguage(accessToken, motherLanguage),
    onSuccess: () => {
      dispatch(accountActions.setMotherLanguage(selectedMotherLanguage))
      navigate(ROUTE_PATHS.ONBOARDING_CHOOSE_STUDY_LANGUAGE)
    },
  })

  useApiErrorHandler(error, 'Error when updating mother language')

  useEffect(() => {
    POSTHOG_EVENTS.viewPage()
  }, [])

  const onSkipClick = () => {
    dispatch(accountActions.setHasChosenNoneOfPossibleMotherLanguages())
    navigate(ROUTE_PATHS.ONBOARDING_CHOOSE_STUDY_LANGUAGE)
  }

  const onNextClick = () => {
    if (hasSelectedOther) {
      dispatch(accountActions.setMotherLanguage(LangCode.ENGLISH))
      navigate(ROUTE_PATHS.ONBOARDING_CHOOSE_STUDY_LANGUAGE)
    } else if (selectedMotherLanguage) {
      mutate(selectedMotherLanguage)
    }
  }

  const handleLangClick = (lang: SupportedMotherLanguage) => {
    if (selectedMotherLanguage === lang) {
      setSelectedMotherLanguage(null)
    } else {
      setSelectedMotherLanguage(lang)
      setHasSelectedOther(false)
    }
  }

  const handleHasSelectedOther = () => {
    setHasSelectedOther(!hasSelectedOther)
    setSelectedMotherLanguage(null)
  }

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setText(e.target.value)
  }

  return (
    <div className='flex w-full flex-col'>
      <div className='flex w-full items-center px-4 py-4'>
        <div className='mx-auto flex h-full w-full items-center justify-end md:w-1/2 3xl:w-1/3'>
          <button onClick={onSkipClick} disabled={isPending} className='text-gray-500 hover:text-gray-700'>
            {t('onboarding.chooseMotherLanguage.skip')}
          </button>
        </div>
      </div>

      <div className='mb-52 flex justify-center'>
        <div className='flex w-full flex-col items-center gap-y-4 px-4 pt-0 md:w-1/2 3xl:w-1/3'>
          <div className='flex max-w-md flex-col items-center'>
            <h1 className='max-w-xl text-center text-4xl font-bold tracking-tighter'>
              {t('onboarding.chooseMotherLanguage.title')}
            </h1>
            <span className='text-md max-w-xl text-center tracking-tighter text-gray-400'>
              {t('onboarding.chooseMotherLanguage.subtitle')}
            </span>
          </div>
          <Input
            autoFocus={true}
            value={text}
            onChange={handleInputChange}
            className='h-14 w-full rounded-xl bg-white text-lg'
            placeholder={t('onboarding.chooseMotherLanguage.searchPlaceholder')}
          />
          <div className='grid w-full grid-cols-2 gap-4'>
            {filteredLangs.map((lang) => (
              <LanguageCard
                key={lang}
                lang={lang}
                handleClick={() => handleLangClick(lang)}
                isSelected={selectedMotherLanguage === lang}
              />
            ))}
            <button
              className={clsx(
                'flex h-12 items-center justify-center rounded-xl border bg-white shadow focus:outline-none',
                { 'bg-gradient-to-r from-orange-300 to-yellow-300 text-white': hasSelectedOther },
                { 'hover:bg-gray-100': !hasSelectedOther }
              )}
              onClick={handleHasSelectedOther}
            >
              <span
                className={clsx('text-xl text-gray-500', {
                  'text-gray-700': hasSelectedOther,
                  'text-gray-500': !hasSelectedOther,
                })}
              >
                {t('onboarding.chooseMotherLanguage.other')}
              </span>
            </button>
          </div>
        </div>
      </div>

      <div className='fixed bottom-0 w-full bg-gray-50'>
        <div className='pointer-events-none absolute bottom-full left-0 right-0 h-40 bg-gradient-to-b from-transparent to-gray-50' />
        <div className='mx-auto flex w-full flex-col gap-y-3 px-4 py-4 md:w-1/2 3xl:w-1/3'>
          <NavigationButton
            onClick={onNextClick}
            disabled={(!selectedMotherLanguage && !hasSelectedOther) || isPending}
            className='w-full bg-gradient-to-r from-indigo-500 to-indigo-600 text-white'
          >
            {isPending ? t('onboarding.chooseMotherLanguage.loading') : t('onboarding.chooseMotherLanguage.next')}
          </NavigationButton>
        </div>
      </div>
    </div>
  )
}
