import {
  NUMBER_OF_DAYS_IN_FREE_TRIAL,
  REFUND_PERIOD_IN_DAYS,
} from '@shared/frontend-and-landing-and-backend/constants/pricing-constants.ts'
import { Dictionary } from '../translate.ts'
import { englishDictionary } from './english-dictionary.ts'

const CONTACT_US_TEXT = 'contact'

export const frenchDictionary: Dictionary = {
  ...englishDictionary, // if a French translation is missing, an English translation will be used

  'onboarding.chooseMotherLanguage.title': 'Quelle est votre langue maternelle ?',
  'onboarding.chooseMotherLanguage.subtitle': '(Vous pourrez la modifier plus tard)',
  'onboarding.chooseMotherLanguage.searchPlaceholder': 'Écrivez votre langue maternelle',
  'onboarding.chooseMotherLanguage.other': 'Autre',
  'onboarding.chooseMotherLanguage.skip': 'Passer',
  'onboarding.chooseMotherLanguage.next': 'Suivant',
  'onboarding.chooseMotherLanguage.loading': 'Chargement...',

  'onboarding.chooseStudyLanguage.title': 'Quelle langue souhaitez-vous étudier ?',
  'onboarding.chooseStudyLanguage.subtitle': '(Vous pourrez la modifier plus tard)',
  'onboarding.chooseStudyLanguage.previous': 'Précédent',
  'onboarding.chooseStudyLanguage.next': 'Suivant',
  'onboarding.chooseStudyLanguage.loading': 'Chargement...',

  'onboarding.termsAndConditions.title': 'Préparez-vous',
  'onboarding.termsAndConditions.subtitle': 'à cloner votre voix',
  'onboarding.termsAndConditions.removeVoiceInfo':
    "Vous pouvez toujours supprimer votre clone vocal plus tard. Il n'est utilisé par personne en dehors de l'application.",
  'onboarding.termsAndConditions.step1.title': 'Étape 1',
  'onboarding.termsAndConditions.step1.description':
    "À l'étape suivante, vous lirez un court texte à voix haute. Pour de meilleurs résultats, assurez-vous d'être dans un environnement calme. Vous pouvez faire des erreurs, elles n'affecteront pas le clonage vocal.",
  'onboarding.termsAndConditions.step2.title': 'Étape 2',
  'onboarding.termsAndConditions.step2.description':
    "Votre clone vocal sera intégré à votre expérience d'apprentissage, adaptant les exercices spécifiquement pour vous.",
  'onboarding.termsAndConditions.consent':
    "Je consens à ce que ma voix soit clonée pour des expériences d'apprentissage personnalisées sur YourBestAccent.com, et j'accepte les",
  'onboarding.termsAndConditions.termsOfService': "Conditions d'Utilisation",
  'onboarding.termsAndConditions.and': 'et',
  'onboarding.termsAndConditions.privacyPolicy': 'Politique de Confidentialité',
  'onboarding.termsAndConditions.previous': 'Précédent',
  'onboarding.termsAndConditions.next': 'Aller au Clonage Vocal',
  'onboarding.termsAndConditions.marketingConsent.label': 'Recevoir les mises à jour et conseils',
  'onboarding.termsAndConditions.marketingConsent.info':
    "Restez informé des nouvelles fonctionnalités, conseils d'apprentissage et offres spéciales pour améliorer votre expérience d'apprentissage des langues. Vous pouvez vous désabonner à tout moment.",

  'onboarding.chooseDialect.title': 'Choisissez le dialecte que vous souhaitez étudier :',
  'onboarding.chooseDialect.subtitle': '(Vous pourrez le modifier plus tard)',
  'onboarding.chooseDialect.previous': 'Précédent',
  'onboarding.chooseDialect.next': 'Suivant',
  'onboarding.chooseDialect.loading': 'Chargement...',

  'onboarding.chooseTopics.title': 'Sur quel sujet souhaitez-vous vous concentrer ?',
  'onboarding.chooseTopics.subtitle': '(Vous pourrez le modifier plus tard)',
  'onboarding.chooseTopics.previous': 'Précédent',
  'onboarding.chooseTopics.next': 'Suivant',
  'onboarding.chooseTopics.loading': 'Chargement...',
  'onboarding.chooseTopics.skip': 'Passer',

  'auth.signIn.title': 'Commencez à maîtriser la prononciation',
  'auth.signIn.subtitle': 'votre prononciation',
  'auth.signIn.withEmail': 'Se connecter avec Email',
  'auth.signIn.withGoogle': 'Se connecter avec Google',
  'auth.signUp.withEmail': "S'inscrire avec Email",
  'auth.signUp.withGoogle': "S'inscrire avec Google",

  'auth.signInEmail.title': 'Se connecter avec Email',
  'auth.signUpEmail.title': "S'inscrire avec Email",
  'auth.email.placeholder': 'Adresse email',
  'auth.email.invalidError': 'Veuillez entrer une adresse email valide',
  'auth.email.sendingEmail': "Envoi de l'email",
  'auth.email.or': 'Ou',
  'auth.email.noAccount': "vous n'avez pas de compte ?",
  'auth.email.haveAccount': 'Vous avez déjà un compte ?',
  'auth.email.signUp': "S'inscrire",
  'auth.email.signIn': 'Se connecter',

  'auth.emailVerify.title': 'Vérifiez Votre Email',
  'auth.emailVerify.verifying': 'Vérification...',
  'auth.emailVerify.verify': 'Vérifier',
  'auth.emailVerify.error.title': "Le lien email n'est pas valide ou a expiré",
  'auth.emailVerify.error.returnToSignIn': 'Retour à la Connexion',

  'auth.verificationSent.title': 'Email de Vérification Envoyé',
  'auth.verificationSent.sentTo': 'Nous avons envoyé un email de vérification à :',
  'auth.verificationSent.checkInbox': 'Veuillez vérifier votre boîte de réception et cliquer sur le',
  'auth.verificationSent.linkToSignIn': 'lien pour vous connecter',
  'auth.verificationSent.linkToSignUp': 'lien pour compléter votre inscription',

  'languages.english': 'anglais',
  'languages.spanish': 'espagnol',
  'languages.german': 'allemand',
  'languages.french': 'français',
  'languages.italian': 'italien',
  'languages.polish': 'polonais',
  'languages.czech': 'tchèque',
  'languages.ukrainian': 'ukrainien',
  'languages.russian': 'russe',
  'languages.portuguese': 'portugais',
  'languages.vietnamese': 'vietnamien',
  'languages.chinese': 'chinois',
  'languages.hindi': 'hindi',
  'languages.indonesian': 'indonésien',
  'languages.malay': 'malais',
  'languages.japanese': 'japonais',
  'languages.korean': 'coréen',
  'languages.tamil': 'tamoul',
  'languages.turkish': 'turc',
  'languages.romanian': 'roumain',
  'languages.swedish': 'suédois',
  'languages.norwegian': 'norvégien',
  'languages.danish': 'danois',
  'languages.slovak': 'slovaque',
  'languages.dutch': 'néerlandais',
  'languages.thai': 'thaïlandais',
  'languages.hungarian': 'hongrois',
  'languages.greek': 'grec',
  'languages.finnish': 'finnois',
  'languages.bulgarian': 'bulgare',
  'languages.catalan': 'catalan',

  'dialects.americanEnglish': 'anglais américain',
  'dialects.britishEnglish': 'anglais britannique',
  'dialects.australianEnglish': 'anglais australien',
  'dialects.scottishEnglish': 'anglais écossais',
  'dialects.castilianSpanish': 'espagnol castillan',
  'dialects.mexicanSpanish': 'espagnol mexicain',
  'dialects.colombianSpanish': 'espagnol colombien',
  'dialects.argentinianSpanish': 'espagnol argentin',
  'dialects.peruvianSpanish': 'espagnol péruvien',
  'dialects.parisianFrench': 'français parisien',
  'dialects.belgianFrench': 'français belge',
  'dialects.canadianFrench': 'français canadien',
  'dialects.standardGerman': 'allemand standard',
  'dialects.standardItalian': 'italien standard',
  'dialects.standardPolish': 'polonais standard',
  'dialects.brazilianPortuguese': 'portugais brésilien',
  'dialects.europeanPortuguese': 'portugais européen',
  'dialects.standardRussian': 'russe standard',
  'dialects.standardUkrainian': 'ukrainien standard',
  'dialects.standardCzech': 'tchèque standard',
  'dialects.standardDanish': 'danois standard',
  'dialects.standardDutch': 'néerlandais standard',
  'dialects.standardFinnish': 'finnois standard',
  'dialects.standardIndonesian': 'indonésien standard',
  'dialects.standardMalay': 'malais standard',
  'dialects.standardRomanian': 'roumain standard',
  'dialects.standardSlovak': 'slovaque standard',
  'dialects.standardSwedish': 'suédois standard',
  'dialects.standardTurkish': 'turc standard',
  'dialects.standardHungarian': 'hongrois standard',
  'dialects.standardNorwegian': 'norvégien standard',

  'dashboard.exercises.title': 'Exercices',
  'dashboard.exercises.subtitle': 'Continuez où vous vous êtes arrêté ou explorez de nouveaux exercices.',
  'dashboard.streak.title': "Votre Série d'Apprentissage",
  'dashboard.streak.subtitle': 'Explorez vos progrès',
  'dashboard.exercises.beginner.title': '{language} pour débutants',
  'dashboard.exercises.beginner.description': 'mots et expressions courants',
  'dashboard.exercises.levelBased.title': 'Par niveau',
  'dashboard.exercises.levelBased.description': 'mots adaptés à votre niveau de langue',
  'dashboard.exercises.random.title': 'Aléatoire',
  'dashboard.exercises.random.description': 'Pratiquez avec des mots aléatoires',
  'dashboard.exercises.custom.title': 'Personnalisé',
  'dashboard.exercises.custom.description': 'Pratiquez avec vos propres mots',
  'dashboard.savedWords.button': 'Mots sauvegardés',
  'dashboard.feedback.button': 'Partagez votre avis',
  'dashboard.mobile.exercises.title': 'Nos exercices',
  'dashboard.mobile.streak.title': "Votre série d'apprentissage",
  'dashboard.streak.popup.info':
    "Un jour compte pour votre série si vous apprenez au moins un mot. Un mot est considéré comme appris lorsqu'il est marqué en vert pour la première fois dans une évaluation d'exercice.",
  'dashboard.streak.current.title': 'Série de {count} jours',
  'dashboard.streak.current.subtitle': 'Plus que {count} jours pour obtenir le badge de série de {target} jours !',
  'dashboard.streak.longest.title': 'Série de {count} jours',
  'dashboard.streak.longest.subtitle': 'Votre plus longue série',

  'exercise.record.instructions':
    "Cliquez sur le bouton d'enregistrement et lisez le texte à voix haute. Quand vous avez terminé, cliquez sur arrêter et nous analyserons votre prononciation.",
  'exercise.button.tryAgain': 'Réessayer',
  'exercise.button.anotherExercise': 'Autre exercice',
  'exercise.button.nextSentence': 'Phrase suivante',
  'exercise.button.anotherText': 'Autre texte',
  'exercise.button.differentText': 'Texte différent',

  'exercise.controls.translate': 'Traduire',
  'exercise.controls.share': 'Partager',
  'exercise.controls.settings': 'Paramètres',
  'exercise.controls.searchLanguage': 'Rechercher une langue...',
  'exercise.controls.noLanguageFound': 'Aucune langue trouvée.',

  'exercise.custom.startPracticing': 'Commencer à pratiquer',
  'exercise.custom.title': 'Entrez le texte à pratiquer',
  'exercise.custom.languageSelect.help':
    'Nous essaierons de détecter automatiquement la langue de votre texte, mais il est préférable de la sélectionner manuellement.',
  'exercise.custom.languageSelect.switchTo': 'passer à :',
  'exercise.custom.textInput.placeholder':
    'Écrivez ici le texte à pratiquer. Exemple : "Betty Botter a acheté du beurre mais a dit que le beurre était amer."',
  'exercise.custom.error.tooShort': 'Le texte doit contenir au moins 2 caractères',
  'exercise.custom.error.tooLong': 'Le texte doit contenir moins de 750 caractères',
  'exercise.custom.success.languageUpdate': "Langue d'étude et dialecte mis à jour avec succès",
  'exercise.custom.error.languageUpdate': "Impossible de mettre à jour la langue d'étude",

  'exercise.skeleton.loading': 'Préparation de votre exercice...',

  'exercise.cefr.title': 'Niveau CECR',
  'exercise.cefr.info':
    "Le Cadre Européen Commun de Référence (CECR) définit la maîtrise d'une langue en six niveaux : A1 et A2 (utilisateur élémentaire), B1 et B2 (utilisateur indépendant), C1 et C2 (utilisateur expérimenté). Chaque niveau représente une compétence linguistique croissante, du débutant au quasi-natif. Ces niveaux standardisés aident les étudiants, les enseignants et les employeurs à comprendre et à comparer les compétences linguistiques internationalement.",
  'exercise.cefr.position': 'Position :',
  'exercise.cefr.beginner': 'Débutant',
  'exercise.cefr.advanced': 'Avancé',
  'exercise.cefr.frequency.info':
    "Nous générerons des phrases avec des mots autour de cette position dans la liste de fréquence. Une liste de fréquence montre quels mots sont les plus utilisés dans une langue. Plus la position du mot dans la liste de fréquence est basse, plus il apparaît souvent dans la langue. Les mots en positions plus élevées sont moins fréquents, ce qui signifie souvent qu'ils sont plus difficiles à apprendre.",

  'exercise.settings.length.title': "Longueur de l'exercice",
  'exercise.settings.length.wordLength': 'longueur des mots :',
  'exercise.settings.length.info': "La longueur réelle de l'exercice peut légèrement différer de la longueur demandée.",

  'exercise.settings.ipaAndTransliteration': 'API et translittération',
  'exercise.settings.ipaInfo':
    "L'alphabet phonétique international (API) et la translittération apparaîtront au-dessus des mots étudiés",

  'exercise.settings.ipa.title': 'API',
  'exercise.settings.ipa.info':
    'La transcription API (Alphabet Phonétique International) est une fonction expérimentale et peut contenir des erreurs.',

  'exercise.settings.transliteration.title': 'Translittération',
  'exercise.settings.transliteration.info':
    'Nous fournissons une translittération pour les langues utilisant des alphabets non latins.',

  'exercise.settings.title': "Paramètres de l'exercice",
  'exercise.settings.level': 'Niveau :',

  'translate.modal.originalText': 'Texte Original',
  'translate.modal.translation': 'Traduction',

  'navbar.contactUs': 'contact',
  'navbar.dropdown.account': 'Compte',
  'navbar.dropdown.settings': 'Paramètres',
  'navbar.dropdown.exercises': 'Exercices',
  'navbar.dropdown.leaderboard': 'Classement',
  'navbar.dropdown.progress': 'Progrès',
  'navbar.dropdown.pricing': 'Tarifs',
  'navbar.dropdown.contact': 'Contact',
  'navbar.dropdown.about': 'À propos',
  'navbar.dropdown.signOut': 'Se déconnecter',

  'settings.title': 'Paramètres',
  'settings.description': 'Modifiez vos paramètres ici',
  'settings.studyLanguage.title': "J'apprends :",
  'settings.studyLanguage.description': "C'est la langue qui sera utilisée dans les exercices.",
  'settings.motherLanguage.title': 'Ma langue maternelle :',
  'settings.dialect.title': 'Mon accent préféré :',
  'settings.dialect.description': 'Note : certains accents sont en version bêta. La vitesse audio peut varier.',
  'settings.searchLanguage': 'Rechercher une langue...',
  'settings.noLanguageFound': 'Aucune langue trouvée.',
  'settings.success.motherLanguage': 'Langue maternelle mise à jour avec succès',
  'settings.error.motherLanguage': 'Impossible de mettre à jour la langue maternelle',
  'settings.success.studyLanguage': "Langue d'étude et dialecte mis à jour avec succès",
  'settings.error.studyLanguage': "Impossible de mettre à jour la langue d'étude",
  'settings.success.dialect': 'Dialecte mis à jour avec succès',
  'settings.error.dialect': "Impossible de mettre à jour l'accent",

  'account.modal.title': 'Compte',
  'account.modal.description': 'Vos informations et paramètres de compte.',
  'account.modal.fullName': 'Nom complet',
  'account.modal.email': 'Email',
  'account.modal.publicNickname': 'Pseudo public',
  'account.modal.noNickname': 'Aucun pseudo défini',
  'account.modal.billing': 'Facturation',
  'account.modal.dangerZone': 'Zone dangereuse',
  'account.modal.marketingEmails.title': 'Notifications par email',
  'account.modal.marketingEmails.info': 'Mises à jour du produit',
  'account.modal.marketingEmails.popoverInfo':
    "Restez informé des nouvelles fonctionnalités, conseils d'apprentissage et offres spéciales pour améliorer votre expérience d'apprentissage des langues. Vous pouvez vous désabonner à tout moment.",
  'account.modal.marketingEmails.updateSuccess': 'Préférences email mises à jour avec succès',
  'account.modal.marketingEmails.updateError': 'Impossible de mettre à jour les préférences email',

  'nickname.title': 'Choisissez votre pseudo',
  'nickname.description': 'Ce pseudo sera public. Vous pourrez le modifier plus tard.',
  'nickname.input.placeholder': 'Écrivez votre pseudo',
  'nickname.button.saving': 'Enregistrement...',
  'nickname.button.join': 'Rejoindre le Classement',

  'leaderboard.title': 'Classement',
  'leaderboard.description': 'Voyez comment vous vous comparez aux autres étudiants',
  'leaderboard.empty': "Pas encore d'entrées. Soyez le premier à rejoindre le classement !",
  'leaderboard.position.title': 'Votre Position',
  'leaderboard.position.notOnBoard':
    "Vous n'êtes pas encore dans le classement. Faites quelques exercices pour commencer à rivaliser avec les autres !",
  'leaderboard.position.of': 'sur',
  'leaderboard.words': 'mots',
  'leaderboard.anonymous': 'Utilisateur Anonyme',

  'filter.allLanguages': 'Toutes les langues',

  'progress.title': 'Progrès',
  'progress.tabs.streak': 'Série',
  'progress.tabs.badges': 'Badges',
  'progress.tabs.stats': 'Statistiques',

  'stats.title': 'Statistiques',
  'stats.tabs.learnedWords': 'Mots Appris',
  'stats.tabs.savedWords': 'Mots Sauvegardés',

  'stats.learnedWords.empty': 'Pas encore de mots appris pour {language}.',
  'stats.learnedWords.noWords': "Vous n'avez pas encore de mots appris",
  'stats.learnedWords.goToExercises': 'Allez aux exercices pour apprendre de nouveaux mots',
  'stats.learnedWords.button': 'ALLER AUX EXERCICES',
  'stats.learnedWords.table.word': 'Mot',
  'stats.learnedWords.table.language': 'Langue',
  'stats.learnedWords.table.learnedOn': 'Appris le',
  'stats.learnedWords.chart.title': 'Mots appris',
  'stats.learnedWords.chart.subtitle': 'groupés par langue',

  'streak.title': "Votre Série d'Apprentissage",
  'streak.info':
    "Un jour compte pour votre série si vous apprenez au moins un mot. Un mot est considéré comme appris lorsqu'il est marqué en vert pour la première fois dans une évaluation d'exercice.",
  'streak.current': 'Série de {count} jours !',
  'streak.nextBadge': 'Plus que {count} jours pour obtenir le badge de série de {target} jours !',
  'streak.highestBadge': 'Vous avez atteint le plus haut badge ! Continuez ainsi !',
  'streak.longest.title': '{count} jours',
  'streak.longest.subtitle': 'Plus longue série',
  'streak.longest.info': "C'est la plus longue série que vous ayez réalisée. Continuez ainsi !",
  'streak.progress': 'Progrès',
  'streak.progress.days': 'jours',
  'streak.badges': 'Badges Gagnés : {count}',

  'pricing.premium.title': 'Choisissez votre forfait',
  'pricing.features.title': 'Fonctionnalités',
  'pricing.button.practice': 'Commencer à Pratiquer',

  'pricing.plans.monthly': 'Mensuel',
  'pricing.plans.yearly': 'Annuel',
  'pricing.plans.lifetime': 'À vie',
  'pricing.plans.freeTrial': 'Essai gratuit',
  'pricing.plans.billedYearly': 'facturé annuellement',
  'pricing.plans.mostPopular': 'plus populaire',
  'pricing.plans.bestValue': 'meilleur rapport qualité-prix',
  'pricing.plans.current': '(Actuel)',
  'pricing.plans.expired': '(Expiré)',
  'pricing.plans.freeTrialDays': `Gratuit pendant ${NUMBER_OF_DAYS_IN_FREE_TRIAL} jours`,
  'pricing.plans.perMonth': '€{amount}/mois',
  'pricing.plans.yearlyBilling': 'Facturé annuellement à €{amount}',
  'pricing.plans.referralDiscount': '{discount}% de réduction avec votre parrainage à vie !',
  'pricing.plans.referralDiscountMonth': '{discount}% de réduction avec votre parrainage pendant 1 mois !',
  'pricing.plans.referralDiscountMonths': '{discount}% de réduction avec votre parrainage pendant {duration} mois !',
  'pricing.plans.referralDiscountYear': '{discount}% de réduction avec votre parrainage pendant 1 an !',
  'pricing.plans.referralDiscountYears': '{discount}% de réduction avec votre parrainage pendant {duration} ans !',
  'pricing.plans.pastReferralDiscount': '{discount}% de réduction grâce à votre parrainage précédent !',

  'pricing.button.subscribe': "S'abonner",
  'pricing.button.manageSubscription': "Gérer l'Abonnement",
  'pricing.button.startPracticing': 'Commencer à Pratiquer',
  'pricing.button.loading': 'Chargement...',
  'pricing.button.currentPlan': 'Votre forfait actuel',
  'pricing.button.usedTrial': 'Vous avez déjà utilisé votre essai gratuit',
  'pricing.button.startTrial': `COMMENCER L'ESSAI GRATUIT DE ${NUMBER_OF_DAYS_IN_FREE_TRIAL} JOURS`,
  'pricing.button.subscribeNow': "S'ABONNER MAINTENANT",
  'pricing.button.goPractice': 'ALLER PRATIQUER',
  'pricing.button.start': 'COMMENCER',

  'pricing.faq.title': 'Questions Fréquentes',
  'pricing.faq.trial.question': 'Comment obtenir un essai gratuit ?',
  'pricing.faq.trial.answer.withCard': `Vous pouvez obtenir un essai gratuit de ${NUMBER_OF_DAYS_IN_FREE_TRIAL} jours en vous inscrivant et en saisissant les détails de votre carte bancaire. Ne vous inquiétez pas, aucun prélèvement ne sera effectué pendant la période d'essai et vous pouvez annuler à tout moment. Même si vous oubliez d'annuler et êtes facturé, nous offrons une garantie de remboursement de ${REFUND_PERIOD_IN_DAYS} jours.`,
  'pricing.faq.trial.answer.noCard': `Vous pouvez obtenir un essai gratuit en vous inscrivant à notre forfait d'Essai Gratuit. Aucune carte bancaire n'est requise, et l'essai se termine automatiquement après ${NUMBER_OF_DAYS_IN_FREE_TRIAL} jours.`,
  'pricing.faq.security.question': 'Le service de paiement est-il sécurisé ?',
  'pricing.faq.security.answer':
    'Oui, notre service de paiement est sécurisé. Nous utilisons Stripe pour le traitement financier et ne stockons aucune information de votre carte. Stripe garantit des normes de sécurité bancaire.',
  'pricing.faq.upgrade.question': 'Puis-je modifier mon abonnement ?',
  'pricing.faq.upgrade.answer':
    "Oui, vous pouvez augmenter ou réduire votre abonnement à tout moment. Allez en haut à droite de l'application une fois connecté, cliquez sur votre photo de profil, ouvrez la section Compte dans le menu et cliquez sur Gérer l'Abonnement. Vous serez redirigé vers le portail de facturation Stripe où vous pourrez changer de forfait.",
  'pricing.faq.cancel.question': 'Puis-je annuler mon abonnement à tout moment ?',
  'pricing.faq.cancel.answer':
    "Vous pouvez annuler votre abonnement à tout moment. Allez dans la section Compte une fois connecté et cliquez sur Gérer l'Abonnement. Vous serez redirigé vers le portail de facturation Stripe où vous pourrez télécharger vos factures, changer de forfait et annuler votre abonnement.",
  'pricing.faq.refund.question': 'Puis-je obtenir un remboursement ?',
  'pricing.faq.refund.answer': `Oui, vous pouvez obtenir un remboursement dans les ${REFUND_PERIOD_IN_DAYS} jours suivant votre facturation. Nous ne souhaitons pas facturer les clients qui ne sont pas satisfaits du produit. Veuillez en savoir plus sur notre politique de remboursement ici :`,
  'pricing.faq.refund.policy': 'Politique de Remboursement',

  'contact.title': 'Contact',
  'contact.help.title': 'Aidez-nous à améliorer YourBestAccent',
  'contact.help.description':
    'Partagez votre avis ou suggérez des améliorations aux fondateurs. Nous lisons et répondons à chaque message !',
  'contact.form.message.label': 'Message pour les fondateurs',
  'contact.form.message.placeholder': 'Dites-nous ce que vous pensez ou décrivez tout problème rencontré...',
  'contact.form.email.label': 'Email',
  'contact.form.email.placeholder': 'votre@email.com',
  'contact.form.name.label': 'Nom (facultatif)',
  'contact.form.name.placeholder': 'Votre nom',
  'contact.button.cancel': 'Annuler',
  'contact.button.send': 'Envoyer le Message',
  'contact.button.sending': 'Envoi...',
  'contact.button.error': 'Une erreur est survenue. Veuillez réessayer.',
  'contact.button.close': 'Fermer',
  'contact.success.message': 'Votre email a été envoyé !',
  'contact.success.toast': 'Merci pour votre retour !',
  'contact.error.toast': "Impossible d'envoyer le retour. Veuillez réessayer.",
  'contact.discord.message': 'Ou envoyez-nous un email pour toute question ou aide.',

  'dangerZone.title': 'Zone Dangereuse',
  'dangerZone.warning.title': 'Attention',
  'dangerZone.warning.description': 'Ces actions ne peuvent pas être annulées. Veuillez procéder avec précaution.',

  'exercise.length.short': 'Court',
  'exercise.length.medium': 'Moyen',
  'exercise.length.long': 'Long',

  'topics.greetings': 'Salutations',
  'topics.intro': 'Présentations',
  'topics.numbers': 'Nombres',
  'topics.family': 'Famille',
  'topics.food': 'Cuisine',
  'topics.directions': 'Directions',
  'topics.shopping': 'Achats',
  'topics.travel': 'Voyages',
  'topics.medicine': 'Médicine',
  'topics.weather': 'Météo',
  'topics.business': 'Affaires',
  'topics.letters': 'Lettres',
  'topics.chatting': 'Discussions',
  'topics.slang': 'Argot',
  'topics.news': 'Nouvelles',
  'topics.academia': 'Académie',
  'topics.tech': 'Technologie',
  'topics.art': 'Art',
  'topics.history': 'Histoire',
  'topics.socializing': 'Socialisation',
  'topics.music': 'Musique',
  'topics.emotions': 'Émotions',
  'topics.nature': 'Nature',
  'topics.career': 'Carrière',
  'topics.interview': 'Entretien',
  'topics.cooking': 'Cuisine',
  'topics.gaming': 'Jeux vidéo',
  'topics.sports': 'Sports',
  'topics.finance': 'Finances',
  'topics.cinema': 'Cinéma',
  'topics.dancing': 'Danse',
  'topics.socialMedia': 'Médias Sociaux',
  'topics.gardening': 'Jardinage',
  'topics.photography': 'Photographie',
  'topics.fashion': 'Mode',

  'signUpPrompt.pronunciation': "S'inscrire pour écouter la prononciation correcte générée avec votre clon de voix",
  'signUpPrompt.downloadAudio': "S'inscrire pour télécharger l'audio généré avec votre clon de voix",
  'signUpPrompt.savedWords': 'S\'inscrire pour aller à vos "mots sauvegardés"',
  'signUpPrompt.addToSavedWords': 'S\'inscrire pour ajouter le mot à vos "mots sauvegardés"',
  'signUpPrompt.tryAnotherExercise': "S'inscrire pour tester un autre exercice",
  'signUpPrompt.tryAnotherText': "S'inscrire pour tester un autre texte",

  'pricing.freeTrial.title': 'Comment fonctionne votre essai gratuit',
  'pricing.freeTrial.timeline.today': "Aujourd'hui",
  'pricing.freeTrial.timeline.today.description': 'Introduisez les détails de votre carte et obtenez accès instantané',
  'pricing.freeTrial.timeline.sevenDays': '7 jours',
  'pricing.freeTrial.timeline.sevenDays.description': 'Dernier jour pour annuler avant le premier prélèvement',
  'pricing.freeTrial.timeline.twentyOneDays': '21 jours',
  'pricing.freeTrial.timeline.twentyOneDays.description': `Même si vous êtes facturé, vous pouvez demander un remboursement à tout moment. Il suffit de nous le faire savoir en cliquant sur le bouton "${CONTACT_US_TEXT}" en haut.`,

  'pricing.toast.alreadyUsedTrial': 'Vous avez déjà utilisé votre essai gratuit.',
  'pricing.toast.changePlanInfo': 'Pour changer votre plan actuel, veuillez cliquer sur "Gérer l\'Abonnement".',
  'pricing.toast.portalError':
    "Impossible d'accéder à votre section de facturation en ce moment. Veuillez réessayer plus tard ou contacter le support si le problème persiste.",

  'exercise.pronunciation.your': 'Votre prononciation',
  'exercise.pronunciation.yourBetter': 'Votre meilleure prononciation',
}
