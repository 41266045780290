import {
  audioUrl,
  audioWordUrl,
  convertAudioUrl,
  getRandomTextExerciseUrl,
  getSentenceFromFrequencyListUrl,
  ipaTranscriptionUrl,
} from '../backend-api-urls.ts'
import {
  GetExerciseResponse,
  GetRandomExerciseRequest,
  GetSentenceFromFrequencyListExerciseRequest,
} from '@shared/frontend-and-backend/body-types/exercise/exercise.types.ts'
import {
  GetAudioIndividualWordRequest,
  GetAudioIndividualWordResponse,
  GetAudioRequest,
  GetAudioResponse,
} from '@shared/frontend-and-backend/body-types/pronunciation/get-audio.types.ts'
import { ResponseWrapper } from '@shared/frontend-and-backend/body-types/response-wrapper.types.ts'
import { DialectCode, SupportedStudyLanguage } from '@shared/frontend-and-landing-and-backend/constants/lang-codes.ts'
import {
  GetIpaRequest,
  GetIpaResponse,
} from '@shared/frontend-and-backend/body-types/pronunciation/transcribe-ipa.types.ts'
import { customFetch } from '../../../utils.ts'
import {
  ConvertAudioRequest,
  ConvertAudioResponse,
} from '@shared/frontend-and-backend/body-types/pronunciation/convert-audio.types.ts'

export const getGeneratedRandomExercise = async (
  wordsToInclude: string[],
  language: SupportedStudyLanguage,
  dialect: DialectCode,
  accessToken: string
): Promise<ResponseWrapper<GetExerciseResponse>> => {
  const body: GetRandomExerciseRequest = { wordsToInclude, language, dialect }
  const response: Response = await customFetch(getRandomTextExerciseUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(body),
  })

  return response.json()
}

export const getGeneratedAudio = async (
  text: string,
  language: SupportedStudyLanguage,
  dialect: DialectCode,
  accessToken: string
): Promise<ResponseWrapper<GetAudioResponse>> => {
  const body: GetAudioRequest = { text, language, dialect }
  const response = await customFetch(audioUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(body),
  })

  return response.json()
}

export const getGeneratedAudioIndividualWord = async (
  word: string,
  language: SupportedStudyLanguage,
  dialect: DialectCode,
  accessToken: string
): Promise<ResponseWrapper<GetAudioIndividualWordResponse>> => {
  const body: GetAudioIndividualWordRequest = {
    word,
    language,
    dialect,
  }
  const response = await customFetch(audioWordUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(body),
  })

  const responseData: ResponseWrapper<GetAudioIndividualWordResponse> = await response.json()
  return responseData
}

export const getGeneratedIpaTranscription = async (
  text: string,
  language: SupportedStudyLanguage,
  dialect: DialectCode,
  accessToken: string
): Promise<ResponseWrapper<GetIpaResponse>> => {
  const body: GetIpaRequest = { text, language, dialect }
  const response = await customFetch(ipaTranscriptionUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(body),
  })

  const responseData: ResponseWrapper<GetIpaResponse> = await response.json()
  return responseData
}

export const getSentenceFromFrequencyList = async (
  language: SupportedStudyLanguage,
  position: number,
  wordLength: number,
  dialect: DialectCode,
  accessToken: string
): Promise<ResponseWrapper<GetExerciseResponse>> => {
  const body: GetSentenceFromFrequencyListExerciseRequest = { position, language, dialect, wordLength }
  const response: Response = await customFetch(getSentenceFromFrequencyListUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(body),
  })

  return response.json()
}

export const convertAudio = async (
  audio: string,
  fromFormat: string,
  toFormat: string,
  accessToken: string
): Promise<ResponseWrapper<ConvertAudioResponse>> => {
  const body: ConvertAudioRequest = { audio, fromFormat, toFormat }
  const response = await customFetch(convertAudioUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(body),
  })

  return response.json()
}
