import { ResponseWrapper } from '@shared/frontend-and-backend/body-types/response-wrapper.types.ts'
import { DialectCode, LangCode } from '@shared/frontend-and-landing-and-backend/constants/lang-codes.ts'
import {
  TranslateTextRequest,
  TranslateTextResponse,
  TranslateWordRequest,
  TranslateWordResponse,
} from '@shared/frontend-and-backend/body-types/translation/translation.types.ts'
import { customFetch } from '../../../utils.ts'
import { translateTextOpenUrl, translateWordOpenUrl } from '../backend-open-api-urls.ts'

export const getWordTranslationViaOpenApi = async (
  text: string,
  sourceDialect: DialectCode,
  targetLanguage: LangCode,
  contextWords: string[],
  selectedWordIndex: number
): Promise<ResponseWrapper<TranslateWordResponse>> => {
  const body: TranslateWordRequest = {
    text,
    sourceDialect,
    targetLanguage,
    contextWords,
    selectedWordIndex,
  }
  const response: Response = await customFetch(translateWordOpenUrl, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
    },
  })

  const json = await response.json()
  return json
}

export const getTextTranslationViaOpenApi = async (
  text: string,
  sourceDialect: DialectCode,
  targetLanguage: LangCode
): Promise<ResponseWrapper<TranslateTextResponse>> => {
  const body: TranslateTextRequest = { text, sourceDialect, targetLanguage }
  const response: Response = await customFetch(translateTextOpenUrl, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
    },
  })

  const json = await response.json()
  return json
}
