import {
  LANGUAGES_WITH_MULTIPLE_DIALECTS,
  SUPPORTED_STUDY_LANGUAGES,
  SupportedStudyLanguage,
} from '@shared/frontend-and-landing-and-backend/constants/lang-codes'
import { useEffect, useState } from 'react'
import { accountActions, selectAccountAccessToken } from '../../../../state/slices/account-slice.ts'
import { useDispatch, useSelector } from 'react-redux'
import { LanguageCard } from './language-card.tsx'
import { NavigationButton } from '../navigation-button.tsx'
import { POSTHOG_EVENTS } from '../../../../analytics/posthog/posthog-events.ts'
import { useNavigate } from 'react-router-dom'
import { ROUTE_PATHS } from '../../../../routing/route-paths.ts'
import { useMutation } from '@tanstack/react-query'
import { updateStudyLanguage } from '../../../../transport/transport/our-backend/api/users/users.ts'
import { useApiErrorHandler } from '../../../../hooks/use-api-error-handler.ts'
import { t } from '../../../../i18n/translate.ts'
import { ArrowLeft } from 'lucide-react'

export const ChooseStudyLanguageView = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [selectedStudyLanguage, setSelectedStudyLanguage] = useState<SupportedStudyLanguage | null>(null)
  const accessToken = useSelector(selectAccountAccessToken)

  const { mutate, isPending, error } = useMutation({
    mutationFn: (studyLanguage: SupportedStudyLanguage) => updateStudyLanguage(accessToken, studyLanguage),
    onSuccess: () => {
      dispatch(accountActions.setStudyLanguage(selectedStudyLanguage as SupportedStudyLanguage))
      if (selectedStudyLanguage && LANGUAGES_WITH_MULTIPLE_DIALECTS.includes(selectedStudyLanguage)) {
        navigate(ROUTE_PATHS.ONBOARDING_CHOOSE_DIALECT)
      } else {
        navigate(ROUTE_PATHS.ONBOARDING_CHOOSE_TOPICS)
      }
    },
  })

  useApiErrorHandler(error, 'Error when updating study language')

  useEffect(() => {
    POSTHOG_EVENTS.viewPage()
  }, [])

  const handleLangClick = (lang: SupportedStudyLanguage) => {
    if (selectedStudyLanguage === lang) {
      setSelectedStudyLanguage(null)
    } else {
      setSelectedStudyLanguage(lang)
    }
  }

  const onPreviousClick = () => {
    dispatch(accountActions.resetMotherLanguage())
    navigate(ROUTE_PATHS.ONBOARDING_CHOOSE_MOTHER_LANGUAGE)
  }

  const onNextClick = () => {
    if (selectedStudyLanguage) {
      mutate(selectedStudyLanguage)
    }
  }

  return (
    <div className='flex w-full flex-col'>
      <div className='flex w-full items-center px-4 py-4'>
        <div className='mx-auto flex h-full w-full items-center md:w-1/2 3xl:w-1/3'>
          <button onClick={onPreviousClick} className='text-gray-500 hover:text-gray-700' disabled={isPending}>
            <ArrowLeft size={24} />
          </button>
        </div>
      </div>

      <div className='mb-52 flex justify-center'>
        <div className='flex w-full flex-col items-center gap-y-4 px-4 pt-0 md:w-1/2 3xl:w-1/3'>
          <div className='flex max-w-md flex-col items-center'>
            <h1 className='max-w-xl text-center text-4xl font-bold tracking-tighter'>
              {t('onboarding.chooseStudyLanguage.title')}
            </h1>
            <span className='text-md max-w-xl text-center tracking-tighter text-gray-400'>
              {t('onboarding.chooseStudyLanguage.subtitle')}
            </span>
          </div>
          <div className='grid w-full grid-cols-2 gap-4'>
            {SUPPORTED_STUDY_LANGUAGES.map((lang) => (
              <LanguageCard
                key={lang}
                lang={lang}
                handleClick={() => handleLangClick(lang)}
                isSelected={selectedStudyLanguage === lang}
              />
            ))}
          </div>
        </div>
      </div>

      <div className='fixed bottom-0 w-full bg-gray-50'>
        <div className='pointer-events-none absolute bottom-full left-0 right-0 h-40 bg-gradient-to-b from-transparent to-gray-50' />
        <div className='mx-auto flex w-full px-4 pb-8 pt-4 md:w-1/2 3xl:w-1/3'>
          <NavigationButton
            onClick={onNextClick}
            disabled={!selectedStudyLanguage || isPending}
            className='w-full bg-gradient-to-r from-indigo-500 to-indigo-600 text-white'
          >
            {isPending ? t('onboarding.chooseStudyLanguage.loading') : t('onboarding.chooseStudyLanguage.next')}
          </NavigationButton>
        </div>
      </div>
    </div>
  )
}
