import {
  MAX_EXERCISE_WORD_LENGTH,
  MIN_EXERCISE_WORD_LENGTH,
} from '@shared/frontend-and-backend/body-types/user-settings/user-settings.types.ts'
import { t } from '../../../../../../i18n/translate.ts'

export type Length = {
  name: string
  range: [number, number]
  visualWidth: number
}

export const SHORT_EXERCISE_LENGTH_UPPER_BOUND = 10

export const Lengths: Length[] = [
  {
    name: t('exercise.length.short'),
    range: [MIN_EXERCISE_WORD_LENGTH, SHORT_EXERCISE_LENGTH_UPPER_BOUND],
    visualWidth: 15,
  },
  { name: t('exercise.length.medium'), range: [SHORT_EXERCISE_LENGTH_UPPER_BOUND + 1, 30], visualWidth: 15 },
  { name: t('exercise.length.long'), range: [31, MAX_EXERCISE_WORD_LENGTH], visualWidth: 15 },
]

export const getCurrentLength = (position: number): Length => {
  const level = Lengths.find((length) => position >= length.range[0] && position <= length.range[1])
  return level || Lengths[Lengths.length - 1] // Return the last level if no match is found
}
