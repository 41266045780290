import { Popover, PopoverContent, PopoverTrigger } from '../../../shadcn/popover'
import { CircleHelp } from 'lucide-react'
import { Toggle } from '../../../design-system/toggle'
import { t } from '../../../../i18n/translate'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { updateMarketingEmailPreferences } from '../../../../transport/transport/our-backend/api/users/users'
import { toast } from 'sonner'
import { useSelector } from 'react-redux'
import { selectAccountAccessToken } from '../../../../state/slices/account-slice.ts'
import { QUERY_KEYS } from '../../../../transport/transport/our-backend/api/query-keys.ts'

export const MarketingEmailsToggle = () => {
  const accessToken = useSelector(selectAccountAccessToken)
  const queryClient = useQueryClient()
  const { data: shouldReceiveMarketingEmails = true } = useQuery<boolean>({
    queryKey: [QUERY_KEYS.SHOULD_RECEIVE_MARKETING_EMAILS],
  })

  const { mutate: updateMarketingEmailPreferencesMutation } = useMutation({
    mutationFn: async (newValue: boolean) => {
      return updateMarketingEmailPreferences(accessToken, {
        shouldReceiveMarketingEmails: newValue,
      })
    },
    onMutate: async (newValue: boolean) => {
      // Optimistic update as explained here: https://tanstack.com/query/latest/docs/framework/react/guides/optimistic-updates#updating-a-list-of-todos-when-adding-a-new-todo
      await queryClient.cancelQueries({
        queryKey: [QUERY_KEYS.SHOULD_RECEIVE_MARKETING_EMAILS],
      })
      const previousValue = queryClient.getQueryData<boolean>([QUERY_KEYS.SHOULD_RECEIVE_MARKETING_EMAILS])

      queryClient.setQueryData([QUERY_KEYS.SHOULD_RECEIVE_MARKETING_EMAILS], newValue)

      return { previousValue }
    },
    onError: (_err, _newValue, context) => {
      queryClient.setQueryData([QUERY_KEYS.SHOULD_RECEIVE_MARKETING_EMAILS], context?.previousValue)
      toast.error(t('account.modal.marketingEmails.updateError'))
    },
    onSuccess: () => {
      toast.success(t('account.modal.marketingEmails.updateSuccess'))
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.SHOULD_RECEIVE_MARKETING_EMAILS],
      })
    },
  })

  return (
    <div className='flex flex-col gap-y-1'>
      <span className='text-sm font-medium text-gray-500'>{t('account.modal.marketingEmails.title')}</span>
      <div className='flex items-center justify-between'>
        <div className='flex items-center space-x-2'>
          <span className='text-base text-stone-900'>{t('account.modal.marketingEmails.info')}</span>
          <Popover>
            <PopoverTrigger>
              <CircleHelp className='h-4 w-4 text-gray-400' />
            </PopoverTrigger>
            <PopoverContent className='w-64 bg-white text-sm'>
              {t('account.modal.marketingEmails.popoverInfo')}
            </PopoverContent>
          </Popover>
        </div>
        <Toggle
          isToggled={shouldReceiveMarketingEmails}
          onClick={() => updateMarketingEmailPreferencesMutation(!shouldReceiveMarketingEmails)}
          size='lg'
        />
      </div>
    </div>
  )
}
