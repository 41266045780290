import { getConfig } from '../../config/environment-config.ts'
import {
  PlanType,
  UserPricingDetails,
} from '@shared/frontend-and-backend/body-types/payments/subscription-account.types.ts'
import { MONTHLY_PRICE, YEARLY_PRICE } from '@shared/frontend-and-landing-and-backend/constants/pricing-constants.ts'
import { t } from '../../i18n/translate'

export type PlanOption = {
  label: string
  value: PlanType
  priceMessage: string
  discountMessage: string | null
  additionalMessage?: string
  billedYearly?: string
}

export type PricingViewConfig = {
  plans: PlanOption[]
  subscribeButton: {
    isDisabled: boolean
    text: string
  }
  startButton: {
    shouldBeShown: boolean
    text: string
  }
}

export const getPricingViewConfig = ({
  isPendingMutation,
  clickedPlan,
  pricingDetails,
  isFreeTrialFeatureFlagEnabled,
  canPay,
  hasReferral,
  hasFinishedOnboarding,
  currentActivePlan,
}: {
  isPendingMutation: boolean
  clickedPlan: PlanType
  pricingDetails: UserPricingDetails
  isFreeTrialFeatureFlagEnabled: boolean
  canPay: boolean
  hasReferral: boolean
  hasFinishedOnboarding: boolean
  currentActivePlan: PlanType | null
}): PricingViewConfig => {
  const planOptions: PlanOption[] = []

  const isSubscribedToFreeTrial = currentActivePlan === 'free_trial'
  const isSubscribedToMonthlyPlan = currentActivePlan === 'month'
  const isSubscribedToYearlyPlan = currentActivePlan === 'year'
  const hasIntroducedCardDetails = !canPay
  const canSubscribeWithReferralDiscount = pricingDetails?.currentlyAvailableDiscounts && canPay

  const yearlyPrice = getYearlyPrice(pricingDetails, isSubscribedToYearlyPlan)
  const monthlyPrice = getMonthlyPrice(pricingDetails, isSubscribedToMonthlyPlan)

  planOptions.push(
    {
      label: `${t('pricing.plans.yearly')}${isSubscribedToYearlyPlan ? ` ${t('pricing.plans.current')}` : ''}`,
      value: 'year',
      priceMessage: t('pricing.plans.perMonth').replace('{amount}', `${(yearlyPrice / 12).toFixed(2)}`),
      discountMessage: getYearlyDiscountString(pricingDetails, isSubscribedToYearlyPlan),
      additionalMessage: t('pricing.plans.bestValue'),
      billedYearly: t('pricing.plans.yearlyBilling').replace('{amount}', `${yearlyPrice.toFixed(2)}`),
    },
    {
      label: `${t('pricing.plans.monthly')}${isSubscribedToMonthlyPlan ? ` ${t('pricing.plans.current')}` : ''}`,
      value: 'month',
      priceMessage: t('pricing.plans.perMonth').replace('{amount}', `${monthlyPrice.toFixed(2)}`),
      discountMessage: getMonthlyDiscountString(pricingDetails, isSubscribedToMonthlyPlan),
    }
  )

  if (!hasReferral && isFreeTrialFeatureFlagEnabled) {
    planOptions.push({
      label: `${t('pricing.plans.freeTrial')} ${
        isSubscribedToFreeTrial ? t('pricing.plans.current') : t('pricing.plans.expired')
      }`,
      value: 'free_trial',
      discountMessage: null,
      priceMessage: t('pricing.plans.freeTrialDays'),
      additionalMessage: '',
    })
  }

  const getButtonText = () => {
    if (clickedPlan === 'free_trial') {
      if (canSubscribeWithReferralDiscount) {
        return t('pricing.button.startTrial')
      } else {
        if (isSubscribedToFreeTrial) {
          return t('pricing.button.currentPlan')
        } else {
          return t('pricing.button.usedTrial')
        }
      }
    }
    if (hasIntroducedCardDetails) {
      if (isPendingMutation) {
        return t('pricing.button.loading')
      } else {
        return t('pricing.button.manageSubscription')
      }
    }
    if (isPendingMutation) {
      return t('pricing.button.loading')
    }
    if (currentActivePlan === 'free_trial') {
      if (hasReferral) {
        return t('pricing.button.startTrial')
      } else {
        return t('pricing.button.subscribeNow')
      }
    } else {
      return t('pricing.button.subscribeNow')
    }
  }

  const shouldShowStartButton = () => {
    if (hasReferral) {
      if (isSubscribedToMonthlyPlan || isSubscribedToYearlyPlan) {
        return true
      }
    } else {
      if (
        isSubscribedToMonthlyPlan ||
        isSubscribedToYearlyPlan ||
        (isSubscribedToFreeTrial && clickedPlan === 'free_trial' && getConfig().featureFlags.isFreeTrialEnabled())
      ) {
        return true
      }
    }
    return false
  }

  return {
    plans: planOptions,
    subscribeButton: {
      isDisabled: clickedPlan === 'free_trial',
      text: getButtonText(),
    },
    startButton: {
      shouldBeShown: shouldShowStartButton(),
      text: hasFinishedOnboarding ? t('pricing.button.goPractice') : t('pricing.button.start'),
    },
  }
}

export const getMonthlyPrice = (pricingDetails: UserPricingDetails, isSubscribedToMonthlyPlan: boolean): number => {
  if (pricingDetails.hasSubscribedWithADiscount && isSubscribedToMonthlyPlan) {
    return MONTHLY_PRICE * (1 - pricingDetails.currentDiscountInPercentage / 100)
  }
  if (!pricingDetails.currentlyAvailableDiscounts) {
    return MONTHLY_PRICE
  }
  return ((100 - pricingDetails.currentlyAvailableDiscounts.monthly.discountAsPercentage) / 100) * MONTHLY_PRICE
}

export const getYearlyPrice = (pricingDetails: UserPricingDetails, isSubscribedToYearlyPlan: boolean): number => {
  if (pricingDetails.hasSubscribedWithADiscount && isSubscribedToYearlyPlan) {
    return YEARLY_PRICE * (1 - pricingDetails.currentDiscountInPercentage / 100)
  }
  if (!pricingDetails.currentlyAvailableDiscounts) {
    return YEARLY_PRICE
  }
  return ((100 - pricingDetails.currentlyAvailableDiscounts.yearly.discountAsPercentage) / 100) * YEARLY_PRICE
}

export const getMonthlyDiscountString = (
  pricingDetails: UserPricingDetails,
  isSubscribedToMonthlyPlan: boolean
): string | null => {
  if (pricingDetails.hasSubscribedWithADiscount && isSubscribedToMonthlyPlan) {
    return t('pricing.plans.pastReferralDiscount').replace(
      '{discount}',
      pricingDetails.currentDiscountInPercentage.toString()
    )
  }
  if (!pricingDetails.currentlyAvailableDiscounts) {
    return null
  }
  const durationLimit = pricingDetails.currentlyAvailableDiscounts.monthly.durationLimit
  const discount = pricingDetails.currentlyAvailableDiscounts.monthly.discountAsPercentage
  if (!durationLimit) {
    return t('pricing.plans.referralDiscount').replace('{discount}', discount.toString())
  }
  if (durationLimit === 1) {
    return t('pricing.plans.referralDiscountMonth').replace('{discount}', discount.toString())
  }
  return t('pricing.plans.referralDiscountMonths')
    .replace('{discount}', discount.toString())
    .replace('{duration}', durationLimit.toString())
}

export const getYearlyDiscountString = (
  pricingDetails: UserPricingDetails,
  isSubscribedToYearlyPlan: boolean
): string | null => {
  if (pricingDetails.hasSubscribedWithADiscount && isSubscribedToYearlyPlan) {
    return t('pricing.plans.pastReferralDiscount').replace(
      '{discount}',
      pricingDetails.currentDiscountInPercentage.toString()
    )
  }
  if (!pricingDetails.currentlyAvailableDiscounts) {
    return null
  }
  const durationLimit = pricingDetails.currentlyAvailableDiscounts.yearly.durationLimit
  const discount = pricingDetails.currentlyAvailableDiscounts.yearly.discountAsPercentage
  if (!durationLimit) {
    return t('pricing.plans.referralDiscount').replace('{discount}', discount.toString())
  }
  if (durationLimit === 1) {
    return t('pricing.plans.referralDiscountYear').replace('{discount}', discount.toString())
  }
  return t('pricing.plans.referralDiscountYears')
    .replace('{discount}', discount.toString())
    .replace('{duration}', durationLimit.toString())
}
