import { SpeedSlider, TimeSlider, TimeSliderInstance, useMediaRemote, VolumeSlider } from '@vidstack/react'
import { RefObject } from 'react'
import {
  MAX_AUDIO_SPEED,
  MIN_AUDIO_SPEED,
  UserSettings,
} from '@shared/frontend-and-backend/body-types/user-settings/user-settings.types.ts'
import { useSelector } from 'react-redux'
import { selectAccountAccessToken } from '../../../../../state/slices/account-slice.ts'
import { useUpdateAudioSpeedMutation } from '../../../../../hooks/use-update-audio-speed-mutation.ts'
import { useUserSettings } from '../../../../../transport/transport/our-backend/api/users/users-hooks.ts'
import { cloneDeep } from 'lodash'
import { AudioSpeedType } from '../types.ts'

export const Volume = () => {
  return (
    <VolumeSlider.Root className='volume-slider group relative inline-flex h-10 w-full max-w-[80px] cursor-pointer touch-none select-none items-center outline-none aria-hidden:hidden'>
      <VolumeSlider.Track className='ring-media-focus relative z-0 h-[5px] w-full rounded-sm bg-gray-200 group-data-[focus]:ring-[3px]'>
        <VolumeSlider.TrackFill className='absolute h-full w-[var(--slider-fill)] rounded-sm bg-gray-400 will-change-[width]' />
      </VolumeSlider.Track>

      <VolumeSlider.Preview
        className='pointer-events-none flex flex-col items-center opacity-0 transition-opacity duration-200 data-[visible]:opacity-100'
        noClamp
      >
        <VolumeSlider.Value className='rounded-sm bg-black px-2 py-px text-[13px] font-medium text-white' />
      </VolumeSlider.Preview>
      <VolumeSlider.Thumb className='absolute left-[var(--slider-fill)] top-1/2 z-20 h-[15px] w-[15px] -translate-x-1/2 -translate-y-1/2 rounded-full border border-[#cacaca] bg-white opacity-100 ring-gray-300/40 transition-opacity will-change-[left] group-data-[dragging]:ring-4' />
    </VolumeSlider.Root>
  )
}

export const Speed = ({ audioSpeedType }: { audioSpeedType: AudioSpeedType }) => {
  const remote = useMediaRemote()
  const accessToken = useSelector(selectAccountAccessToken)
  const { mutate } = useUpdateAudioSpeedMutation(accessToken)
  const { data: userSettings } = useUserSettings()

  const updateAudioSpeed = (audioSpeed: number) => {
    if (!userSettings) {
      return
    }
    const updatedSettings: UserSettings = cloneDeep(userSettings)
    updatedSettings.preferences.exercises.audioSpeed[audioSpeedType] = audioSpeed
    mutate(updatedSettings)
  }

  const handleSpeedChange = (speed: number) => {
    remote.changePlaybackRate(speed)
  }

  const handleSpeedCommit = (speed: number) => {
    updateAudioSpeed(speed)
  }

  return (
    <>
      <SpeedSlider.Root
        step={0.05}
        max={MAX_AUDIO_SPEED}
        min={MIN_AUDIO_SPEED}
        orientation='horizontal'
        className='group relative inline-flex h-10 w-full cursor-pointer touch-none select-none items-center outline-none aria-hidden:hidden'
        onValueChange={handleSpeedChange}
        onDragEnd={handleSpeedCommit}
      >
        <SpeedSlider.Preview className='pointer-events-none flex flex-col items-center opacity-0 transition-opacity duration-200 data-[visible]:opacity-100'>
          <SpeedSlider.Value className='rounded-sm bg-black px-2 py-px text-[13px] font-medium text-white' />
        </SpeedSlider.Preview>
        <SpeedSlider.Track className='relative z-0 h-[5px] w-full rounded-sm bg-gray-200 ring-sky-400 group-data-[focus]:ring-[3px]'>
          <SpeedSlider.TrackFill className='absolute h-full w-[var(--slider-fill)] rounded-sm bg-gray-400 will-change-[width]' />
        </SpeedSlider.Track>
        <SpeedSlider.Thumb className='absolute left-[var(--slider-fill)] top-1/2 z-20 h-[15px] w-[15px] -translate-x-1/2 -translate-y-1/2 rounded-full border border-[#cacaca] bg-white opacity-100 ring-gray-300/40 transition-opacity will-change-[left] group-data-[active]:opacity-100 group-data-[dragging]:ring-4' />
        <SpeedSlider.Steps className='absolute left-0 top-0 flex h-full w-full items-center justify-between'>
          {(step) => (
            <div
              className='h-1.5 w-0.5 bg-[#7c7c7c] opacity-0 transition-opacity group-data-[active]:opacity-100'
              key={String(step)}
            />
          )}
        </SpeedSlider.Steps>
      </SpeedSlider.Root>
    </>
  )
}

export const Time = ({ timeSliderRef }: { timeSliderRef?: RefObject<TimeSliderInstance> | null }) => {
  return (
    <TimeSlider.Root
      ref={timeSliderRef}
      className='time-slider group relative inline-flex h-6 w-full cursor-pointer touch-none select-none items-center outline-none'
    >
      <TimeSlider.Track className='ring-media-focus relative z-0 h-[5px] w-full rounded-sm bg-gray-200 group-data-[focus]:ring-[3px]'>
        <TimeSlider.TrackFill className='absolute h-full w-[var(--slider-fill)] rounded-sm bg-gray-400 will-change-[width]' />
        <TimeSlider.Progress className='absolute z-10 h-full w-[var(--chapter-progress)] rounded-sm bg-gray-200 will-change-[width]' />
      </TimeSlider.Track>

      <TimeSlider.Thumb className='absolute left-[var(--slider-fill)] top-1/2 h-[15px] w-[15px] -translate-x-1/2 -translate-y-1/2 rounded-full border border-[#cacaca] bg-white opacity-100 ring-gray-300/40 transition-opacity will-change-[left] group-data-[active]:opacity-100 group-data-[dragging]:ring-4' />

      <TimeSlider.Preview className='pointer-events-none flex flex-col items-center opacity-0 transition-opacity duration-200 data-[visible]:opacity-100'>
        <TimeSlider.Value className='rounded-sm bg-black px-2 py-px text-[13px] text-white' />
      </TimeSlider.Preview>
    </TimeSlider.Root>
  )
}
