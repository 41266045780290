import { useEffect, useState } from 'react'
import { Circle } from 'lucide-react'
import { useSelector } from 'react-redux'
import {
  selectAccountAccessToken,
  selectHasFinishedOnboarding,
  selectHasReferral,
} from '../../state/slices/account-slice'
import { getConfig } from '../../config/environment-config'
import { useMutation, useQuery } from '@tanstack/react-query'
import {
  createCustomerPortalSession,
  getSubscriptionDetails,
} from '../../transport/transport/our-backend/api/payments/subscription-account'
import { QUERY_KEYS } from '../../transport/transport/our-backend/api/query-keys.ts'
import { POSTHOG_EVENTS } from '../../analytics/posthog/posthog-events.ts'
import { useCheckoutMutation } from '../../hooks/use-checkout-mutation.ts'
import { buildPricingFreeTrialPath, ROUTE_PATHS } from '../../routing/route-paths'
import { PricingFaqItems } from './pricing-faq-items'
import { Button } from '../design-system/button.tsx'
import { Card } from '../design-system/card.tsx'
import { getPricingViewConfig, PlanOption, PricingViewConfig } from './pricing-view-utils.ts'
import { toast } from 'sonner'
import { logWithRollbar } from '../../analytics/rollbar/log-with-rollbar.ts'
import { clsx } from 'clsx'
import { PlanType } from '@shared/frontend-and-backend/body-types/payments/subscription-account.types.ts'
import { useNavigate } from 'react-router-dom'
import { t } from '../../i18n/translate'

const LeftPartOfButton = ({ option, isChosen }: { option: PlanOption; isChosen: boolean }) => {
  const desktopVersion = (
    <div className='hidden items-center md:flex'>
      <div className='mr-2'>
        {isChosen ? (
          <Circle className='h-5 w-5 fill-indigo-600 text-indigo-600' />
        ) : (
          <Circle className='h-5 w-5 text-gray-400' />
        )}
      </div>
      <div>
        <span>{option.label}</span>
        {option.additionalMessage && (
          <span className='ml-2 rounded-full bg-indigo-100 px-2 py-1 text-sm font-medium text-indigo-800'>
            {option.additionalMessage}
          </span>
        )}
      </div>
    </div>
  )

  const mobileVersion = (
    <div className='flex flex-col items-start gap-y-2 md:hidden'>
      <div className='flex items-center'>
        <div className='mr-2'>
          {isChosen ? (
            <Circle className='h-5 w-5 fill-indigo-600 text-indigo-600' />
          ) : (
            <Circle className='h-5 w-5 text-gray-400' />
          )}
        </div>
        <span>{option.label}</span>
      </div>
      {option.additionalMessage && (
        <span className='rounded-full bg-indigo-100 px-2 py-1 text-xs font-medium text-indigo-800'>
          {option.additionalMessage}
        </span>
      )}
    </div>
  )

  return (
    <>
      {desktopVersion}
      {mobileVersion}
    </>
  )
}

export const PricingView = () => {
  const accessToken = useSelector(selectAccountAccessToken)
  const [clickedPlan, setClickedPlan] = useState<PlanType>('year')
  const hasReferral = useSelector(selectHasReferral)
  const navigate = useNavigate()
  const hasFinishedOnboarding = useSelector(selectHasFinishedOnboarding)

  const handlePlanOptionClick = (planType: PlanType) => {
    POSTHOG_EVENTS.clickPlan('plan_radio_button', planType)
    if (hasIntroducedCardDetails) {
      if (planType === 'free_trial') {
        toast.info(t('pricing.toast.alreadyUsedTrial'))
      } else {
        toast.info(t('pricing.toast.changePlanInfo'))
      }
    } else {
      setClickedPlan(planType)
    }
  }

  const { mutate: mutateCustomerPortalSession, isPending: isCustomerPortalMutationPending } = useMutation({
    mutationFn: ({ returnPath }: { returnPath: string }) => createCustomerPortalSession(accessToken, returnPath),
    onSuccess: (data) => {
      if (data.data?.url) {
        window.location.href = data.data.url
      }
    },
    onError: (error) => {
      toast.error(t('pricing.toast.portalError'))
      logWithRollbar(`Error creating customer portal session: ${error}`)
    },
  })
  const { mutate, isPending: isPendingCheckoutMutation } = useCheckoutMutation(accessToken)
  const { data: subscriptionData } = useQuery({
    queryKey: [QUERY_KEYS.SUBSCRIPTION_DETAILS, accessToken],
    queryFn: () => getSubscriptionDetails(accessToken),
    enabled: !!accessToken,
  })

  const subscriptionInfo = subscriptionData?.data
  const details = subscriptionInfo?.details
  const currentActivePlan = details?.currentActivePlan || null
  const pricing = details?.userPricingDetails || null

  useEffect(() => {
    if (currentActivePlan) {
      if (hasReferral) {
        // referral users should never see free_trial plan
        if (currentActivePlan !== 'free_trial') {
          setClickedPlan(currentActivePlan)
        }
      } else {
        if (currentActivePlan === 'free_trial') {
          if (getConfig().featureFlags.isFreeTrialEnabled()) {
            // non referral users should not see free_trial if the feature flag is disabled
            setClickedPlan(currentActivePlan)
          }
        } else {
          setClickedPlan(currentActivePlan)
        }
      }
    }
  }, [currentActivePlan, hasReferral])

  const canPay = details?.canPay ?? false
  const hasIntroducedCardDetails = !canPay

  const monthlyPriceId = getConfig().stripeMonthlyPriceId
  const yearlyPriceId = getConfig().stripeYearlyPriceId
  const lifetimePriceId = getConfig().stripeLifetimePriceId

  const handleCTAClick = () => {
    POSTHOG_EVENTS.click('subscribe_button')
    if (hasIntroducedCardDetails) {
      const currentPath = location.pathname + location.search
      mutateCustomerPortalSession({ returnPath: currentPath })
    } else if (hasReferral) {
      navigate(buildPricingFreeTrialPath(clickedPlan as 'month' | 'year'))
    } else {
      let priceId
      switch (clickedPlan) {
        case 'month':
          priceId = monthlyPriceId
          break
        case 'year':
          priceId = yearlyPriceId
          break
        default:
          priceId = lifetimePriceId
          break
      }
      mutate({
        priceId: priceId,
        successPathAndHash: ROUTE_PATHS.CHECKOUT_SUCCESS,
        cancelPathAndHash: ROUTE_PATHS.PRICING,
      })
    }
  }

  const handleGoPracticeNowClick = () => {
    POSTHOG_EVENTS.click('go_practice_now_button')
    navigate(ROUTE_PATHS.DASHBOARD)
  }

  useEffect(() => {
    POSTHOG_EVENTS.viewPage()
  }, [])

  const config: PricingViewConfig = getPricingViewConfig({
    isPendingMutation: isPendingCheckoutMutation || isCustomerPortalMutationPending,
    clickedPlan: clickedPlan,
    pricingDetails: pricing || {
      amountInEurosThatUserIsCurrentlyPayingPerInterval: null,
      hasSubscribedWithADiscount: false,
      currentlyAvailableDiscounts: null,
      currentDiscountInPercentage: 0,
    },
    isFreeTrialFeatureFlagEnabled: getConfig().featureFlags.isFreeTrialEnabled(),
    canPay: canPay,
    hasReferral: hasReferral,
    hasFinishedOnboarding: hasFinishedOnboarding,
    currentActivePlan: currentActivePlan,
  })

  return (
    <div className='mx-auto flex w-full max-w-6xl flex-col items-center gap-y-4 px-1 py-4 md:gap-y-8 md:px-4 md:py-8'>
      <Card className='max-w-2xl p-0 shadow'>
        <div className='px-6 py-4 md:py-8'>
          <h1 className='w-full text-center text-3xl font-bold text-stone-800'>{t('pricing.premium.title')}</h1>
        </div>
        <div className='px-2 pb-2 md:px-6 md:pb-6'>
          <div className='mb-6 flex flex-col gap-y-4'>
            {config.plans.map((option) => {
              return (
                <Button
                  key={option.value}
                  onClick={() => handlePlanOptionClick(option.value as PlanType)}
                  className={clsx('flex h-auto min-h-20 justify-between rounded-lg border px-2 py-4 md:px-4', {
                    'border-indigo-600 bg-indigo-50': clickedPlan === option.value,
                    'border-gray-200': clickedPlan === option.value,
                    'opacity-60': clickedPlan !== option.value && hasIntroducedCardDetails,
                  })}
                  shouldHaveHoverAndActiveStyles={!hasIntroducedCardDetails}
                >
                  <LeftPartOfButton option={option} isChosen={clickedPlan === option.value} />
                  <div className='text-right'>
                    <div className='font-semibold'>{option.priceMessage}</div>
                    {option.discountMessage && <div className='text-sm text-green-600'>{option.discountMessage}</div>}
                    {option.billedYearly && <div className='text-sm text-gray-500'>{option.billedYearly}</div>}
                  </div>
                </Button>
              )
            })}
          </div>

          <div className='flex w-full flex-col gap-y-4'>
            <Button
              className='h-12 w-full bg-green-500 text-lg text-white'
              onClick={handleCTAClick}
              disabled={config.subscribeButton.isDisabled}
            >
              {config.subscribeButton.text}
            </Button>
            {config.startButton.shouldBeShown && (
              <Button className='h-12 w-full bg-indigo-600 text-lg text-white' onClick={handleGoPracticeNowClick}>
                {config.startButton.text}
              </Button>
            )}
          </div>
        </div>
      </Card>

      <Card className='max-w-2xl shadow'>
        <h2 className='mb-2 text-center text-3xl font-bold text-gray-900 md:mb-8'>{t('pricing.faq.title')}</h2>
        <PricingFaqItems />
      </Card>
    </div>
  )
}
