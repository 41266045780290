import { z } from 'zod'
import { LearnedWordsInADay, WordsInLanguageCounter } from '@shared/frontend-and-backend/body-types/words/words.types'
import { UserSettings } from '@shared/frontend-and-backend/body-types/user-settings/user-settings.types'
import {
  DialectCode,
  LangCode,
  SUPPORTED_STUDY_LANGUAGES,
  SupportedStudyLanguage,
} from '@shared/frontend-and-landing-and-backend/constants/lang-codes'
import * as naughtyWords from 'naughty-words'
import { AVAILABLE_TOPICS, Topic } from '@shared/frontend-and-backend/constants/topics'

export type PutUserResponse = {
  hasVoice: boolean
  counters: WordsInLanguageCounter[]
  learnedWordsByDay: LearnedWordsInADay[]
  settings: UserSettings
  referral: string | null
  studyLanguage: SupportedStudyLanguage | null
  studyDialect: DialectCode | null
  motherLanguage: LangCode | null
  topics: Topic[]
  nickname: string | null
  shouldReceiveMarketingEmails: boolean
}

export type UpdateUserResponse = {
  hasVoice: boolean
}

export type PutUserRequest = {
  referral?: string
}

export const PatchUserStudyLanguageSchema = z.object({
  studyLanguage: z.nativeEnum(LangCode),
})

export type PatchUserStudyLanguageRequest = z.infer<typeof PatchUserStudyLanguageSchema>

export const PatchUserStudyLanguageResponseSchema = z.object({
  studyLanguage: z.enum(SUPPORTED_STUDY_LANGUAGES),
})

export type PatchUserStudyLanguageResponse = z.infer<typeof PatchUserStudyLanguageResponseSchema>

export const PatchUserStudyDialectSchema = z.object({
  studyDialect: z.nativeEnum(DialectCode),
})

export type PatchUserStudyDialectRequest = z.infer<typeof PatchUserStudyDialectSchema>

export const PatchUserStudyDialectResponseSchema = z.object({
  studyDialect: z.nativeEnum(DialectCode).nullable(),
})

export type PatchUserStudyDialectResponse = z.infer<typeof PatchUserStudyDialectResponseSchema>

export const PatchUserMotherLanguageSchema = z.object({
  motherLanguage: z.nativeEnum(LangCode),
})

export type PatchUserMotherLanguageRequest = z.infer<typeof PatchUserMotherLanguageSchema>

export const PatchUserMotherLanguageResponseSchema = z.object({
  motherLanguage: z.nativeEnum(LangCode).nullable(),
})

export type PatchUserMotherLanguageResponse = z.infer<typeof PatchUserMotherLanguageResponseSchema>

export const PatchUserStudyLanguageAndDialectSchema = z.object({
  studyLanguage: z.enum(SUPPORTED_STUDY_LANGUAGES),
  studyDialect: z.nativeEnum(DialectCode),
})

export const PatchUserTopicsSchema = z.object({
  topics: z.array(z.enum(AVAILABLE_TOPICS)),
})

export type PatchUserTopicsRequest = z.infer<typeof PatchUserTopicsSchema>

export const PatchUserTopicsResponseSchema = z.object({
  topics: z.array(z.enum(AVAILABLE_TOPICS)),
})

export type PatchUserTopicsResponse = z.infer<typeof PatchUserTopicsResponseSchema>

export type PatchUserStudyLanguageAndDialectRequest = z.infer<typeof PatchUserStudyLanguageAndDialectSchema>

export const PatchUserStudyLanguageAndDialectResponseSchema = z.object({
  studyLanguage: z.enum(SUPPORTED_STUDY_LANGUAGES),
  studyDialect: z.nativeEnum(DialectCode),
})

export type PatchUserStudyLanguageAndDialectResponse = z.infer<typeof PatchUserStudyLanguageAndDialectResponseSchema>

const containsProfanity = (text: string): boolean => {
  const lowercaseText = text.toLowerCase()

  return Object.keys(naughtyWords).some((langCode) => {
    const words = naughtyWords[langCode as keyof typeof naughtyWords]
    return Array.isArray(words) && words.some((word: string) => lowercaseText.includes(word.toLowerCase()))
  })
}

export const NicknameValidationSchema = z
  .string()
  .min(3, 'Nickname must be at least 3 characters')
  .max(20, 'Nickname must be less than 20 characters')
  .regex(/^[a-zA-Z0-9_-]+$/, 'Only letters, numbers, underscores and hyphens are allowed')
  .refine((val) => !containsProfanity(val), 'This nickname is not allowed')

export const GetNicknameQuerySchema = z.object({
  nickname: NicknameValidationSchema,
})

export type GetNicknameAvailabilityRequest = z.infer<typeof GetNicknameQuerySchema>
export type GetNicknameAvailabilityResponse = {
  isAvailable: boolean
  message: string
}

export const PatchNicknameRequestSchema = z.object({
  nickname: NicknameValidationSchema,
})

export type PatchNicknameRequest = z.infer<typeof PatchNicknameRequestSchema>
export type PatchNicknameResponse = {
  nickname: string
}

export const MarketingEmailPreferencesSchema = z.object({
  shouldReceiveMarketingEmails: z.boolean(),
})

export type MarketingEmailPreferences = z.infer<typeof MarketingEmailPreferencesSchema>

export type UpdateMarketingEmailPreferencesRequest = MarketingEmailPreferences
export type UpdateMarketingEmailPreferencesResponse = MarketingEmailPreferences
