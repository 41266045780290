import { DialogContent, DialogHeader, DialogTitle } from '../../shadcn/dialog'
import { useDispatch, useSelector } from 'react-redux'
import { selectAccountAccessToken, selectEmail, selectName } from '../../../state/slices/account-slice'
import { Button } from '../../design-system/button'
import { Loader2, Send } from 'lucide-react'
import { Textarea } from '../../shadcn/textarea'
import { Input } from '../../shadcn/input'
import { useMutation } from '@tanstack/react-query'
import { sendEmail } from '../../../transport/transport/our-backend/api/contact-email/contact-email'
import { toast } from 'sonner'
import { logWithRollbar } from '../../../analytics/rollbar/log-with-rollbar'
import { modalActions } from '../../../state/slices/modal-slice'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '../../shadcn/form'
import {
  ContactEmailRequest,
  formSchema,
} from '@shared/frontend-and-backend/body-types/contact-email/contact-email.types.ts'
import { z } from 'zod'
import clsx from 'clsx'
import { t } from '../../../i18n/translate'

export const ContactUsModalContent = () => {
  const dispatch = useDispatch()
  const userEmail = useSelector(selectEmail)
  const accessToken = useSelector(selectAccountAccessToken)
  const username = useSelector(selectName)

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      username: username || '',
      email: userEmail || '',
      message: '',
    },
  })

  const sendEmailMutation = useMutation({
    mutationFn: (data: ContactEmailRequest) => sendEmail(data, accessToken),
  })

  const onSubmit = (values: z.infer<typeof formSchema>) => {
    sendEmailMutation.mutate(values, {
      onSuccess: () => {
        toast.success(t('contact.success.toast'))
        form.reset()
        dispatch(modalActions.closeModal())
      },
      onError: (error) => {
        logWithRollbar(`Failed to send feedback: ${JSON.stringify(error)}`)
        toast.error(t('contact.error.toast'))
      },
    })
  }

  return (
    <DialogContent className='w-11/12 rounded-xl bg-white p-8 sm:max-w-md'>
      <DialogHeader>
        <DialogTitle>{t('contact.help.title')}</DialogTitle>
        <p className='text-sm text-gray-500'>{t('contact.help.description')}</p>
      </DialogHeader>

      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className='mt-4 space-y-4'>
          <FormField
            control={form.control}
            name='message'
            render={({ field }) => (
              <FormItem>
                <FormLabel className='text-sm font-medium text-gray-700'>{t('contact.form.message.label')}</FormLabel>
                <FormControl>
                  <Textarea
                    {...field}
                    placeholder={t('contact.form.message.placeholder')}
                    className='mt-1 min-h-[120px] resize-none rounded-xl border-gray-200 focus:border-indigo-500 focus:ring-indigo-500'
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name='email'
            render={({ field }) => (
              <FormItem>
                <FormLabel className='text-sm font-medium text-gray-700'>{t('contact.form.email.label')}</FormLabel>
                <FormControl>
                  <Input
                    {...field}
                    type='email'
                    className='mt-1 block w-full rounded-xl border-gray-200 focus:border-indigo-500 focus:ring-indigo-500'
                    placeholder={t('contact.form.email.placeholder')}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name='username'
            render={({ field }) => (
              <FormItem className='pb-4'>
                <FormLabel className='text-sm font-medium text-gray-700'>{t('contact.form.name.label')}</FormLabel>
                <FormControl>
                  <Input
                    {...field}
                    className='mt-1 block w-full rounded-xl border-gray-200 focus:border-indigo-500 focus:ring-indigo-500'
                    placeholder={t('contact.form.name.placeholder')}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <div className='flex justify-end gap-3'>
            <Button
              onClick={() => dispatch(modalActions.closeModal())}
              type='button'
              className='border border-gray-200 bg-white px-6 text-gray-700 hover:bg-gray-50'
            >
              {t('contact.button.cancel')}
            </Button>
            <Button
              type='submit'
              disabled={sendEmailMutation.isPending}
              className={clsx(
                'w-full rounded-xl bg-gradient-to-r from-indigo-500 to-indigo-500 px-4 py-2 font-medium text-white',
                {
                  'cursor-not-allowed bg-gray-500': sendEmailMutation.isPending || sendEmailMutation.isSuccess,
                  'bg-red-500 hover:bg-red-600': sendEmailMutation.isError,
                }
              )}
            >
              {sendEmailMutation.isPending ? (
                <>
                  <Loader2 className='mr-2 inline h-4 w-4 animate-spin' />
                  {t('contact.button.sending')}
                </>
              ) : sendEmailMutation.isError ? (
                t('contact.button.error')
              ) : (
                <>
                  <Send className='mr-2 inline h-4 w-4' />
                  {t('contact.button.send')}
                </>
              )}
            </Button>
          </div>
        </form>
      </Form>
    </DialogContent>
  )
}
