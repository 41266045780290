import { Popover, PopoverContent, PopoverTrigger } from '../../../../shadcn/popover.tsx'
import { Settings } from 'lucide-react'
import { cn } from '../../../../../utils/shadcn-utils.ts'
import { Drawer, DrawerContent, DrawerHeader, DrawerTitle, DrawerTrigger } from '../../../../shadcn/drawer.tsx'
import { useMediaQuery } from 'usehooks-ts'
import { ReactNode } from 'react'
import { Level } from '../atoms/cefr-level-selector/cefr-level-selector-utils.ts'
import { t } from '../../../../../i18n/translate'

type Props =
  | {
      children: ReactNode | ReactNode[]
      shouldShowLevel: false
      currentLevel: null
    }
  | {
      children: ReactNode | ReactNode[]
      shouldShowLevel: true
      currentLevel: Level
    }

export const ExerciseControlsSettingsButton = ({ children, shouldShowLevel, currentLevel }: Props) => {
  const isSmOrLarger = useMediaQuery('(min-width: 640px)')
  return (
    <>
      {isSmOrLarger ? (
        <Popover>
          <PopoverTrigger>
            <div className='flex items-center justify-center gap-2'>
              <div className='cursor-pointer rounded p-1.5 text-gray-700 transition-colors duration-100 hover:bg-gray-600 hover:text-white active:bg-gray-700 active:text-white'>
                <Settings className='h-5' />
              </div>
              {shouldShowLevel && (
                <div className='text-lg font-semibold text-slate-800'>
                  {`${t('exercise.settings.level')} ${currentLevel.name}`}
                </div>
              )}
            </div>
          </PopoverTrigger>
          <PopoverContent className={cn('flex flex-col gap-4 bg-white p-6', 'w-[400px] max-w-[90vw]')}>
            {children}
          </PopoverContent>
        </Popover>
      ) : (
        <Drawer>
          <DrawerTrigger asChild>
            <div className='flex items-center justify-center gap-2'>
              <div className='cursor-pointer rounded p-1.5 text-gray-700 transition-colors duration-100 hover:bg-gray-600 hover:text-white active:bg-gray-700 active:text-white'>
                <Settings className='h-5' />
              </div>
              {shouldShowLevel && (
                <div className='text-lg font-semibold text-slate-800'>
                  {`${t('exercise.settings.level')} ${currentLevel.name}`}
                </div>
              )}
            </div>
          </DrawerTrigger>
          <DrawerContent className='bg-white pb-10'>
            <div className='mx-auto mt-4 h-2 w-[100px] rounded-full bg-muted' />
            <DrawerHeader>
              <DrawerTitle>{t('exercise.settings.title')}</DrawerTitle>
            </DrawerHeader>
            <div className='mx-auto w-11/12 px-4'>{children}</div>
          </DrawerContent>
        </Drawer>
      )}
    </>
  )
}
