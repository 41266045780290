import {
  GetNicknameAvailabilityRequest,
  GetNicknameAvailabilityResponse,
  GetNicknameQuerySchema,
  PatchNicknameRequest,
  PatchNicknameResponse,
  PatchUserMotherLanguageRequest,
  PatchUserMotherLanguageResponse,
  PatchUserStudyDialectRequest,
  PatchUserStudyDialectResponse,
  PatchUserStudyLanguageAndDialectRequest,
  PatchUserStudyLanguageAndDialectResponse,
  PatchUserStudyLanguageRequest,
  PatchUserStudyLanguageResponse,
  PutUserRequest,
  PutUserResponse,
  UpdateMarketingEmailPreferencesRequest,
  UpdateMarketingEmailPreferencesResponse,
  UpdateUserResponse,
  PatchUserTopicsRequest,
  PatchUserTopicsResponse,
} from '@shared/frontend-and-backend/body-types/users/users.types.ts'
import {
  marketingEmailPreferencesUrl,
  getCloneVoiceUrl,
  getNicknameAvailabilityUrl,
  nicknameUrl,
  patchUserMotherLanguageUrl,
  patchUserStudyDialectUrl,
  patchUserStudyLanguageAndStudyDialogUrl,
  patchUserStudyLanguageUrl,
  userUrl,
  patchUserTopicsUrl,
} from '../backend-api-urls.ts'
import { ResponseWrapper } from '@shared/frontend-and-backend/body-types/response-wrapper.types.ts'
import { customFetch } from '../../../utils.ts'
import {
  DialectCode,
  LangCode,
  SupportedStudyLanguage,
} from '@shared/frontend-and-landing-and-backend/constants/lang-codes.ts'
import { Topic } from '@shared/frontend-and-backend/constants/topics.ts'

export const cloneVoice = async (
  langCode: LangCode,
  accessToken: string,
  audioBlob: Blob
): Promise<ResponseWrapper<UpdateUserResponse>> => {
  const formData: FormData = new FormData()
  formData.append('audio', audioBlob, 'user-audio.mp3')

  const response = await customFetch(getCloneVoiceUrl(langCode), {
    method: 'PATCH',
    body: formData,
    headers: {
      Authorization: 'Bearer ' + accessToken,
    },
  })

  const data = await response.json()
  return data
}

export const getOrCreateUser = async (
  accessToken: string,
  referral?: string
): Promise<ResponseWrapper<PutUserResponse>> => {
  const body: PutUserRequest = { referral }
  const response = await customFetch(userUrl, {
    method: 'PUT',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + accessToken,
    },
  })
  const json = await response.json()
  return json
}

export const updateMotherLanguage = async (
  accessToken: string,
  motherLanguage: LangCode
): Promise<ResponseWrapper<PatchUserMotherLanguageResponse>> => {
  const body: PatchUserMotherLanguageRequest = {
    motherLanguage,
  }
  const response = await customFetch(patchUserMotherLanguageUrl, {
    method: 'PATCH',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + accessToken,
    },
  })
  const json = await response.json()
  return json
}

// we need both of the below methods because it's fine to change the study language only if it's happening during the onboarding...
export const updateStudyLanguage = async (
  accessToken: string,
  studyLanguage: SupportedStudyLanguage
): Promise<ResponseWrapper<PatchUserStudyLanguageResponse>> => {
  const body: PatchUserStudyLanguageRequest = {
    studyLanguage,
  }
  const response = await customFetch(patchUserStudyLanguageUrl, {
    method: 'PATCH',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + accessToken,
    },
  })
  const json = await response.json()
  return json
}

// ... but we can't allow the user to change the study language alone if it's a change happening after a successful onboarding,
// for example in settings
export const updateStudyLanguageAndStudyDialect = async (
  accessToken: string,
  studyLanguage: SupportedStudyLanguage,
  dialect: DialectCode
): Promise<ResponseWrapper<PatchUserStudyLanguageAndDialectResponse>> => {
  const body: PatchUserStudyLanguageAndDialectRequest = {
    studyLanguage,
    studyDialect: dialect,
  }
  const response = await customFetch(patchUserStudyLanguageAndStudyDialogUrl, {
    method: 'PATCH',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + accessToken,
    },
  })
  const json = await response.json()
  return json
}

export const updateStudyDialect = async (
  accessToken: string,
  dialect: DialectCode
): Promise<ResponseWrapper<PatchUserStudyDialectResponse>> => {
  const body: PatchUserStudyDialectRequest = {
    studyDialect: dialect,
  }
  const response = await customFetch(patchUserStudyDialectUrl, {
    method: 'PATCH',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + accessToken,
    },
  })
  const json = await response.json()
  return json
}

export const checkNicknameAvailability = async (
  accessToken: string,
  nickname: string
): Promise<ResponseWrapper<GetNicknameAvailabilityResponse>> => {
  const validationResult = GetNicknameQuerySchema.safeParse({ nickname })

  if (!validationResult.success) {
    return {
      data: {
        isAvailable: false,
        message: validationResult.error.errors[0].message,
      },
    }
  }

  const params: GetNicknameAvailabilityRequest = validationResult.data

  const response = await customFetch(getNicknameAvailabilityUrl(params.nickname), {
    method: 'GET',
    headers: {
      Authorization: 'Bearer ' + accessToken,
    },
  })

  const json = await response.json()
  return json
}

export const updateNickname = async (
  accessToken: string,
  nickname: string
): Promise<ResponseWrapper<PatchNicknameResponse>> => {
  const body: PatchNicknameRequest = { nickname }
  const response = await customFetch(nicknameUrl, {
    method: 'PATCH',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + accessToken,
    },
  })
  const json = await response.json()
  return json
}

export const updateMarketingEmailPreferences = async (
  accessToken: string,
  data: UpdateMarketingEmailPreferencesRequest
): Promise<ResponseWrapper<UpdateMarketingEmailPreferencesResponse>> => {
  const response = await customFetch(marketingEmailPreferencesUrl, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(data),
  })

  return await response.json()
}

export const updateTopics = async (
  accessToken: string,
  topics: Topic[]
): Promise<ResponseWrapper<PatchUserTopicsResponse>> => {
  const body: PatchUserTopicsRequest = { topics }
  const response = await customFetch(patchUserTopicsUrl, {
    method: 'PATCH',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + accessToken,
    },
  })
  const json = await response.json()
  return json
}
