import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { accountActions, selectAccountAccessToken } from '../../../../state/slices/account-slice.ts'
import { Logo } from '../../../design-system/logo.tsx'
import { Mic, UserCheck, ArrowLeft } from 'lucide-react'
import { POSTHOG_EVENTS } from '../../../../analytics/posthog/posthog-events.ts'
import { NavigationButton } from '../navigation-button.tsx'
import { useNavigate } from 'react-router-dom'
import { ROUTE_PATHS } from '../../../../routing/route-paths.ts'
import { t } from '../../../../i18n/translate.ts'
import { VoiceCloningAndNotificationsConsent } from './voice-cloning-and-notifications-consent.tsx'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { updateMarketingEmailPreferences } from '../../../../transport/transport/our-backend/api/users/users'
import { QUERY_KEYS } from '../../../../transport/transport/our-backend/api/query-keys.ts'
import { toast } from 'sonner'

export const TermsAndConditionsView = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [isConsentChecked, setIsConsentChecked] = useState(false)
  const [shouldReceiveMarketingEmails, setShouldReceiveMarketingEmails] = useState(true)
  const accessToken = useSelector(selectAccountAccessToken)
  const queryClient = useQueryClient()

  const { mutate: updateMarketingEmailPreferencesMutation } = useMutation({
    mutationFn: async (newValue: boolean) => {
      return updateMarketingEmailPreferences(accessToken, {
        shouldReceiveMarketingEmails: newValue,
      })
    },
    onSuccess: (_, newValue) => {
      queryClient.setQueryData([QUERY_KEYS.SHOULD_RECEIVE_MARKETING_EMAILS], newValue)
    },
    onError: () => {
      toast.error(t('account.modal.marketingEmails.updateError'))
    },
  })

  const handleMarketingEmailsChange = (checked: boolean) => {
    setShouldReceiveMarketingEmails(checked)
    updateMarketingEmailPreferencesMutation(checked)
  }

  const handleStartCloning = () => {
    if (isConsentChecked) {
      dispatch(accountActions.setHasAcceptedTermsAndConditionsAndClickedNext(true))
      navigate(ROUTE_PATHS.ONBOARDING_CLONE_VOICE)
    }
  }

  useEffect(() => {
    POSTHOG_EVENTS.viewPage()
  }, [])

  const onPreviousClick = () => {
    dispatch(accountActions.resetTopics())
    navigate(ROUTE_PATHS.ONBOARDING_CHOOSE_TOPICS)
  }

  return (
    <div className='flex w-full flex-col'>
      <div className='flex w-full items-center px-4 py-4'>
        <div className='mx-auto flex h-full w-full items-center md:w-1/2 3xl:w-1/3'>
          <button onClick={onPreviousClick} className='text-gray-500 hover:text-gray-700'>
            <ArrowLeft size={24} />
          </button>
        </div>
      </div>

      <div className='mb-52 flex justify-center'>
        <div className='flex w-full flex-col items-center gap-y-4 px-4 pt-0 md:w-1/2 3xl:w-1/3'>
          <div className='hidden items-center space-x-4 md:flex'>
            <div className='flex h-16 w-16 flex-shrink-0 items-center justify-center rounded-full bg-indigo-600'>
              <Logo size={40} inverted={true} />
            </div>
            <h1 className='text-3xl font-bold leading-tight'>
              {t('onboarding.termsAndConditions.title')}
              <br />
              {t('onboarding.termsAndConditions.subtitle')}
            </h1>
          </div>

          <div className='flex w-full justify-center md:hidden'>
            <h1 className='text-center text-3xl font-bold leading-tight'>
              {t('onboarding.termsAndConditions.title')}
              <br />
              {t('onboarding.termsAndConditions.subtitle')}
            </h1>
          </div>

          <p className='text-gray-600'>{t('onboarding.termsAndConditions.removeVoiceInfo')}</p>

          <div className='space-y-4'>
            <div className='flex items-start space-x-3 rounded-lg bg-white p-4 shadow-sm'>
              <div className='flex h-8 w-8 flex-shrink-0 items-center justify-center rounded-full bg-indigo-100'>
                <Mic className='h-5 w-5 text-indigo-600' />
              </div>
              <div>
                <h2 className='text-lg font-semibold'>{t('onboarding.termsAndConditions.step1.title')}</h2>
                <p className='text-sm text-gray-600'>{t('onboarding.termsAndConditions.step1.description')}</p>
              </div>
            </div>

            <div className='flex items-start space-x-3 rounded-lg bg-white p-4 shadow-sm'>
              <div className='flex h-8 w-8 flex-shrink-0 items-center justify-center rounded-full bg-indigo-100'>
                <UserCheck className='h-5 w-5 text-indigo-600' />
              </div>
              <div>
                <h2 className='text-lg font-semibold'>{t('onboarding.termsAndConditions.step2.title')}</h2>
                <p className='text-sm text-gray-600'>{t('onboarding.termsAndConditions.step2.description')}</p>
              </div>
            </div>
          </div>

          <VoiceCloningAndNotificationsConsent
            isConsentChecked={isConsentChecked}
            setIsConsentChecked={setIsConsentChecked}
            shouldReceiveMarketingEmails={shouldReceiveMarketingEmails}
            onMarketingEmailsChange={handleMarketingEmailsChange}
          />
        </div>
      </div>

      <div className='fixed bottom-0 w-full bg-gray-50'>
        <div className='pointer-events-none absolute bottom-full left-0 right-0 h-40 bg-gradient-to-b from-transparent to-gray-50' />
        <div className='mx-auto flex w-full px-4 pb-8 pt-4 md:w-1/2 3xl:w-1/3'>
          <NavigationButton
            onClick={handleStartCloning}
            disabled={!isConsentChecked}
            className='w-full bg-gradient-to-r from-indigo-500 to-indigo-600 text-white'
          >
            {t('onboarding.termsAndConditions.next')}
          </NavigationButton>
        </div>
      </div>
    </div>
  )
}
