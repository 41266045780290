import { getConfig } from '../../../../config/environment-config.ts'
import { LangCode } from '@shared/frontend-and-landing-and-backend/constants/lang-codes.ts'
import { GetCorrectUserPronunciationsRequestParams } from '@shared/frontend-and-backend/body-types/words/words.types.ts'
import { generateQueryString } from '../../../../utils/query-params-utils.ts'

export const apiPrefix = '/api/v1'
const hostWithPrefix = `${getConfig().apiHost}${apiPrefix}`

export const getRandomTextExerciseUrl = `${hostWithPrefix}/exercises/random/generate`
export const getSentenceFromFrequencyListUrl = `${hostWithPrefix}/exercises/frequency-list/generate`

export const audioUrl = `${hostWithPrefix}/pronunciation/audio`
export const audioWordUrl = `${hostWithPrefix}/pronunciation/audio-word`

export const transcribeUrl = (langCode: LangCode) => `${hostWithPrefix}/transcribe?langCode=${langCode}`

export const userUrl = `${hostWithPrefix}/users/me`

export const getCloneVoiceUrl = (langCode: LangCode) => `${userUrl}?langCode=${langCode}`

export const translateTextUrl = `${hostWithPrefix}/translate-text`
export const translateWordUrl = `${hostWithPrefix}/translate-word`
export const ipaTranscriptionUrl = `${hostWithPrefix}/pronunciation/transcribe-to-ipa`
export const transliterationUrl = `${hostWithPrefix}/transliterate`
export const languageDetectionUrl = `${hostWithPrefix}/detect-study-language`

export const emailUrl = `${hostWithPrefix}/send-contact-email`
export const removalsUrl = `${hostWithPrefix}/removals`

export const checkoutSessionUrl = `${hostWithPrefix}/payment/create-checkout-session`
export const subscriptionDetailsUrl = `${hostWithPrefix}/payment/subscription-details`
export const createCustomerPortalSessionUrl = `${hostWithPrefix}/payment/create-customer-portal-session`

export const postUserPronunciationsUrl = `${hostWithPrefix}/user-pronunciations?`
export const getLearnedWordsUrl = (params: GetCorrectUserPronunciationsRequestParams) =>
  `${hostWithPrefix}/learned-words?${generateQueryString(params)}`

export const userSettingsUrl = `${hostWithPrefix}/users/me/settings`

export const patchUserMotherLanguageUrl = `${hostWithPrefix}/users/me/mother_language`
export const patchUserStudyLanguageAndStudyDialogUrl = `${hostWithPrefix}/users/me/study_language_and_study_dialect`
export const patchUserStudyLanguageUrl = `${hostWithPrefix}/users/me/study_language`
export const patchUserStudyDialectUrl = `${hostWithPrefix}/users/me/study_dialect`

export const authenticationSendEmailVerification = `${hostWithPrefix}/authentication/send-email-verification`

export const savedWordsUrl = `${hostWithPrefix}/saved-words`

export const getSavedWordsUrl = (params: GetCorrectUserPronunciationsRequestParams) =>
  `${hostWithPrefix}/saved-words?${generateQueryString(params)}`

export const convertAudioUrl = `${hostWithPrefix}/audio/convert-to-mp3`

export const nicknameUrl = `${hostWithPrefix}/users/me/nickname`
export const getNicknameAvailabilityUrl = (nickname: string) =>
  `${hostWithPrefix}/users/me/nickname/availability?nickname=${encodeURIComponent(nickname)}`
export const leaderboardUrl = `${hostWithPrefix}/leaderboard`

export const marketingEmailPreferencesUrl = `${hostWithPrefix}/users/me/marketing-preferences`

export const patchUserTopicsUrl = `${hostWithPrefix}/users/me/topics`
