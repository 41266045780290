import React, { useEffect, useState } from 'react'
import { Badge } from '../../../../../shadcn/badge.tsx'
import { Popover, PopoverContent, PopoverTrigger } from '../../../../../shadcn/popover.tsx'
import { CircleHelp } from 'lucide-react'
import { Slider } from '../../../../../shadcn/slider.tsx'
import { getCurrentLevel, Levels } from './cefr-level-selector-utils.ts'
import { t } from '../../../../../../i18n/translate.ts'

const totalVisualWidth = Levels.reduce((sum, level) => sum + level.visualWidth, 0)

interface CEFRScaleProps {
  initialPosition: number
  onPositionCommit: (newPosition: number) => void
}

export const CefrLevelSelector: React.FC<CEFRScaleProps> = ({ initialPosition, onPositionCommit }) => {
  const [position, setPosition] = useState(initialPosition)
  const [sliderValue, setSliderValue] = useState(0)

  const currentLevel = getCurrentLevel(position)

  const positionToSliderValue = (position: number): number => {
    let accumulatedWidth = 0
    for (const level of Levels) {
      if (position <= level.range[1]) {
        const levelProgress = (position - level.range[0]) / (level.range[1] - level.range[0])
        return accumulatedWidth + levelProgress * level.visualWidth
      }
      accumulatedWidth += level.visualWidth
    }
    return totalVisualWidth
  }

  const sliderValueToPosition = (value: number): number => {
    let accumulatedWidth = 0
    for (const level of Levels) {
      if (value <= accumulatedWidth + level.visualWidth) {
        const levelProgress = (value - accumulatedWidth) / level.visualWidth
        return Math.round(level.range[0] + levelProgress * (level.range[1] - level.range[0]))
      }
      accumulatedWidth += level.visualWidth
    }
    return Levels[Levels.length - 1].range[1] // Return max position if slider is at the end
  }

  useEffect(() => {
    setSliderValue(positionToSliderValue(initialPosition))
    setPosition(initialPosition)
  }, [initialPosition])

  const handleSliderChange = (newValue: number[]) => {
    const newPosition = sliderValueToPosition(newValue[0])
    setSliderValue(newValue[0])
    setPosition(newPosition)
  }

  const handleSliderCommit = (newValue: number[]) => {
    const newPosition = sliderValueToPosition(newValue[0])
    onPositionCommit(newPosition)
  }

  return (
    <div className='mb-4 space-y-3'>
      <h3 className='text-base font-semibold text-gray-800'>{t('exercise.cefr.title')}</h3>

      <div className='flex items-center justify-between'>
        <div className='flex items-center gap-2'>
          <Badge variant='secondary' className='text-lg'>
            {currentLevel.name}
          </Badge>
          <Popover>
            <PopoverTrigger>
              <CircleHelp className='h-4 w-4 text-stone-400' />
            </PopoverTrigger>
            <PopoverContent className='bg-white text-center text-sm shadow-lg'>
              {t('exercise.cefr.info')}
            </PopoverContent>
          </Popover>
        </div>
        <div className='flex justify-center gap-2'>
          <Popover>
            <PopoverTrigger>
              <CircleHelp className='h-4 w-4 text-stone-400' />
            </PopoverTrigger>
            <PopoverContent className='bg-white text-center text-sm shadow-lg'>
              {t('exercise.cefr.frequency.info')}
            </PopoverContent>
          </Popover>
          <span className='text-sm text-gray-400'>
            {t('exercise.cefr.position')} {position}
          </span>
        </div>
      </div>

      <div className='flex flex-col gap-1'>
        <Slider
          value={[sliderValue]}
          min={0}
          max={totalVisualWidth}
          step={0.1}
          onValueChange={handleSliderChange}
          onValueCommit={handleSliderCommit}
          className='w-full'
        />
        <div className='flex justify-between'>
          {Levels.map((level) => (
            <div
              key={level.name}
              className='text-xs font-medium text-gray-500'
              style={{ width: `${(level.visualWidth / totalVisualWidth) * 100}%` }}
            >
              {level.name}
            </div>
          ))}
        </div>
        <div className='flex justify-between text-sm text-gray-500'>
          <span>{t('exercise.cefr.beginner')}</span>
          <span>{t('exercise.cefr.advanced')}</span>
        </div>
      </div>
    </div>
  )
}
