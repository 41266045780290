import { useSelector } from 'react-redux'
import {
  useFrequencySliderPosition,
  useFrequencyWordLength,
  useUserSettings,
} from '../../../../../transport/transport/our-backend/api/users/users-hooks.ts'
import { selectAccountAccessToken, selectStudyLanguageOrEnglish } from '../../../../../state/slices/account-slice.ts'
import { CefrLevelSelector } from '../atoms/cefr-level-selector/cefr-level-selector.tsx'
import { LangCode } from '@shared/frontend-and-landing-and-backend/constants/lang-codes.ts'
import { UserSettings } from '@shared/frontend-and-backend/body-types/user-settings/user-settings.types.ts'
import { cloneDeep } from 'lodash'
import { useUpdateFrequencyListPositionMutation } from '../../../../../hooks/use-update-frequency-list-position-mutation.ts'
import { ExerciseBasicSettingsContent } from '../atoms/exercise-basic-settings-content.tsx'
import { ExerciseLevelSelector } from '../atoms/exercise-length-level-selector/exercise-level-selector.tsx'
import { useUpdateFrequencyWordLengthMutation } from '../../../../../hooks/use-update-frequency-list-word-length-mutation.ts'

export const FrequencyListExerciseSettingsContent = () => {
  const studyLanguage = useSelector(selectStudyLanguageOrEnglish)
  const position = useFrequencySliderPosition(studyLanguage)
  const wordLength = useFrequencyWordLength(studyLanguage)
  const { data: userSettings } = useUserSettings()
  const accessToken = useSelector(selectAccountAccessToken)
  const { mutate: mutateFrequencyListPosition } = useUpdateFrequencyListPositionMutation(accessToken, studyLanguage)
  const { mutate: mutateWordLength } = useUpdateFrequencyWordLengthMutation(accessToken, studyLanguage)

  const handlePositionChange = (newPosition: number) => {
    updateFrequencySliderPosition(studyLanguage, newPosition)
  }

  const handleWordLengthChange = (newPosition: number) => {
    updateWordLengthSliderPosition(studyLanguage, newPosition)
  }

  const updateFrequencySliderPosition = (language: LangCode, position: number) => {
    if (!userSettings) {
      return
    }
    const updatedSettings: UserSettings = cloneDeep(userSettings)
    updatedSettings.preferences.exercises.frequencyList.position.byLanguage.map((lang) => {
      if (lang.language === language) {
        lang.position = position
      }
      return lang
    })
    mutateFrequencyListPosition(updatedSettings)
  }

  const updateWordLengthSliderPosition = (language: LangCode, wordLength: number) => {
    if (!userSettings) {
      return
    }
    const updatedSettings: UserSettings = cloneDeep(userSettings)
    updatedSettings.preferences.exercises.frequencyList.exerciseLength.byLanguage.map((lang) => {
      if (lang.language === language) {
        lang.length = wordLength
      }
      return lang
    })
    mutateWordLength(updatedSettings)
  }

  return (
    <>
      <CefrLevelSelector initialPosition={position} onPositionCommit={handlePositionChange} />
      <ExerciseLevelSelector initialWordLength={wordLength} onWordLengthCommit={handleWordLengthChange} />
      <ExerciseBasicSettingsContent />
    </>
  )
}
