import {
  GetIpaRequest,
  GetIpaResponse,
} from '@shared/frontend-and-backend/body-types/pronunciation/transcribe-ipa.types.ts'
import { ResponseWrapper } from '@shared/frontend-and-backend/body-types/response-wrapper.types.ts'
import { DialectCode, SupportedStudyLanguage } from '@shared/frontend-and-landing-and-backend/constants/lang-codes.ts'

import { customFetch } from '../../../utils.ts'
import { ipaTranscriptionOpenUrl } from '../backend-open-api-urls.ts'

export const getGeneratedIpaTranscriptionViaOpenApi = async (
  text: string,
  language: SupportedStudyLanguage,
  dialect: DialectCode
): Promise<ResponseWrapper<GetIpaResponse>> => {
  const body: GetIpaRequest = { text, language, dialect }
  const response = await customFetch(ipaTranscriptionOpenUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  })

  const responseData: ResponseWrapper<GetIpaResponse> = await response.json()
  return responseData
}
